/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {createStyles, List, Modal} from "@mantine/core";
import BaseGridNode, {DIAMETER_DEFAULT} from "./grid/BaseGridNode";
import {colors} from "../helper/ColorPalette";

/**
 * Type that represents the props of the {@link GameInstructionsDialog}.
 *
 * opened: Determines whether the dialog is currently opened.
 * onDismiss: Callback that is invoked when the dialog is dismissed.
 */
type GameInstructionsDialogProps = {
    opened: boolean;
    onDismiss: () => void;
};

const useStyles = createStyles(() => ({
    root: {
        display: "flex",
    },
    screenshot1: {
        width: "30%",
        height: "30%",
        display: "flex",
    },
    screenshot2: {
        width: "30%",
        height: "30%",
        display: "flex",
    },
    screenshot3: {
        width: "20%",
        height: "20%",
        display: "flex",
        marginRight: "1vw",
    },
    screenshot3container: {
        display: "flex",
        flexDirection: "row",
    },
    textContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: "0.5rem",
    },
    visibilityOfRanksContainer: {
        display: "flex",
        flexDirection: "row",
    },
    visibilityOfRanksText: {
        display: "flex",
        marginTop: "0.5rem",
        marginLeft: "0.5rem",
    },
}));

/**
 * This component is responsible for the game instructions' dialog.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const GameInstructionsDialog = (props: GameInstructionsDialogProps) => {

    const {classes} = useStyles();

    return (
        <div className={classes.root}>
            <Modal
                opened={props.opened}
                onClose={props.onDismiss}
                title="Stemgs Gatatooo Game Instructions"
                size="xl"
                transitionProps={{transition: "rotate-left"}}
                overlayProps={{
                    blur: 3,
                }}>
                <text>
                    <h2>Objective:</h2>
                    <p>
                        The objective of Stemgs Gatatooo is to either capture the opponent's
                        flag or eliminate all of the opponent's figures in a way that they
                        have no legal moves left.
                    </p>
                    <h2>Figures and their ranks:</h2>
                    <p>
                        The game consists of different figures, each with a specific rank.
                        The ranks determine the strength of the figures in combat. Listed
                        below are the ranks from the highest to the lowest strength:
                    </p>
                    <List>
                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"1"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>
                                Flag (can be defeated by any figure)
                            </div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"12"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>
                                Bomb (kills all figures except the Miner)
                            </div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"11"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>
                                Marshal (can only be killed by bombs or the Spy)
                            </div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"10"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>General</div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"9"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>Colonel</div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"8"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>Major</div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"7"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>Captain</div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"6"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>Lieutenant</div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"5"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>Sergeant</div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"4"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>
                                Miner (can defuse bombs)
                            </div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"3"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>
                                Scout (can move over multiple empty squares)
                            </div>
                        </List.Item>

                        <List.Item
                            icon={
                                <BaseGridNode
                                    text={"2"}
                                    diameter={DIAMETER_DEFAULT - 2}
                                    hoverable
                                    color={colors.red}
                                />
                            }>
                            <div className={classes.textContainer}>
                                Spy (can defeat the flag and the Marshal)
                            </div>
                        </List.Item>
                    </List>
                    <h2>Basic Rules:</h2>
                    <img
                        src="/instructionScreenshots/screenshot1.png"
                        alt="Possible moves"
                        className={classes.screenshot1}
                    />
                    <p>
                        A figure can move one square in any direction to an adjacent empty
                        square. The blue squares in the middle of the board squares (Lakes)
                        are impassable, even for the Scout. The flag and bombs cannot be
                        moved.
                    </p>
                    <h3>The Two-Field-Rule:</h3>
                    <img
                        src="/instructionScreenshots/screenshot2.png"
                        alt="Two-Field-Rule"
                        className={classes.screenshot2}
                    />
                    <p>
                        Each figure is allowed to move back and forth only three times.
                        Attempting a fourth move to any previously visited square will be
                        prevented by the game.
                    </p>
                    <h3>The Multiple-Field-Rule:</h3>
                    <div className={classes.screenshot3container}>
                        <img
                            src="/instructionScreenshots/screenshot3.1.png"
                            alt="Multiple-Field-Rule"
                            className={classes.screenshot3}
                        />

                        <img
                            src="/instructionScreenshots/screenshot3.2.png"
                            alt="Multiple-Field-Rule"
                            className={classes.screenshot3}
                        />
                        <img
                            src="/instructionScreenshots/screenshot3.3.png"
                            alt="Multiple-Field-Rule"
                            className={classes.screenshot3}
                        />
                    </div>
                    <p>
                        If a figure chases another figure back and forth for three
                        consecutive moves, a fourth move will be blocked directly at that
                        position. However, the Scout is exempt from this rule.
                    </p>
                    <h2>Combat Rules:</h2>
                    <p>
                        When a figure moves onto a square occupied by an opponent's figure a
                        battle occurs. A figure with a higher rank defeats a figure with a
                        lower rank. The higher-ranked figure survives the battle, and the
                        lower-ranked figure is eliminated. If both figures have the same
                        rank, both figures are eliminated.
                    </p>
                    <h3>Visibility of Ranks:</h3>

                    <div className={classes.visibilityOfRanksContainer}>
                        <BaseGridNode
                            text={"0"}
                            diameter={DIAMETER_DEFAULT - 2}
                            hoverable
                            color={colors.blue}
                        />
                        <text className={classes.visibilityOfRanksText}>
                            Enemy figure with unknown rank.
                        </text>
                    </div>

                    <p>
                        Each player can only see the ranks of their own figures. Once an
                        opponent's figure is involved in a battle, its rank is revealed. It
                        will be hidden again once the battle is over and a certain time has
                        passed.
                    </p>
                    <h2>Winning the Game:</h2>
                    <p>
                        A player wins by capturing the opponent's flag or by rendering the
                        opponent's figures unable to make any legal moves.
                    </p>
                    <h2>Starting the Game:</h2>
                    <p>
                        At the beginning of the game, both players set up their starting
                        positions. This can be done either by selecting a pre-set formation
                        or by clicking and placing their own figures or by using
                        drag-and-drop. Once both players are ready, the game begins.
                    </p>
                    <h2>Turn Sequence:</h2>
                    <p>
                        The active player is highlighted by a yellow border around their
                        figures. The player can move their figures using click-and-click or
                        drag-and-drop. An attack is initiated by moving a figure onto a
                        neighboring square occupied by an opponent's figure.
                    </p>
                    <p>
                        With these rules, you should be able to play Stemgs Gatatooo and
                        understand the game mechanics. Enjoy playing!
                    </p>
                </text>
            </Modal>
        </div>
    );
};

export default GameInstructionsDialog;
