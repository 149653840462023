/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { createStyles, Popover, Title } from "@mantine/core";
import { copyToClipboard, generateJoinURL } from "../../helper/Utils";
import GameMenuItem from "./GameMenuItem";
import { Link2Outline } from "@styled-icons/evaicons-outline/Link2Outline";
import TextButton from "../../components/TextButton";
import { Copy } from "@styled-icons/boxicons-regular/Copy";
import LobbyQRCode from "../LobbyPage/LobbyQRCode";

/**
 * Type that represents the props of the {@link GameMenuShareSheet} component.
 */
type GameMenuShareSheetProps = {
    roomCode: string;
};

const useStyles = createStyles({
    sheetContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    qrContainer: {
        padding: "1rem",
    }
});

/**
 * Component that represents a share sheet in the {@link GameMenu}.
 * This is a wrapper for a {@link GameMenuItem}.
 *
 * @param roomCode Code of the current room.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const GameMenuShareSheet: React.FC<GameMenuShareSheetProps> = ({
                                                                   roomCode,
                                                               }) => {

    const {classes} = useStyles();
    const joinURL = generateJoinURL(roomCode);

    return (
        <Popover position="bottom" withArrow>
            <Popover.Target>
                <div>
                    <GameMenuItem
                        label="Invite"
                        IconClass={Link2Outline}/>
                </div>
            </Popover.Target>
            <Popover.Dropdown className={classes.sheetContainer}>
                <Title order={3}>
                    To invite your friends to this room, you can
                </Title>
                <Title order={4}>
                    1. Share this QR-Code
                </Title>
                <div className={classes.qrContainer}>
                    <LobbyQRCode
                        roomCode={roomCode}
                        width={150}
                        height={150}/>
                </div>
                <Title order={4}>
                    2. Share this code
                </Title>
                <TextButton
                    rightIcon={<Copy size={25}/>}
                    onClick={() => copyToClipboard(roomCode)}>
                    <Title order={4}>
                        {roomCode}
                    </Title>
                </TextButton>
                <Title order={4}>
                    3. Share this link
                </Title>
                <TextButton
                    rightIcon={<Copy size={25}/>}
                    onClick={() => copyToClipboard(joinURL)}>
                    <Title order={4}>
                        {joinURL}
                    </Title>
                </TextButton>
            </Popover.Dropdown>
        </Popover>
    );
};

export default GameMenuShareSheet;