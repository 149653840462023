/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Button, Modal, Select, Text, Title } from "@mantine/core";
import usePositioningTemplates from "../../hooks/usePositioningTemplates";
import BoardProgress from "../../components/loaders/BoardProgress";
import TemplatePreviewGrid from "../../components/grid/TemplatePreviewGrid";
import usePositioningTemplate from "../../hooks/usePositioningTemplate";

/**
 * Type that represents the props of the {@link SelectPositioningPresetDialog}.
 *
 * playerColor: Color of the current player.
 * opened: Determines whether the dialog is currently displayed.
 * onDismiss: Callback method that is invoked when the dialog is dismissed.
 * onPresetConfirmed: Callback method that is invoked when the preset was confirmed.
 */
type SelectPositioningPresetDialogProps = {
    playerColor: string;
    opened: boolean;
    onDismiss: () => void;
    onPresetConfirmed: (id: string) => void;
};

/**
 * Dialog that allows the user to load a positioning configuration from a {@link PositioningTemplate}.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const SelectPositioningPresetDialog = (props: SelectPositioningPresetDialogProps) => {

    const [templates, templatesPending, templatesError] = usePositioningTemplates();
    const [template, , , fetchTemplate] = usePositioningTemplate();
    const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);

    useEffect(() => {
        if (selectedTemplate)
            fetchTemplate(selectedTemplate);
        // eslint-disable-next-line
    }, [selectedTemplate]);

    /**
     * Method that builds the pending layout of the dialog.
     *
     * @return Returns the created layout.
     */
    const _buildPendingLayout = () => {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10rem",
                marginBottom: "10rem",
            }}>
                <BoardProgress
                    width={7}
                    height={7}
                    dimensionUnit="rem"
                    diameter={6}/>
                <Text sx={{marginTop: "1rem"}}>
                    Loading Presets
                </Text>
            </Box>
        );
    };

    /**
     * Method that builds the error layout of the dialog.
     *
     * @return Returns the error layout of the dialog.
     */
    const _buildErrorLayout = () => {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10rem",
                marginBottom: "10rem",
            }}>
                <Text>
                    Unable to load templates. Please try again later.
                </Text>
            </Box>
        );
    };

    /**
     * Method that builds the main layout of the dialog.
     *
     * @return Returns the created main layout.
     */
    const _buildMainLayout = () => {
        return (
            <Box>
                <Select
                    data-cy="SelectPositioningTemplateDropdown"
                    placeholder="Select a preset"
                    dropdownPosition="bottom"
                    value={selectedTemplate}
                    onChange={setSelectedTemplate}
                    data={templates.map(template => ({
                        value: template.id,
                        label: template.name,
                    }))}/>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2rem",
                    marginBottom: "1rem",
                }}>
                    {template ? (
                        <TemplatePreviewGrid
                            previewGrid={template.template}
                            playerColor={props.playerColor}/>
                    ) : (
                        <Box sx={{
                            marginTop: "5rem",
                            marginBottom: "5rem",
                        }}>
                            Please select a preset
                        </Box>
                    )}
                </Box>
            </Box>
        );
    };

    return (
        <Modal
            opened={props.opened}
            onClose={() => props.onDismiss()}
            title={<Title order={3}>Positioning Presets</Title>}
            centered
            transitionProps={{transition: "rotate-left"}}
            size="70%">
            {templatesPending ? _buildPendingLayout() :
                templatesError ? _buildErrorLayout() : _buildMainLayout()}
            <Button
                data-cy="SelectPositioningTemplateSubmissionButton"
                onClick={() => props.onPresetConfirmed(template.id)}
                disabled={!template}>
                Confirm
            </Button>
        </Modal>
    );
};

export default SelectPositioningPresetDialog;