/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {motion} from "framer-motion";

/**
 * Type that represents the props of the {@link PositionedSlideAnimation}.
 */
type PositionedSlideAnimationProps = {
    className?: string;
    offsetX: number;
    offsetY: number;
    stiffness?: number;
    children?: React.ReactNode;
    diameter: number;
};

/**
 * Component that features a positioned slide animation.
 * This is used when animating multiple children inside a grid.
 *
 * @param className Optional class name that is passed to the component.
 * @param offsetX Offset on the X-Index of the element inside the grid.
 * @param offsetY Offset on the Y-Index of the element inside the grid.
 * @param stiffness Optional stiffness of the animation.
 * @param children Children of the component.
 * @param diameter Diameter of the grid the children are animated in.
 * @param rest Other props that are passed to the component.
 */
const PositionedSlideAnimation: React.FC<PositionedSlideAnimationProps> = ({
                                                                               className,
                                                                               offsetX,
                                                                               offsetY,
                                                                               stiffness,
                                                                               children,
                                                                               diameter,
                                                                               ...rest
                                                                           }) => {

    const SLIDE_OFFSET = 50;
    const SPEED_FACTOR = 0.03;

    /**
     * Function that calculates the speed factor depending on the offsets on both axes.
     *
     * @return Returns the animation speed factor.
     */
    const _calculateSpeedFactor = (): number => {
        let referenceX = offsetX;
        let referenceY = offsetY;

        while (referenceX < diameter && referenceY < 0) {
            referenceX++;
            referenceY++;
        }

        return (referenceX + referenceY) * SPEED_FACTOR;
    };

    return (
        <motion.div
            className={className}
            {...rest}
            initial={{
                scale: 0,
                translateX: -SLIDE_OFFSET,
                translateY: -SLIDE_OFFSET,
            }}
            animate={{
                scale: 1,
                translateX: 0,
                translateY: 0,
            }}
            exit={{
                scale: 0,
                translateX: -SLIDE_OFFSET,
                translateY: -SLIDE_OFFSET,
            }}
            transition={{
                type: "spring",
                stiffness: stiffness ?? 270,
                damping: 30,
                delay: _calculateSpeedFactor(),
            }}>
            {children}
        </motion.div>
    );
};

export default PositionedSlideAnimation;