/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {Box, Button, Modal, Title} from "@mantine/core";
import {Handshake} from "@styled-icons/fluentui-system-filled/Handshake";


/**
 * Type that represents the props of the {@link InfoDrawDialog}.
 *
 * oppositePlayerName: name of the opposite player.
 * onAcceptClick: onclick method of accept button.
 * onDeclinedClick: onclick method of decline button.
 * opened: Determines whether the dialog is currently displayed.
 * onDismiss: Callback method that is invoked when the dialog is dismissed.
 */
type InfoDrawDialogProps = {
    oppositePlayerName: string;
    onAcceptClick?: () => void;
    onDeclinedClick?: () => void;
    opened: boolean;
    onDismiss: () => void;
};

/**
 * Dialog that informs the player about a draw
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const InfoDrawDialog = (props: InfoDrawDialogProps) => {
    return (
        <Modal
            opened={props.opened}
            onClose={props.onDismiss}
            title={<Title order={3}>Draw request</Title>}
            centered
            transitionProps={{transition: "rotate-left"}}
            size="30%"
            overlayProps={{
                blur: 3,
            }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: "center",
                flexDirection: "column",
                padding: "3rem"
            }}>
                <Box sx={{
                    padding: "1rem"
                }}>
                    <Handshake size={80}/>
                </Box>
                <Title
                    order={4}>{props.oppositePlayerName} sent a draw request</Title>
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: "center",
                paddingBottom: "1rem",
                gap: "2rem"
            }}>
                <Button variant={"subtle"} color={"green"} onClick={props?.onAcceptClick}>Accept</Button>
                <Button variant={"subtle"} color={"red"} onClick={props?.onDeclinedClick}>Decline</Button>
            </Box>
        </Modal>
    );
};

export default InfoDrawDialog;