/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import { useState } from "react";
import axios from "axios";

/**
 * Type that represents the elements that are returned by the room creation hook.
 *
 * pending: Determines whether a new room is currently being created.
 * error: Contains any error that occurred during the process.
 * createRoom: Method that creates a new room.
 */
type RoomCreationHookStructure = [
    pending: boolean,
    error: string,
    createRoom: () => void,
];

/**
 * Custom hook used to create a new room.
 *
 * @param callback Callback that is invoked once a new room has successfully been created.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const useRoomCreation = (callback: (roomCode: string) => void): RoomCreationHookStructure => {

    const [pending, setPending] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);

    /**
     * Method that creates a new room.
     * This method invokes a request to the REST API endpoint used to create a new room.
     * When the room code is returned, it is passed to the invoked callback.
     */
    const createRoom = () => {
        setPending(true);
        axios.post("/api/v1/rooms/create")
            .then(result => {
                setPending(false);
                setError(null);
                callback(result.data);
            })
            .catch(_ => {
                setPending(false);
                setError("Unable to create the room. Please try again later.");
            });
    };

    return [
        pending,
        error,
        createRoom
    ];
};

export default useRoomCreation;