/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useContext} from "react";
import {RoomConnectionContext} from "../rooms/useRoomConnection";
import {NodeDto, PositionDto, Rank} from "../../model/proto/dto";
import {PositioningAction} from "../../model/proto/request";
import {StrategoResponse} from "../../model/proto/response";
import {generateRequestID} from "../../helper/Utils";
import {notifications} from "@mantine/notifications";
import {Error} from "@styled-icons/boxicons-solid/Error";

/**
 * Type that represents the output structure of the positioning controller.
 *
 * placeFigure: Method that is used to place a figure on the positioning board.
 * removeFigure: Method that is used to remove a figure from the positioning board.
 * loadPreset: Method that loads the positioning configuration form a preset with a given ID.
 * resetPositioning: Method that resets the positioning configuration.
 * showErrorMessage: Method that displays an error message to the player.
 */
type PositioningControllerHookStructure = [
    placeFigure: (position: PositionDto, node: NodeDto, rank: Rank, callback: (response: StrategoResponse) => void) => void,
    removeFigure: (position: PositionDto, callback: (response: StrategoResponse) => void) => void,
    loadPreset: (presetId: string, callback: (response: StrategoResponse) => void) => void,
    resetPositioning: () => void,
    showErrorMessage: (title: string, message: string) => void,
];

/**
 * Custom hook that contains the implementation of the frontend positioning logic.
 * This hook sends the requests and processes the responses.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const usePositioningController = (): PositioningControllerHookStructure => {

    const roomConnection = useContext(RoomConnectionContext);

    /**
     * Method that sends a generic positioning request to the backend.
     *
     * @param action Positioning action that should be invoked.
     * @param position Position the action should be invoked on.
     * @param rank Rank the action should be invoked with.
     * @param presetId Optional ID of a preset that should be loaded.
     * @param callback Callback that is invoked when the response of the request is received.
     */
    const _sendPositioningRequest = (action: PositioningAction, position: PositionDto, rank: Rank, presetId: string, callback: (response: StrategoResponse) => void) => {
        const requestID = generateRequestID("positioning_request_" + action);
        roomConnection.sendRequest(
            {
                accessToken: "",
                requestId: requestID,
                modifyPositioningRequest: {
                    positioningAction: action,
                    position: position,
                    figureRank: rank,
                    presetId: presetId
                }
            },
            {
                requestID,
                callback
            }
        );
    }

    /**
     * Method that places a figure on the positioning board.
     * This method sends the request to place the figure on the positioning board.
     *
     * @param position Position the figure should be positioned at.
     * @param node Node the figure should be placed on.
     * @param rank Rank of the figure that is placed.
     * @param callback Callback that is invoked when the response of the request is received.
     */
    const _placeFigure = (position: PositionDto, node: NodeDto, rank: Rank, callback: (response: StrategoResponse) => void) => {
        _sendPositioningRequest(
            PositioningAction.PLACE,
            position,
            rank,
            "",
            callback
        )
    };

    /**
     * Method that removes a figure from the positioning board.
     * This method sends the request to remove the figure from the positioning board.
     *
     * @param position Position the figure should be removed from.
     * @param callback Callback that is invoked when the response of the request is received.
     */
    const _removeFigure = (position: PositionDto, callback: (response: StrategoResponse) => void) => {
        _sendPositioningRequest(
            PositioningAction.REMOVE,
            position,
            Rank.BOMB,
            "",
            callback,
        );
    };

    /**
     * Method that loads a positioning preset with a specific ID.
     * This method sends the request to load the positioning preset with the given ID.
     *
     * @param presetId ID of the positioning preset that should be loaded.
     * @param callback Callback that is invoked when the response of the request is received.
     */
    const _loadPositioningPreset = (presetId: string, callback: (response: StrategoResponse) => void) => {
        _sendPositioningRequest(
            PositioningAction.LOAD,
            {positionX: -1, positionY: -1},
            Rank.BOMB,
            presetId,
            callback
        );
    };

    /**
     * Method that resets the positioning configuration of the player.
     * This method sends the request to reset the positioning configuration.
     */
    const _resetPositioning = () => {
        _sendPositioningRequest(
            PositioningAction.RESET,
            {positionX: -1, positionY: -1},
            Rank.BOMB,
            "",
            () => {
            },
        );
    };

    /**
     * Method that displays an error message to the player.
     *
     * @param title Title of the error message.
     * @param message Actual error message.
     */
    const _showErrorMessage = (title: string, message: string) => {
        notifications.show({
            id: "notification_error_" + Date.now().toString(),
            autoClose: 4000,
            color: "red",
            title,
            message,
            icon: <Error width={20} height={20}/>
        });
    };

    return [
        _placeFigure,
        _removeFigure,
        _loadPositioningPreset,
        _resetPositioning,
        _showErrorMessage,
    ];
};

export default usePositioningController;