/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { Card, createStyles, Title } from "@mantine/core";

/**
 * Type that represents the props of the {@link RoomCodeInputDigit} component.
 *
 * digit: Currently displayed digit of the component.
 */
type RoomCodeInputDigitProps = {
    digit: string;
};

const useStyles = createStyles({
    digitContainer: {
        margin: "0.2rem",
    },
    digit: {
        paddingTop: "0.1rem"
    }
});

/**
 * Component that represents a single digit of the room code input.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const RoomCodeInputDigit = (props: RoomCodeInputDigitProps) => {

    const {classes} = useStyles();

    return (
        <Card
            className={classes.digitContainer}
            shadow="sm"
            padding="xl"
            radius="lg"
            withBorder>
            <Title
                className={classes.digit}
                order={1}>
                {props.digit}
            </Title>
        </Card>
    );
};

export default RoomCodeInputDigit;