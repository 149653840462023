/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useState} from "react";
import {Button, TextInput} from "@mantine/core";
import {SaveEdit} from "@styled-icons/fluentui-system-regular/SaveEdit";

/**
 * Type that represents the props of the {@link LobbyUsernameEditor}.
 *
 * username: Current username of the player.
 * onSubmit: Callback that is invoked when the user updated the username.
 * onDismiss: Callback that is invoked when the user dismisses the popover.
 */
type LobbyUsernameEditorProps = {
    username: string;
    onSubmit: (username: string) => void;
    onDismiss: () => void;
};

/**
 * Component that lets the user update its own username.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const LobbyUsernameEditor = (props: LobbyUsernameEditorProps) => {

    const [username, setUsername] = useState<string>(props.username);

    return (
        <>
            <TextInput
                data-cy="UsernameEditorTextField"
                value={username}
                autoFocus
                onChange={e => setUsername(e.target.value)}
                placeholder="Enter your username"
                label="Username"
                withAsterisk
                onBlur={() => props.onDismiss()}
                onKeyDown={event => {
                    if (event.key === "Enter" && username.trim().length > 0 && /\S/.test(username)) {
                        props.onSubmit(username);
                        props.onDismiss();
                    }
                }}
                required/>
            <Button
                data-cy="UsernameEditorSaveButton"
                sx={{
                    marginTop: "0.8rem",
                    marginBottom: "0.2rem"
                }}
                fullWidth
                color="green"
                rightIcon={<SaveEdit width={20} height={20}/>}
                disabled={username.trim().length === 0}
                onClick={() => {
                    props.onSubmit(username);
                    props.onDismiss();
                }}>
                Save
            </Button>
        </>
    );
};

export default LobbyUsernameEditor;