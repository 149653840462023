/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {createStyles} from "@mantine/core";
import LobbyPlayerListItem from "./LobbyPlayerListItem";
import {PlayerDto} from "../../model/proto/dto";
import SlideAnimation, {SlideDirection} from "../../components/animation/SlideAnimation";

/**
 * Type that represents the props of the {@link LobbyPlayerList}.
 *
 * playerList: List of {@link PlayerDto} instances that should be displayed.
 */
export type PlayerListProps = {
    playerList: PlayerDto[];
};

const useStyles = createStyles({
    itemContainer: {
        display: "flex",
        flexDirection: "column",
    },
});

/**
 * Component that represents both players.
 * This component consists of {@link LobbyPlayerListItem} instances.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const LobbyPlayerList = (props: PlayerListProps) => {

    const {classes} = useStyles();

    return (
        <SlideAnimation
            layout
            className={classes.itemContainer}
            direction={SlideDirection.TOP}>
            {props.playerList.map((player) => (
                <LobbyPlayerListItem
                    key={player.uuid}
                    player={player}/>
            ))}
        </SlideAnimation>
    );
};

export default LobbyPlayerList;
