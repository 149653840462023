/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { EmphasizedPosition } from "../../components/grid/GameGrid";
import { RoomConnectionContext } from "../rooms/useRoomConnection";
import { NodeState } from "../../components/grid/BaseGridNode";
import { FightResult, FightResultEvent, StrategoEvent } from "../../model/proto/event";
import { Sword } from "@styled-icons/remix-fill/Sword";
import { notifications } from "@mantine/notifications";
import { Rank } from "../../model/proto/dto";
import { getPlayerFromUUID } from "../../helper/Utils";

/**
 * Custom hook that contains the implementation of the frontend fighting logic.
 *
 * @param setMovementLocked Function that enables/disables the movement logic.
 * @param interruptState Function that interrupts the state updates of the page for a specific time interval.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const useFightingController = (setMovementLocked: (locked: boolean) => void, interruptState: (delay: number) => void): EmphasizedPosition[] => {

    const roomConnection = useContext(RoomConnectionContext);

    const [fightingPositions, setFightingPositions] = useState<EmphasizedPosition[]>(null);

    /**
     * Method that creates the notification message of the fight result.
     *
     * @param result Result the message should be created for.
     */
    const _createNotificationMessage = (result: FightResultEvent): string => {
        const attackerPlayerName = getPlayerFromUUID(result.attacker.figure.playerId, roomConnection.applicationState.playerList).name;
        const attackerFigureName = Rank[result.attacker.figure.rank].toLowerCase();
        const defenderPlayerName = getPlayerFromUUID(result.defender.figure.playerId, roomConnection.applicationState.playerList).name;
        const defenderFigureName = Rank[result.defender.figure.rank].toLowerCase();

        if (result.result === FightResult.DRAW) {
            return `Two ${attackerFigureName}s of ${attackerPlayerName} and ${defenderPlayerName} fought against each other and both died.`;
        } else if (result.result === FightResult.ATTACKER_WON) {
            if (result.defender.figure.rank === Rank.FLAG) {
                return `${attackerPlayerName} captured the flag of ${defenderPlayerName}`;
            }

            return `A ${attackerFigureName} of ${attackerPlayerName} won against a ${defenderFigureName} of ${defenderPlayerName}`;
        } else if (result.result === FightResult.DEFENDER_WON) {
            if (result.defender.figure.rank === Rank.BOMB) {
                return `A ${attackerFigureName} of ${attackerPlayerName} was blown up by a bomb of ${defenderPlayerName}`;
            }

            return `A ${defenderFigureName} of ${defenderPlayerName} won against a ${attackerFigureName} of ${attackerPlayerName}`;
        }
    };

    /**
     * Method that invokes the primary phase of the fighting animation.
     * This method sets the fighting positions to the two figures currently fighting against each other.
     * Moreover, this method reveals the ranks of the two figures.
     *
     * @param event Event that contains the result of the invoked fight,
     */
    const _invokePrimaryPhase = (event: StrategoEvent) => setTimeout(() => {
        setFightingPositions([
            {
                position: event.fightResultEvent.attacker.coordinate,
                state: NodeState.FOCUSED,
                contentOverride: event.fightResultEvent.attacker.figure.rank.toString(),
            },
            {
                position: event.fightResultEvent.defender.coordinate,
                state: NodeState.FOCUSED,
                contentOverride: event.fightResultEvent.defender.figure.rank.toString(),
            }
        ]);
    }, 1000);

    /**
     * Method that invokes the secondary phase of the fighting animation.
     * This method publishes the results of the fight.
     *
     * @param event Event that contains the result of the fight.
     */
    const _invokeSecondaryPhase = (event: StrategoEvent) => setTimeout(() => {
        setFightingPositions([
            {
                position: event.fightResultEvent.attacker.coordinate,
                state: event.fightResultEvent.result === FightResult.ATTACKER_WON ? NodeState.FIGHT_WINNER : NodeState.FIGHT_LOSER,
            },
            {
                position: event.fightResultEvent.defender.coordinate,
                state: event.fightResultEvent.result === FightResult.DEFENDER_WON ? NodeState.FIGHT_WINNER : NodeState.FIGHT_LOSER,
            }
        ]);

        // Show the result notification
        notifications.show({
            id: "fight_result_" + new Date().getTime(),
            autoClose: 12000,
            color: "blue",
            title: "Fight Completed",
            message: _createNotificationMessage(event.fightResultEvent),
            icon: <Sword width={20} height={20}/>
        });
    }, 2000);

    /**
     * Method that invokes the tertiary phase of the fighting animation.
     * This method reset all parameters.
     */
    const _invokeTertiaryPhase = () => setTimeout(() => {
        setMovementLocked(false);
        setFightingPositions(null);
    }, 4000);

    useEffect(() => {
        const eventListenerID = "fight_result_event_listener"
        roomConnection.registerEventListener({
            listenerID: eventListenerID,
            filterPredicate: event => event.fightResultEvent !== undefined,
            fire: event => {
                setFightingPositions([
                    event.fightResultEvent.attacker.coordinate,
                    event.fightResultEvent.defender.coordinate,
                ].map(coordinate => ({
                    position: coordinate,
                    state: NodeState.FOCUSED,
                })));

                interruptState(4000);
                setMovementLocked(true);
                _invokePrimaryPhase(event);
                _invokeSecondaryPhase(event);
                _invokeTertiaryPhase();
            },
        });

        return () => roomConnection.unregisterEventListener(eventListenerID);
        // eslint-disable-next-line
    }, []);

    return fightingPositions;
};

export default useFightingController;