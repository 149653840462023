/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {Avatar, Card, createStyles} from "@mantine/core";

/**
 * Type that represents the props of the {@link LobbyPlayerListItemProfilePicture} component.
 *
 * imageSrc: Source of the profile image that is displayed.
 */
type LobbyPlayerInformationProps = {
    imageSrc: string
};

const useStyles = createStyles({
    accountPlayerBox: {
        height: '5.2rem',
        width: '5rem',
        margin: '0.3rem'
    },
    accountPlayerAvatar: {
        height: '100%',
        width: '100%',
    },
});

/**
 * Component that represents an Avatar of possible account. Has a default state.
 * This component is nested in the {@link LobbyPlayerListItem}.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const LobbyPlayerListItemProfilePicture = (props: LobbyPlayerInformationProps) => {

    const {classes} = useStyles();

    return (
        <Card
            className={classes.accountPlayerBox}
            radius="md"
            shadow={"sm"}
            withBorder>
            <Avatar
                className={classes.accountPlayerAvatar}
                alt="account logo"
                color="dark"
                variant="gradient"/>
        </Card>
    );
};

export default LobbyPlayerListItemProfilePicture;