/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {Box, Button, createStyles, Title} from "@mantine/core";
import {Error} from "@styled-icons/material-twotone/Error";
import {NoMeetingRoom} from "@styled-icons/material-outlined/NoMeetingRoom";
import {Groups} from "@styled-icons/material-twotone/Groups";
import {Home} from "@styled-icons/boxicons-regular/Home";
import {useNavigate} from "react-router-dom";
import SlideAnimation, {SlideDirection} from "../../components/animation/SlideAnimation";

/**
 * Type that represents the props of the {@link HostErrorView} component.
 *
 * icon: Icon of the component.
 * title: Error title of the component.
 * subtitle: Error subtitle of the component.
 */
type HostErrorViewProps = {
    icon: HostErrorViewIcon;
    title: string;
    subtitle: string;
};

export type HostErrorViewIcon = "not_found" | "at_capacity" | "general";

const useStyles = createStyles({
    icon: {
        width: "20vw",
        height: "20vh",
        margin: "1.5rem"
    },
    errorContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        width: "100%",
        height: "100vh"
    }
});

/**
 * Component that displays the error state of the {@link HostPage}.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const HostErrorView = (props: HostErrorViewProps) => {

    const {classes} = useStyles();
    const navigate = useNavigate();

    /**
     * Method that builds the error icon of the view.
     *
     * @return Returns the created error icon of the view.
     */
    const _buildErrorIcon = () => {
        switch (props.icon) {
            case "not_found":
                return <NoMeetingRoom
                    className={classes.icon}
                    color="red"/>
            case "at_capacity":
                return <Groups
                    className={classes.icon}
                    color="red"/>
            default:
                return <Error
                    className={classes.icon}
                    color="red"/>
        }
    };

    return (
        <Box
            className={classes.errorContainer}>
            <SlideAnimation direction={SlideDirection.TOP}>
                {_buildErrorIcon()}
            </SlideAnimation>
            <SlideAnimation direction={SlideDirection.TOP}>
                <Title
                    color="red"
                    order={3}>
                    {props.title}
                </Title>
            </SlideAnimation>
            <SlideAnimation direction={SlideDirection.BOTTOM}>
                <Title order={4}>
                    {props.subtitle}
                </Title>
            </SlideAnimation>
            <SlideAnimation direction={SlideDirection.BOTTOM}>
                <Button
                    color="red"
                    variant="outline"
                    sx={{marginTop: "1rem"}}
                    rightIcon={<Home size={20}/>}
                    onClick={() => navigate("/")}>
                    Back To Home
                </Button>
            </SlideAnimation>
        </Box>
    );
};

export default HostErrorView;