/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import {MutableRefObject, useEffect, useRef, useState} from "react";

/**
 * Type that represents the output structure of the {@link useInterruptableState} hook.
 *
 * state: Interuptable state that is exposed.
 * stateReference: Interuptable state that is exposed as a reference for async operations.
 * setState: Method that publishes a new state to the queue.
 * overrideState: Method that overrides the current state, neglecting the blocking.
 * interrupt: Method that interrupts the state updates by a given amount of time.
 */
type InterruptableStateHookStructure<T> = [
    state: T,
    stateReference: MutableRefObject<T>,
    setState: (newState: T) => void,
    overrideState: (newState: T) => void,
    interrupt: (delay: number) => void,
];

/**
 * Custom hook that provides a state that can be interrupted for a given time period.
 * After the time period passed, the state is updated again.
 * During this time period, the state can only be updated using the override method.
 * The implementation works by creating a proxy state that essentially clones the observed state.
 * However, in case the state is currently interrupted, the data is saved to a caching object.
 * After the time passed, the state updates from the cache are published to the proxy state.
 *
 * @param originalState State that should be observed.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const useInterruptableState = <T>(originalState: T): InterruptableStateHookStructure<T> => {

    const [proxyState, setProxyState] = useState<T>(originalState);
    const proxyStateReference = useRef<T>(originalState);
    const cachedState = useRef<T>(null);
    const interrupted = useRef<boolean>(false);

    useEffect(() => {
        if (interrupted.current) {
            cachedState.current = originalState;
        } else {
            setProxyState(originalState);
            proxyStateReference.current = originalState;
        }
    }, [originalState]);

    /**
     * Method that interrupts the state updates for a given amount of time.
     * After the time passed, the cached updates are published to the exposed proxy state.
     *
     * @param delay Timespan in which the state updates should be interrupted.
     */
    const interrupt = (delay: number) => {
        interrupted.current = true;
        setTimeout(() => {
            interrupted.current = false;
            setProxyState(cachedState.current);
            proxyStateReference.current = originalState;
        }, delay);
    };

    /**
     * Method that publishes a new state update.
     * However, if the state is currently in interrupted state, the update is only saved to the cache.
     *
     * @param newState State that should be cached or published.
     */
    const proxySetState = (newState: T) => {
        if (interrupted.current) {
            cachedState.current = newState;
        } else {
            setProxyState(newState);
            proxyStateReference.current = originalState;
        }
    };

    /**
     * Method that publishes a new state update.
     * This update is published, event if the state is currently in interrupted state.
     *
     * @param newState State that should be immediately published.
     */
    const proxyOverrideState = (newState: T) => {
        setProxyState(newState);
        proxyStateReference.current = originalState;
    };

    return [
        proxyState,
        proxyStateReference,
        proxySetState,
        proxyOverrideState,
        interrupt,
    ];
};

export default useInterruptableState;