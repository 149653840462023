/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import { PositioningTemplateIdentity } from "../model/templates";
import { useEffect, useState } from "react";
import axios from "axios";

/**
 * Type that represents the output structure of the positioning templates hook.
 *
 * templates: List of {@link PositioningTemplateIdentity} instances that was fetched.
 * pending: Determines whether the request is currently pending.
 * error: Contains any error that occurred during the process.
 */
type PositioningTemplatesHookStructure = [
    templates: PositioningTemplateIdentity[],
    pending: boolean,
    error: string,
];

/**
 * Custom hook that fetches the {@link PositioningTemplateIdentity} instances using the REST API.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const usePositioningTemplates = (): PositioningTemplatesHookStructure => {

    const [templates, setTemplates] = useState<PositioningTemplateIdentity[]>([]);
    const [pending, setPending] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);

    useEffect(() => {
        setPending(true);
        axios.get("/api/v1/templates")
            .then(result => {
                setPending(false);
                setError(null);
                setTemplates(result.data);
            })
            .catch(() => {
                setPending(false);
                setError("Unable to fetch templates");
            })
    }, []);

    return [
        templates,
        pending,
        error
    ];
};

export default usePositioningTemplates;