/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {CookiesProvider} from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MantineProvider theme={{colorScheme: "dark"}} withGlobalStyles withNormalizeCSS>
        <DndProvider backend={HTML5Backend}>
            <CookiesProvider>
                <Notifications/>
                <App/>
            </CookiesProvider>
        </DndProvider>
    </MantineProvider>
);
