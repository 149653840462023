/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {motion, MotionProps} from "framer-motion";

/**
 * Enum that represents the direction of the slide animation.
 */
export enum SlideDirection {
    LEFT,
    RIGHT,
    TOP,
    BOTTOM,
    CENTER,
}

/**
 * Type that represents the props of the {@link SlideAnimation} component.
 *
 * className: CSS class name passed to the component.
 * layout: Determines whether the layout property of the motion.div is enabled.
 * direction: Direction the animation starts in.
 * translation: Amount of pixels the slide is offset at the start.
 * speedFactor: Factor that influences the speed of the animation.
 * stiffness: Stiffness of the animation.
 * delay: Delay of the animation.
 */
type SlideAnimationProps = {
    className?: string;
    layout?: boolean;
    direction: SlideDirection;
    translation?: number;
    speedFactor?: number;
    stiffness?: number;
    delay?: number;
};

/**
 * Component that displays a slide animation on enter and on exit on its descendants.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const SlideAnimation = (props: MotionProps & SlideAnimationProps) => {

    const translation = props.translation ?? 100;

    /**
     * Method that calculates the translation on the x-axis depending on the given {@link SlideDirection}.
     *
     * @return Returns the calculated translation factor on the x-axis.
     */
    const _translateXFromDirection = (): number => {
        if (props.direction === SlideDirection.TOP || props.direction === SlideDirection.BOTTOM || props.direction === SlideDirection.CENTER)
            return 0;
        else
            return props.direction === SlideDirection.LEFT ? -translation : translation;
    };

    /**
     * Method that calculates the translation on the y-axis depending on the given {@link SlideDirection}.
     *
     * @return Returns the calculated translation factor on the y-axis.
     */
    const _translateYFromDirection = (): number => {
        if (props.direction === SlideDirection.LEFT || props.direction === SlideDirection.RIGHT || props.direction === SlideDirection.CENTER)
            return 0;
        else
            return props.direction === SlideDirection.TOP ? -translation : translation;
    }

    return (
        <motion.div
            className={props.className}
            {...props}
            initial={{
                opacity: 0,
                scale: 0,
                translateX: _translateXFromDirection(),
                translateY: _translateYFromDirection(),
            }}
            animate={{
                opacity: 1,
                scale: 1,
                translateX: 0,
                translateY: 0,
            }}
            exit={{
                opacity: 0,
                scale: 0,
                translateX: _translateXFromDirection(),
                translateY: _translateYFromDirection(),
            }}
            transition={{
                delay: props.delay,
                type: "spring",
                stiffness: props.stiffness ?? 260,
                damping: 50 * (props.speedFactor ?? 1.0),
            }}>
            {props.children}
        </motion.div>
    );
};

export default SlideAnimation;