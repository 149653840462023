// @ts-nocheck
// @generated by protobuf-ts 2.9.0
// @generated from protobuf file "request.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Rank } from "./dto";
import { PositionDto } from "./dto";
import { PlayerState } from "./dto";
/**
 * @generated from protobuf message StrategoRequest
 */
export interface StrategoRequest {
    /**
     * @generated from protobuf field: string access_token = 1;
     */
    accessToken: string;
    /**
     * @generated from protobuf field: string request_id = 2;
     */
    requestId: string;
    /**
     * @generated from protobuf field: PlayerAuthenticationRequest authentication_request = 3;
     */
    authenticationRequest?: PlayerAuthenticationRequest;
    /**
     * @generated from protobuf field: PlayerConfigurationRequest configuration_request = 4;
     */
    configurationRequest?: PlayerConfigurationRequest;
    /**
     * @generated from protobuf field: PlayerChangeStateRequest change_state_request = 5;
     */
    changeStateRequest?: PlayerChangeStateRequest;
    /**
     * @generated from protobuf field: ModifyPositioningRequest modify_positioning_request = 6;
     */
    modifyPositioningRequest?: ModifyPositioningRequest;
    /**
     * @generated from protobuf field: MoveFigureRequest move_figure_request = 7;
     */
    moveFigureRequest?: MoveFigureRequest;
    /**
     * @generated from protobuf field: PlayerPossibleMovesRequest possible_moves_request = 8;
     */
    possibleMovesRequest?: PlayerPossibleMovesRequest;
    /**
     * @generated from protobuf field: OfferOrAcceptDrawRequest offer_or_accept_draw_request = 9;
     */
    offerOrAcceptDrawRequest?: OfferOrAcceptDrawRequest;
}
/**
 * @generated from protobuf message PlayerAuthenticationRequest
 */
export interface PlayerAuthenticationRequest {
    /**
     * @generated from protobuf field: string refresh_token = 1;
     */
    refreshToken: string;
}
/**
 * @generated from protobuf message PlayerConfigurationRequest
 */
export interface PlayerConfigurationRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string color = 2;
     */
    color: string;
}
/**
 * @generated from protobuf message PlayerChangeStateRequest
 */
export interface PlayerChangeStateRequest {
    /**
     * @generated from protobuf field: PlayerState player_state = 1;
     */
    playerState: PlayerState;
}
/**
 * @generated from protobuf message MoveFigureRequest
 */
export interface MoveFigureRequest {
    /**
     * @generated from protobuf field: PositionDto sourceCoordinate = 1;
     */
    sourceCoordinate?: PositionDto;
    /**
     * @generated from protobuf field: PositionDto targetCoordinate = 2;
     */
    targetCoordinate?: PositionDto;
}
/**
 * @generated from protobuf message PlayerPossibleMovesRequest
 */
export interface PlayerPossibleMovesRequest {
    /**
     * @generated from protobuf field: PositionDto sourceCoordinate = 1;
     */
    sourceCoordinate?: PositionDto;
}
/**
 * @generated from protobuf message ModifyPositioningRequest
 */
export interface ModifyPositioningRequest {
    /**
     * @generated from protobuf field: PositioningAction positioning_action = 1;
     */
    positioningAction: PositioningAction;
    /**
     * @generated from protobuf field: PositionDto position = 2;
     */
    position?: PositionDto;
    /**
     * @generated from protobuf field: Rank figure_rank = 3;
     */
    figureRank: Rank;
    /**
     * @generated from protobuf field: string preset_id = 4;
     */
    presetId: string;
}
/**
 * @generated from protobuf message OfferOrAcceptDrawRequest
 */
export interface OfferOrAcceptDrawRequest {
    /**
     * @generated from protobuf field: DrawAction draw_action = 1;
     */
    drawAction: DrawAction;
}
/**
 * @generated from protobuf enum PositioningAction
 */
export enum PositioningAction {
    /**
     * @generated from protobuf enum value: POSITIONING_ACTION_PLACE = 0;
     */
    PLACE = 0,
    /**
     * @generated from protobuf enum value: POSITIONING_ACTION_REMOVE = 1;
     */
    REMOVE = 1,
    /**
     * @generated from protobuf enum value: POSITIONING_ACTION_LOAD = 2;
     */
    LOAD = 2,
    /**
     * @generated from protobuf enum value: POSITIONING_ACTION_RESET = 3;
     */
    RESET = 3
}
/**
 * @generated from protobuf enum DrawAction
 */
export enum DrawAction {
    /**
     * @generated from protobuf enum value: DRAW_ACTION_OFFER = 0;
     */
    OFFER = 0,
    /**
     * @generated from protobuf enum value: DRAW_ACTION_ACCEPT = 1;
     */
    ACCEPT = 1,
    /**
     * @generated from protobuf enum value: DRAW_ACTION_DECLINE = 2;
     */
    DECLINE = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class StrategoRequest$Type extends MessageType<StrategoRequest> {
    constructor() {
        super("StrategoRequest", [
            { no: 1, name: "access_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "request_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "authentication_request", kind: "message", T: () => PlayerAuthenticationRequest },
            { no: 4, name: "configuration_request", kind: "message", T: () => PlayerConfigurationRequest },
            { no: 5, name: "change_state_request", kind: "message", T: () => PlayerChangeStateRequest },
            { no: 6, name: "modify_positioning_request", kind: "message", T: () => ModifyPositioningRequest },
            { no: 7, name: "move_figure_request", kind: "message", T: () => MoveFigureRequest },
            { no: 8, name: "possible_moves_request", kind: "message", T: () => PlayerPossibleMovesRequest },
            { no: 9, name: "offer_or_accept_draw_request", kind: "message", T: () => OfferOrAcceptDrawRequest }
        ]);
    }
    create(value?: PartialMessage<StrategoRequest>): StrategoRequest {
        const message = { accessToken: "", requestId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StrategoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StrategoRequest): StrategoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string access_token */ 1:
                    message.accessToken = reader.string();
                    break;
                case /* string request_id */ 2:
                    message.requestId = reader.string();
                    break;
                case /* PlayerAuthenticationRequest authentication_request */ 3:
                    message.authenticationRequest = PlayerAuthenticationRequest.internalBinaryRead(reader, reader.uint32(), options, message.authenticationRequest);
                    break;
                case /* PlayerConfigurationRequest configuration_request */ 4:
                    message.configurationRequest = PlayerConfigurationRequest.internalBinaryRead(reader, reader.uint32(), options, message.configurationRequest);
                    break;
                case /* PlayerChangeStateRequest change_state_request */ 5:
                    message.changeStateRequest = PlayerChangeStateRequest.internalBinaryRead(reader, reader.uint32(), options, message.changeStateRequest);
                    break;
                case /* ModifyPositioningRequest modify_positioning_request */ 6:
                    message.modifyPositioningRequest = ModifyPositioningRequest.internalBinaryRead(reader, reader.uint32(), options, message.modifyPositioningRequest);
                    break;
                case /* MoveFigureRequest move_figure_request */ 7:
                    message.moveFigureRequest = MoveFigureRequest.internalBinaryRead(reader, reader.uint32(), options, message.moveFigureRequest);
                    break;
                case /* PlayerPossibleMovesRequest possible_moves_request */ 8:
                    message.possibleMovesRequest = PlayerPossibleMovesRequest.internalBinaryRead(reader, reader.uint32(), options, message.possibleMovesRequest);
                    break;
                case /* OfferOrAcceptDrawRequest offer_or_accept_draw_request */ 9:
                    message.offerOrAcceptDrawRequest = OfferOrAcceptDrawRequest.internalBinaryRead(reader, reader.uint32(), options, message.offerOrAcceptDrawRequest);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StrategoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string access_token = 1; */
        if (message.accessToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accessToken);
        /* string request_id = 2; */
        if (message.requestId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.requestId);
        /* PlayerAuthenticationRequest authentication_request = 3; */
        if (message.authenticationRequest)
            PlayerAuthenticationRequest.internalBinaryWrite(message.authenticationRequest, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* PlayerConfigurationRequest configuration_request = 4; */
        if (message.configurationRequest)
            PlayerConfigurationRequest.internalBinaryWrite(message.configurationRequest, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* PlayerChangeStateRequest change_state_request = 5; */
        if (message.changeStateRequest)
            PlayerChangeStateRequest.internalBinaryWrite(message.changeStateRequest, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* ModifyPositioningRequest modify_positioning_request = 6; */
        if (message.modifyPositioningRequest)
            ModifyPositioningRequest.internalBinaryWrite(message.modifyPositioningRequest, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* MoveFigureRequest move_figure_request = 7; */
        if (message.moveFigureRequest)
            MoveFigureRequest.internalBinaryWrite(message.moveFigureRequest, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* PlayerPossibleMovesRequest possible_moves_request = 8; */
        if (message.possibleMovesRequest)
            PlayerPossibleMovesRequest.internalBinaryWrite(message.possibleMovesRequest, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* OfferOrAcceptDrawRequest offer_or_accept_draw_request = 9; */
        if (message.offerOrAcceptDrawRequest)
            OfferOrAcceptDrawRequest.internalBinaryWrite(message.offerOrAcceptDrawRequest, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StrategoRequest
 */
export const StrategoRequest = new StrategoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlayerAuthenticationRequest$Type extends MessageType<PlayerAuthenticationRequest> {
    constructor() {
        super("PlayerAuthenticationRequest", [
            { no: 1, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PlayerAuthenticationRequest>): PlayerAuthenticationRequest {
        const message = { refreshToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlayerAuthenticationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlayerAuthenticationRequest): PlayerAuthenticationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string refresh_token */ 1:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlayerAuthenticationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string refresh_token = 1; */
        if (message.refreshToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PlayerAuthenticationRequest
 */
export const PlayerAuthenticationRequest = new PlayerAuthenticationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlayerConfigurationRequest$Type extends MessageType<PlayerConfigurationRequest> {
    constructor() {
        super("PlayerConfigurationRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "color", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PlayerConfigurationRequest>): PlayerConfigurationRequest {
        const message = { name: "", color: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlayerConfigurationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlayerConfigurationRequest): PlayerConfigurationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string color */ 2:
                    message.color = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlayerConfigurationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string color = 2; */
        if (message.color !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.color);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PlayerConfigurationRequest
 */
export const PlayerConfigurationRequest = new PlayerConfigurationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlayerChangeStateRequest$Type extends MessageType<PlayerChangeStateRequest> {
    constructor() {
        super("PlayerChangeStateRequest", [
            { no: 1, name: "player_state", kind: "enum", T: () => ["PlayerState", PlayerState] }
        ]);
    }
    create(value?: PartialMessage<PlayerChangeStateRequest>): PlayerChangeStateRequest {
        const message = { playerState: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlayerChangeStateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlayerChangeStateRequest): PlayerChangeStateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PlayerState player_state */ 1:
                    message.playerState = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlayerChangeStateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PlayerState player_state = 1; */
        if (message.playerState !== 0)
            writer.tag(1, WireType.Varint).int32(message.playerState);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PlayerChangeStateRequest
 */
export const PlayerChangeStateRequest = new PlayerChangeStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MoveFigureRequest$Type extends MessageType<MoveFigureRequest> {
    constructor() {
        super("MoveFigureRequest", [
            { no: 1, name: "sourceCoordinate", kind: "message", T: () => PositionDto },
            { no: 2, name: "targetCoordinate", kind: "message", T: () => PositionDto }
        ]);
    }
    create(value?: PartialMessage<MoveFigureRequest>): MoveFigureRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MoveFigureRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MoveFigureRequest): MoveFigureRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PositionDto sourceCoordinate */ 1:
                    message.sourceCoordinate = PositionDto.internalBinaryRead(reader, reader.uint32(), options, message.sourceCoordinate);
                    break;
                case /* PositionDto targetCoordinate */ 2:
                    message.targetCoordinate = PositionDto.internalBinaryRead(reader, reader.uint32(), options, message.targetCoordinate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MoveFigureRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PositionDto sourceCoordinate = 1; */
        if (message.sourceCoordinate)
            PositionDto.internalBinaryWrite(message.sourceCoordinate, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* PositionDto targetCoordinate = 2; */
        if (message.targetCoordinate)
            PositionDto.internalBinaryWrite(message.targetCoordinate, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MoveFigureRequest
 */
export const MoveFigureRequest = new MoveFigureRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlayerPossibleMovesRequest$Type extends MessageType<PlayerPossibleMovesRequest> {
    constructor() {
        super("PlayerPossibleMovesRequest", [
            { no: 1, name: "sourceCoordinate", kind: "message", T: () => PositionDto }
        ]);
    }
    create(value?: PartialMessage<PlayerPossibleMovesRequest>): PlayerPossibleMovesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlayerPossibleMovesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlayerPossibleMovesRequest): PlayerPossibleMovesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PositionDto sourceCoordinate */ 1:
                    message.sourceCoordinate = PositionDto.internalBinaryRead(reader, reader.uint32(), options, message.sourceCoordinate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlayerPossibleMovesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PositionDto sourceCoordinate = 1; */
        if (message.sourceCoordinate)
            PositionDto.internalBinaryWrite(message.sourceCoordinate, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PlayerPossibleMovesRequest
 */
export const PlayerPossibleMovesRequest = new PlayerPossibleMovesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModifyPositioningRequest$Type extends MessageType<ModifyPositioningRequest> {
    constructor() {
        super("ModifyPositioningRequest", [
            { no: 1, name: "positioning_action", kind: "enum", T: () => ["PositioningAction", PositioningAction, "POSITIONING_ACTION_"] },
            { no: 2, name: "position", kind: "message", T: () => PositionDto },
            { no: 3, name: "figure_rank", kind: "enum", T: () => ["Rank", Rank, "RANK_"] },
            { no: 4, name: "preset_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ModifyPositioningRequest>): ModifyPositioningRequest {
        const message = { positioningAction: 0, figureRank: 0, presetId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ModifyPositioningRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModifyPositioningRequest): ModifyPositioningRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PositioningAction positioning_action */ 1:
                    message.positioningAction = reader.int32();
                    break;
                case /* PositionDto position */ 2:
                    message.position = PositionDto.internalBinaryRead(reader, reader.uint32(), options, message.position);
                    break;
                case /* Rank figure_rank */ 3:
                    message.figureRank = reader.int32();
                    break;
                case /* string preset_id */ 4:
                    message.presetId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModifyPositioningRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PositioningAction positioning_action = 1; */
        if (message.positioningAction !== 0)
            writer.tag(1, WireType.Varint).int32(message.positioningAction);
        /* PositionDto position = 2; */
        if (message.position)
            PositionDto.internalBinaryWrite(message.position, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Rank figure_rank = 3; */
        if (message.figureRank !== 0)
            writer.tag(3, WireType.Varint).int32(message.figureRank);
        /* string preset_id = 4; */
        if (message.presetId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.presetId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ModifyPositioningRequest
 */
export const ModifyPositioningRequest = new ModifyPositioningRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OfferOrAcceptDrawRequest$Type extends MessageType<OfferOrAcceptDrawRequest> {
    constructor() {
        super("OfferOrAcceptDrawRequest", [
            { no: 1, name: "draw_action", kind: "enum", T: () => ["DrawAction", DrawAction, "DRAW_ACTION_"] }
        ]);
    }
    create(value?: PartialMessage<OfferOrAcceptDrawRequest>): OfferOrAcceptDrawRequest {
        const message = { drawAction: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OfferOrAcceptDrawRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OfferOrAcceptDrawRequest): OfferOrAcceptDrawRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* DrawAction draw_action */ 1:
                    message.drawAction = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OfferOrAcceptDrawRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* DrawAction draw_action = 1; */
        if (message.drawAction !== 0)
            writer.tag(1, WireType.Varint).int32(message.drawAction);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OfferOrAcceptDrawRequest
 */
export const OfferOrAcceptDrawRequest = new OfferOrAcceptDrawRequest$Type();
