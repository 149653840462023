/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useContext} from "react";
import {createStyles} from "@mantine/core";
import LobbyPlayerStateButton, {invertState} from "./LobbyPlayerStateButton";
import LobbyPlayerList from "./LobbyPlayerList";
import LobbySharePanel from "./LobbySharePanel";
import {RoomConnectionContext} from "../../hooks/rooms/useRoomConnection";
import {generateRequestID} from "../../helper/Utils";
import {AnimatePresence, LayoutGroup} from "framer-motion";

const useStyles = createStyles({
    homeContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
    },
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    link: {
        paddingLeft: "0.3rem",
        paddingRight: "0.3rem",
    },
    sharePanelContainer: {
        margin: "2rem"
    },
    playerListContainer: {
        display: "flex",
        flexDirection: "column"
    },
    lobbyButtonContainer: {
        marginLeft: "5.6rem",
        marginTop: "1rem"
    },
    waitingBox: {
        height: '5rem',
        margin: '0.3rem',
        padding: "1.5rem",
    },
});


/**
 * Page that represents the main lobby of the game.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const LobbyPage = () => {

    const {classes} = useStyles();
    const connection = useContext(RoomConnectionContext);

    const sortedPlayerList = [
        ...connection.applicationState.playerList.filter(player => player.uuid === connection.applicationState?.localPlayer?.uuid),
        ...connection.applicationState.playerList.filter(player => player.uuid !== connection.applicationState?.localPlayer?.uuid)
    ];

    return (
        <div className={classes.homeContainer}>
            <div className={classes.sharePanelContainer}>
                <LobbySharePanel roomCode={connection.applicationState.roomCode}/>
            </div>
            <div className={classes.playerListContainer}>
                <LayoutGroup>
                    <AnimatePresence>
                        <LobbyPlayerList playerList={sortedPlayerList}
                                         data-cy="LobbyPlayerList"
                        />
                        <div className={classes.lobbyButtonContainer}>
                            <LobbyPlayerStateButton
                                pending={false}
                                onClick={() => {
                                    const updateRequestID = generateRequestID("lobby_button_update");
                                    connection.sendRequestWithoutCallback(
                                        {
                                            accessToken: "",
                                            requestId: updateRequestID,
                                            changeStateRequest: {
                                                playerState: invertState(connection.applicationState.localPlayer?.playerState)
                                            }
                                        });
                                }}
                                playerState={connection.applicationState.localPlayer?.playerState}/>
                        </div>
                    </AnimatePresence>
                </LayoutGroup>
            </div>
        </div>
    );
};

export default LobbyPage;