/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {createStyles, Title} from "@mantine/core";
import RoomCodeInput from "./RoomCodeInput";
import useRoomAvailabilityCheck from "../../hooks/rooms/useRoomAvailabilityCheck";
import useRoomCreation from "../../hooks/rooms/useRoomCreation";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {RefreshTokenCookie} from "../../hooks/rooms/useRoomConnection";
import jwt_decode from "jwt-decode";
import SlideAnimation, {SlideDirection,} from "../../components/animation/SlideAnimation";
import TextButton from "../../components/TextButton";
import {AnimatePresence, LayoutGroup} from "framer-motion";
import {notifications} from "@mantine/notifications";
import {ErrorAlt} from "@styled-icons/boxicons-solid/ErrorAlt";
import RejoinAlert from "./RejoinAlert";
import useRoomSurrender from "../../hooks/rooms/useRoomSurrender";
import GameTitle from "../../components/GameTitle";

const useStyles = createStyles({
  homeContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    paddingLeft: "0.3rem",
    paddingRight: "0.3rem",
  },
  createContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "0.3rem",
  },
  reconnectContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "0.3rem",
  },
  title: {
    fontSize: "6rem",
  },
  errorContainer: {
    marginBottom: "0.3rem",
  },
  aboutContainer: {
    position: "fixed",
    textAlign: "center",
    width: "100%",
    bottom: "0.5rem",
    fontSize: "0.8rem",
    color: "#ADB5BD",
  },
  textNameContainer: {
    textDecoration: "underline",
    color: "#ADB5BD",
  },
});

/**
 * Page that represents the landing page of the application.
 * This page is used to create and join rooms.
 * Furthermore, the player name configuration is handled by it.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const HomePage = () => {
  const [cookies, , removeCookie] = useCookies([RefreshTokenCookie]);

  const [
    availabilityResult,
    availabilityError,
    availabilityPending,
    checkAvailability,
    clearStatus,
  ] = useRoomAvailabilityCheck();

  const _handleSuccessfulRoomCreation = (roomCode: string) =>
    _joinRoom(roomCode);

  const [creationPending, creationError, createRoom] = useRoomCreation(
    _handleSuccessfulRoomCreation
  );

  const [surrender] = useRoomSurrender();

  const { classes } = useStyles();
  const navigate = useNavigate();

  const _pending = availabilityPending || creationPending;

  /**
   * Method that joins a specific room.
   *
   * @param roomCode ID of the room that should be joined.
   */
  const _joinRoom = (roomCode: string) => {
    navigate(`/join?code=${roomCode}`);
  };

  /**
   * Method that attempts to reconnect to a recent room.
   * This method retrieves the refresh token from the cookies.
   * The room code included in this cookie is used to attempt the reconnection.
   */
  const _reconnectRoom = () => {
    const decoded = jwt_decode(cookies.refresh_token);
    // @ts-ignore
    if (decoded.room) {
      // @ts-ignore
      navigate(`/join?code=${decoded.room}`);
    } else {
      // @ts-ignore
      removeCookie(RefreshTokenCookie);
      notifications.show({
        id: "refresh_token_invalid",
        autoClose: 4000,
        color: "red",
        title: "Unable to reconnect",
        message: "The connection to the room cannot be established",
        icon: <ErrorAlt width={20} height={20} />,
      });
    }
  };

  const _surrenderPlayer = () => {
    const decoded = jwt_decode(cookies.refresh_token);
    // @ts-ignore
    if (decoded.room) {
      // @ts-ignore
      surrender(decoded.room, cookies.refresh_token, () =>
        removeCookie(RefreshTokenCookie)
      );
    } else {
      // @ts-ignore
      removeCookie(RefreshTokenCookie);
      notifications.show({
        id: "refresh_token_invalid",
        autoClose: 4000,
        color: "red",
        title: "Unable to Surrender",
        message: "The original room was not found",
        icon: <ErrorAlt width={20} height={20} />,
      });
    }
  };

  return (
    <div>
      <div className={classes.homeContainer}>
        <LayoutGroup>
          <AnimatePresence>
            <SlideAnimation layout direction={SlideDirection.TOP}>
              <GameTitle />
            </SlideAnimation>
            <SlideAnimation layout direction={SlideDirection.BOTTOM}>
              <Title order={4}>Enter code to join</Title>
            </SlideAnimation>
            <RoomCodeInput
              disabled={_pending}
              digitCount={6}
              state={availabilityResult}
              pending={availabilityPending}
              onSubmit={(roomCode) =>
                checkAvailability(roomCode, cookies.refresh_token)
              }
              onClear={clearStatus}
              onActionButtonClick={_joinRoom}
            />
            {(availabilityError || creationError) && (
              <SlideAnimation direction={SlideDirection.RIGHT} layout>
                <Title className={classes.errorContainer} order={4} color="red">
                  {availabilityError
                    ? availabilityError
                    : creationError
                    ? creationError
                    : "Internal Server Error"}
                </Title>
              </SlideAnimation>
            )}
            <SlideAnimation
              layout
              className={classes.createContainer}
              direction={SlideDirection.BOTTOM}
            >
              <Title order={4}>Don't have an invite code? -</Title>
              <TextButton disabled={_pending} onClick={createRoom}>
                <Title order={4} color="blue">
                  Create Game
                </Title>
              </TextButton>
            </SlideAnimation>
            {cookies.refresh_token && (
              <RejoinAlert
                reconnect={_reconnectRoom}
                surrender={_surrenderPlayer}
              />
            )}
          </AnimatePresence>
        </LayoutGroup>
      </div>
      <SlideAnimation
        direction={SlideDirection.BOTTOM}
        className={classes.aboutContainer}
      >
        Built by{" "}
        <a
          href={"https://github.com/M4x-Dev"}
          className={classes.textNameContainer}
        >
          Maximilian Flügel
        </a>
        ,{" "}
        <a
          href={"https://github.com/jb381"}
          className={classes.textNameContainer}
        >
          Jannes Bikker
        </a>
        ,{" "}
        <a
          href={"https://github.com/aliCodet3"}
          className={classes.textNameContainer}
        >
          Alina Simon
        </a>{" "}
        and{" "}
        <a
          href={"https://gitlab.com/NikiLugi"}
          className={classes.textNameContainer}
        >
          Niklas Lugowski
        </a>
      </SlideAnimation>
    </div>
  );
};

export default HomePage;
