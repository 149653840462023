/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { Button, createStyles } from "@mantine/core";
import { PlayerState } from "../../model/proto/dto";
import { Done } from "@styled-icons/material-rounded/Done";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import "./LobbyPlayerStateButton.css";
import SlideAnimation, { SlideDirection } from "../../components/animation/SlideAnimation";

/**
 * Function that inverts a given {@link PlayerState}.
 *
 * @param state {@link PlayerState} that should be inverted.
 *
 * @return Returns the inverted {@link PlayerState}.
 */
export const invertState = (state: PlayerState): PlayerState => {
    return state === PlayerState.NOT_READY ? PlayerState.READY : PlayerState.NOT_READY
}

/**
 * Type that represents the props of the {@link LobbyPlayerStateButton} component.
 *
 * onClick: Callback that is invoked when the button is clicked.
 * pending: Determines whether the button currently is in pending state.
 * playerState: State of the player
 */
type LobbyPlayerStateButtonProps = {
    onClick?: () => void;
    pending: boolean;
    playerState: PlayerState;
};

const useStyles = createStyles({
    button: {
        margin: "0.2",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "2.5rem",
        transition: "all .2s",
        padding: 0,
        width: "20rem",
        marginLeft: "0.2rem",
    },
});

/**
 * Component that changes the state of the player.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const LobbyPlayerStateButton = (props: LobbyPlayerStateButtonProps) => {

    const {classes} = useStyles();

    return (
        <SlideAnimation direction={SlideDirection.BOTTOM}>
            <Button
                id="lobby-button"
                data-cy="LobbyStateChangeButton"
                variant="gradient"
                loading={props.pending}
                rightIcon={props.playerState === PlayerState.READY ? <CloseOutline size={20}/> : <Done size={20}/>}
                className={classes.button}
                radius="md"
                gradient={props.playerState === PlayerState.READY ? {
                    from: "orange",
                    to: "red",
                } : {
                    from: "teal",
                    to: "lime",
                    deg: 105,
                }}
                onClick={props.onClick}
            >
                {props.playerState === PlayerState.READY ? "Not Ready" : "Ready"}
            </Button>
        </SlideAnimation>
    );
};

export default LobbyPlayerStateButton;