/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

export const colors = {
    blue: "#2F5880",
    darkBlue: "#052646",
    red: "#CD7B7B",
    darkRed: "#8B5454",
    ground: "#687466",
    darkGround: "#3E463D",
    semiTransparentGround: "rgba(104,116,102,0.2)",
    lake: "#90B4FB",
    darkLake: "#7F95C0",
    semiTransparentLake: "rgba(144,180,251,0.2)",
    default: "#000000",
    transparent: "rgba(0,0,0,0)",
};