// @ts-nocheck
// @generated by protobuf-ts 2.9.0
// @generated from protobuf file "common.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType as MessageType$ } from "@protobuf-ts/runtime";
import { StrategoEvent } from "./event";
import { StrategoResponse } from "./response";
import { StrategoRequest } from "./request";
/**
 * @generated from protobuf message StrategoMessage
 */
export interface StrategoMessage {
    /**
     * @generated from protobuf field: MessageType message_type = 1;
     */
    messageType: MessageType;
    /**
     * @generated from protobuf field: StrategoRequest request = 2;
     */
    request?: StrategoRequest;
    /**
     * @generated from protobuf field: StrategoResponse response = 3;
     */
    response?: StrategoResponse;
    /**
     * @generated from protobuf field: StrategoEvent event = 4;
     */
    event?: StrategoEvent;
}
/**
 * @generated from protobuf enum MessageType
 */
export enum MessageType {
    /**
     * @generated from protobuf enum value: MESSAGE_TYPE_REQUEST = 0;
     */
    REQUEST = 0,
    /**
     * @generated from protobuf enum value: MESSAGE_TYPE_RESPONSE = 1;
     */
    RESPONSE = 1,
    /**
     * @generated from protobuf enum value: MESSAGE_TYPE_EVENT = 2;
     */
    EVENT = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class StrategoMessage$Type extends MessageType$<StrategoMessage> {
    constructor() {
        super("StrategoMessage", [
            { no: 1, name: "message_type", kind: "enum", T: () => ["MessageType", MessageType, "MESSAGE_TYPE_"] },
            { no: 2, name: "request", kind: "message", T: () => StrategoRequest },
            { no: 3, name: "response", kind: "message", T: () => StrategoResponse },
            { no: 4, name: "event", kind: "message", T: () => StrategoEvent }
        ]);
    }
    create(value?: PartialMessage<StrategoMessage>): StrategoMessage {
        const message = { messageType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StrategoMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StrategoMessage): StrategoMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* MessageType message_type */ 1:
                    message.messageType = reader.int32();
                    break;
                case /* StrategoRequest request */ 2:
                    message.request = StrategoRequest.internalBinaryRead(reader, reader.uint32(), options, message.request);
                    break;
                case /* StrategoResponse response */ 3:
                    message.response = StrategoResponse.internalBinaryRead(reader, reader.uint32(), options, message.response);
                    break;
                case /* StrategoEvent event */ 4:
                    message.event = StrategoEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StrategoMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* MessageType message_type = 1; */
        if (message.messageType !== 0)
            writer.tag(1, WireType.Varint).int32(message.messageType);
        /* StrategoRequest request = 2; */
        if (message.request)
            StrategoRequest.internalBinaryWrite(message.request, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* StrategoResponse response = 3; */
        if (message.response)
            StrategoResponse.internalBinaryWrite(message.response, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* StrategoEvent event = 4; */
        if (message.event)
            StrategoEvent.internalBinaryWrite(message.event, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StrategoMessage
 */
export const StrategoMessage = new StrategoMessage$Type();
