/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Button, createStyles, Overlay, Title } from "@mantine/core";
import { PlugDisconnected } from "@styled-icons/fluentui-system-filled/PlugDisconnected";
import { motion } from "framer-motion";
import { colors } from "../../helper/ColorPalette";
import { useNavigate } from "react-router-dom";
import useRoomDeletion from "../../hooks/rooms/useRoomDeletion";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import { RefreshTokenCookie } from "../../hooks/rooms/useRoomConnection";
import { notifications } from "@mantine/notifications";
import { ErrorAlt } from "@styled-icons/boxicons-solid/ErrorAlt";

/**
 * Type that represents the props of the {@link GameInterruptedOverlay} component.
 *
 * show: Determines whether the overlay is visible.
 */
export type GameInterruptedOverlayProps = {
    show: boolean;
};

const useStyles = createStyles({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    titleContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    subContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "1rem",
    }
});

/**
 * Component that informs the user that the game was interrupted as one player disconnected.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const GameInterruptedOverlay = (props: GameInterruptedOverlayProps) => {

    const {classes} = useStyles();
    const navigate = useNavigate();
    const [cookies, , removeCookie] = useCookies();
    const [deleteRoom] = useRoomDeletion();

    const [abortTimeout, setAbortTimeout] = useState<number>(30);
    const abortTimeoutRef = useRef<number>(30);
    const timeoutRef = useRef<NodeJS.Timeout>(null);

    /**
     * Method that aborts the current game and deletes the room.
     * This method also removes the refresh token once the process was completed.
     */
    const _deleteRoom = () => {
        const decoded = jwt_decode(cookies.refresh_token);
        // @ts-ignore
        if (decoded.room) {
            // @ts-ignore
            deleteRoom(decoded.room, cookies.refresh_token, () => {
                removeCookie(RefreshTokenCookie);
                navigate("/");
            });
        } else {
            // @ts-ignore
            removeCookie(RefreshTokenCookie);
            notifications.show({
                id: "refresh_token_invalid",
                autoClose: 4000,
                color: "red",
                title: "Unable to abort game",
                message: "The room cannot be closed. Insufficient permissions",
                icon: <ErrorAlt width={20} height={20}/>,
            });
        }
    };

    /**
     * Method that recursively reduces the timeout to abort the game.
     * This method reduces the timeout value every second.
     */
    const _reduceTimer = () => {
        timeoutRef.current = setTimeout(() => {
            if (props.show) {
                abortTimeoutRef.current -= 1;
                setAbortTimeout(abortTimeoutRef.current);
                _reduceTimer();
            }
        }, 1000);
    };

    useEffect(() => {
        if (props.show) {
            _reduceTimer();
        } else {
            clearTimeout(timeoutRef.current);
            abortTimeoutRef.current = 30;
        }
        // eslint-disable-next-line
    }, [props.show]);

    useEffect(() => {
        setAbortTimeout(abortTimeoutRef.current);
        // eslint-disable-next-line
    }, [timeoutRef.current]);

    return (
        <Overlay
            sx={{
                pointerEvents: props.show ? "all" : "none",
                transition: "all 0.5s",
            }}
            opacity={props.show ? 0.7 : 0}
            blur={props.show ? 5 : 0}>
            {props.show && (
                <div className={classes.container}>
                    <motion.div
                        initial={{scale: 0}}
                        animate={{rotate: 360, scale: 1}}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 40,
                        }}>
                        <PlugDisconnected color={colors.red} size={150}/>
                    </motion.div>
                    <div className={classes.subContainer}>
                        <motion.div
                            className={classes.titleContainer}
                            initial={{scale: 0, translateY: 300}}
                            animate={{scale: 1, translateY: 0}}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 40,
                            }}>
                            <Title
                                order={1}
                                sx={{color: colors.red}}>
                                Player Disconnected
                            </Title>
                            <Title
                                sx={{marginBottom: "1rem"}}
                                order={3}>
                                The game will resume once the player returned
                            </Title>
                        </motion.div>
                        <motion.div
                            className={classes.titleContainer}
                            initial={{scale: 0, translateY: -300}}
                            animate={{scale: 1, translateY: 0}}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 40,
                            }}>
                            <Button
                                color="red"
                                disabled={abortTimeout > 0}
                                fullWidth
                                onClick={_deleteRoom}>
                                Abort Game {abortTimeout > 0 ? `(${abortTimeout})` : ""}
                            </Button>
                        </motion.div>
                    </div>
                </div>
            )}
        </Overlay>
    );
};

export default GameInterruptedOverlay;