/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useContext, useState} from "react";
import {RoomConnectionContext} from "../../hooks/rooms/useRoomConnection";
import GameGrid, {EmphasizedPosition} from "../../components/grid/GameGrid";
import {Button, createStyles, Title} from "@mantine/core";
import useMovementController from "../../hooks/controller/useMovementController";
import DotProgress from "../../components/loaders/DotProgress";
import useInterruptableState from "../../hooks/useInterruptableState";
import {NodeState} from "../../components/grid/BaseGridNode";
import useFightingController from "../../hooks/controller/useFightingController";
import useDrawController from "../../hooks/controller/useDrawController";
import {DrawState, PlayerDto} from "../../model/proto/dto";
import FigureExplanationDialog from "../../components/FigureExplanationDialog";
import PlayerCard, {PlayerResultCardState} from "./PlayerCard";
import GameResultOverlay from "./GameResultOverlay";
import GameResultPanel from "./GameResultPanel";
import {motion} from "framer-motion";
import {Home} from "@styled-icons/boxicons-regular/Home";
import {useNavigate} from "react-router-dom";
import {DrawAction} from "../../model/proto/request";
import InfoDrawDialog from "./InfoDrawDialog";
import GameInterruptedOverlay from "./GameInterruptedOverlay";
import {GamePhase} from "../../model/proto/event";
import Graveyard from "../../components/Graveyard";
import GameMenu from "./GameMenu";
import SlideAnimation, {SlideDirection,} from "../../components/animation/SlideAnimation";
import RevengeButton from "./RevengeButton";
import usePlayerReadyState from "../../hooks/usePlayerReadyState";
import GameInstructionsDialog from "../../components/GameInstructionsDialog";

const useStyles = createStyles({
  statusContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "3rem",
    width: "100%",
    transition: "opacity .4s",
  },
  gameResultPanelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "3rem",
    width: "100%",
  },
  gridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    height: "100vh",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: "3rem",
    width: "100%",
  },
  playerBoxes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    padding: "3rem",
    flexDirection: "column",
  },
  button: {
    margin: "0.2",
    alignItems: "center",
    justifyContent: "center",
    height: "2.5rem",
    transition: "all .2s",
    padding: 0,
    width: "20rem",
    marginLeft: "0.2rem",
  },
  postGameActionPanel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    bottom: "3rem",
    position: "absolute",
    gap: "2rem",
  },
  graveyardPlayercardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  graveyard: {
    display: "flex",
    width: "100%",
    marginRight: "2rem",
  },
});

/**
 * Main game page of the application.
 * This is the page where the actual game takes place.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */

const GamePage = () => {

  const [explanationDialogOpen, setExplanationDialogOpen] =
    useState<boolean>(false);
  const [instructionsDialogOpen, setInstructionsDialogOpen] =
    useState<boolean>(false);

  const { classes } = useStyles();
  const navigate = useNavigate();

  const roomConnection = useContext(RoomConnectionContext);
  const opponentPlayer = roomConnection.applicationState.playerList.find(
    (player) =>
      player.uuid !== roomConnection.applicationState.localPlayer?.uuid
  );

  const [applicationState, , , , interruptApplicationState] =
    useInterruptableState(roomConnection.applicationState);

  const [sourceNode, targetNode, handleNodeSelection, setMovementLocked] =
    useMovementController();

  const [sendChangeDrawRequest, offered, drawOpened, , drawDisabled] =
    useDrawController();
  const [changePlayerState] = usePlayerReadyState();

  const fightingPositions = useFightingController(
    setMovementLocked,
    interruptApplicationState
  );

  const [overlayDismissed, setOverlayDismissed] = useState<boolean>(false);

  /**
   * Method that retrieves all {@link EmphasizedPosition} instances of the current grid.
   * This method either returns the current selection or the fight data depending on the current situation.
   */
  const _getEmphasizedPositions = (): EmphasizedPosition[] => {
    return (
      fightingPositions ??
      applicationState.possibleMoves.map((move) => ({
        position: move,
        state: NodeState.HIGHLIGHTED,
      }))
    );
  };
  /**
   * Method that checks, if the player can request a draw
   *
   * @return Returns true, if player can not request a draw
   */
  const _isDisabled: boolean =
    roomConnection.applicationState.currentPlayer?.uuid ===
      opponentPlayer?.uuid ||
    roomConnection.applicationState.localPlayer?.drawState ===
      DrawState.REQUESTED ||
    opponentPlayer?.drawState === DrawState.REQUESTED ||
    drawDisabled;

  /**
   * Method that retrieves the {@link PlayerResultCardState} for a given player id.
   *
   * @param uuid id of the player the state should be resolved for.
   *
   * @return Returns the resolved {@link PlayerResultCardState}
   */
  const _getCardStateForPlayer = (
    uuid: string | null
  ): PlayerResultCardState => {
    if (!_isGameOver()) {
      return !uuid || applicationState?.currentPlayer?.uuid !== uuid
        ? PlayerResultCardState.DEFAULT
        : PlayerResultCardState.HIGHLIGHTED;
    } else {
      return applicationState?.gameResult?.winner?.uuid === uuid
        ? PlayerResultCardState.WINNER
        : PlayerResultCardState.LOSER;
    }
  };

  /**
   * Method that determines whether the game is over.
   *
   * @return Returns whether the game is over.
   */
  const _isGameOver = (): boolean => applicationState?.gameResult !== null;

  const _buildPlayerSection = (
    player: PlayerDto | null,
    animationFactor: number
  ) => {
    return (
      <div className={classes.graveyardPlayercardContainer}>
        <SlideAnimation
          direction={
            animationFactor < 0 ? SlideDirection.LEFT : SlideDirection.RIGHT
          }
          className={classes.playerBoxes}
        >
          <PlayerCard
            player={player}
            state={_getCardStateForPlayer(player?.uuid)}
            draw={
              applicationState?.localPlayer?.drawState !== DrawState.NONE &&
              opponentPlayer?.drawState !== DrawState.NONE
            }
          />
        </SlideAnimation>
        <SlideAnimation
          direction={
            animationFactor < 0 ? SlideDirection.LEFT : SlideDirection.RIGHT
          }
          className={classes.graveyard}
        >
          <Graveyard
            graveyardStacks={player.graveyard.stacks}
            player={player}
          />
        </SlideAnimation>
      </div>
    );
  };

    return (
        <div>
            {_isGameOver() && (
                <div className={classes.gameResultPanelContainer}>
                    <GameResultPanel
                        gameResult={applicationState?.gameResult}
                        localPlayer={roomConnection.applicationState?.localPlayer}
                        opponentPlayer={opponentPlayer}/>
                </div>
            )}
            <GameInterruptedOverlay
                show={roomConnection.applicationState?.playerList.some(player => !player.connected) && roomConnection.applicationState?.gamePhase !== GamePhase.COMPLETED}/>
            <FigureExplanationDialog
                playerColor={roomConnection.applicationState.localPlayer?.color ?? "blue"}
                opened={explanationDialogOpen}
                onDismiss={() => setExplanationDialogOpen(false)}
            />
            <GameInstructionsDialog
                opened={instructionsDialogOpen}
                onDismiss={() => setInstructionsDialogOpen(false)}
            />
            <GameResultOverlay
                show={_isGameOver() && !overlayDismissed}
                opponentPlayer={opponentPlayer}
                localPlayer={applicationState?.localPlayer}
                gameResult={applicationState?.gameResult}
                onClick={() => setOverlayDismissed(true)}/>
            <div
                data-cy="GamePageStatusContainer"
                className={classes.statusContainer}
                style={{
                    opacity: !_isGameOver() && roomConnection.applicationState.currentPlayer?.uuid !== roomConnection.applicationState.localPlayer?.uuid && !fightingPositions ? 1.0 : 0.0,
                }}>
                <Title order={4}>Waiting for {opponentPlayer?.name} to complete the turn</Title>
                <DotProgress/>
            </div>
            <GameMenu
                drawAvailable={_isDisabled}
                onDrawRequested={() => sendChangeDrawRequest(DrawAction.OFFER)}
                onHelpRequested={() => setExplanationDialogOpen(true)}
                onGameInstructionsRequested={() => setInstructionsDialogOpen(true)}
                roomCode={roomConnection.applicationState.roomCode}
                drawNotPossiblePhase={roomConnection.applicationState?.gamePhase === GamePhase.COMPLETED}/>
            <div className={classes.gridContainer}>
                {_buildPlayerSection(applicationState?.localPlayer, -1)}
                <GameGrid
                    gridData={applicationState.board}
                    playerList={roomConnection.applicationState.playerList}
                    emphasisData={{
                        emphasizedPositions: _getEmphasizedPositions(),
                        emphasizeWithZoom: fightingPositions !== null,
                    }}
                    onNodeClicked={node => {
                        if (!_isGameOver())
                            handleNodeSelection(node);
                    }}
                    source={sourceNode?.coordinate}
                    target={targetNode?.coordinate}/>
                {_buildPlayerSection(opponentPlayer, 1)}
            </div>
            {_isGameOver() && (
                <motion.div
                    className={classes.postGameActionPanel}
                    initial={{scale: 0, translateY: -300}}
                    animate={{scale: 1, translateY: 0}}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 50,
                    }}>

                    <Button
                        leftIcon={<Home size={25}/>}
                        onClick={() => navigate("/")}>
                        Back to Home
                    </Button>
                    <RevengeButton
                        opponentPlayer={opponentPlayer}
                        localPlayer={applicationState.localPlayer}
                        onClick={changePlayerState}/>
                </motion.div>
            )}
            {offered && <InfoDrawDialog
                oppositePlayerName={opponentPlayer?.name}
                opened={drawOpened} onDismiss={() => sendChangeDrawRequest(DrawAction.DECLINE)}
                onAcceptClick={() => sendChangeDrawRequest(DrawAction.ACCEPT)}
                onDeclinedClick={() => sendChangeDrawRequest(DrawAction.DECLINE)}
            />
            }
        </div>
    );
};

export default GamePage;
