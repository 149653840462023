// @ts-nocheck
// @generated by protobuf-ts 2.9.0
// @generated from protobuf file "response.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { PositionDto } from "./dto";
import { MoveErrorEnum } from "./dto";
import { PlayerDto } from "./dto";
/**
 * @generated from protobuf message StrategoResponse
 */
export interface StrategoResponse {
    /**
     * @generated from protobuf field: string player_uuid = 1;
     */
    playerUuid: string;
    /**
     * @generated from protobuf field: string request_id = 2;
     */
    requestId: string;
    /**
     * @generated from protobuf field: StrategoResponseStatus status = 3;
     */
    status?: StrategoResponseStatus;
    /**
     * @generated from protobuf field: PlayerAuthenticationResponse authentication_response = 4;
     */
    authenticationResponse?: PlayerAuthenticationResponse;
    /**
     * @generated from protobuf field: MoveFigureResponse move_figure_response = 5;
     */
    moveFigureResponse?: MoveFigureResponse;
    /**
     * @generated from protobuf field: PlayerPossibleMovesResponse possible_moves_response = 6;
     */
    possibleMovesResponse?: PlayerPossibleMovesResponse;
}
/**
 * @generated from protobuf message StrategoResponseStatus
 */
export interface StrategoResponseStatus {
    /**
     * @generated from protobuf field: bool succeeded = 1;
     */
    succeeded: boolean;
    /**
     * @generated from protobuf field: string status = 2;
     */
    status: string;
}
/**
 * @generated from protobuf message PlayerAuthenticationResponse
 */
export interface PlayerAuthenticationResponse {
    /**
     * @generated from protobuf field: PlayerDto initial_configuration = 1;
     */
    initialConfiguration?: PlayerDto;
    /**
     * @generated from protobuf field: string access_token = 2;
     */
    accessToken: string;
    /**
     * @generated from protobuf field: string refresh_token = 3;
     */
    refreshToken: string;
}
/**
 * @generated from protobuf message MoveFigureResponse
 */
export interface MoveFigureResponse {
    /**
     * @generated from protobuf field: MoveErrorEnum moveError = 1;
     */
    moveError: MoveErrorEnum;
}
/**
 * @generated from protobuf message PlayerPossibleMovesResponse
 */
export interface PlayerPossibleMovesResponse {
    /**
     * @generated from protobuf field: repeated PositionDto possible_moves = 1;
     */
    possibleMoves: PositionDto[];
}
// @generated message type with reflection information, may provide speed optimized methods
class StrategoResponse$Type extends MessageType<StrategoResponse> {
    constructor() {
        super("StrategoResponse", [
            { no: 1, name: "player_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "request_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "message", T: () => StrategoResponseStatus },
            { no: 4, name: "authentication_response", kind: "message", T: () => PlayerAuthenticationResponse },
            { no: 5, name: "move_figure_response", kind: "message", T: () => MoveFigureResponse },
            { no: 6, name: "possible_moves_response", kind: "message", T: () => PlayerPossibleMovesResponse }
        ]);
    }
    create(value?: PartialMessage<StrategoResponse>): StrategoResponse {
        const message = { playerUuid: "", requestId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StrategoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StrategoResponse): StrategoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string player_uuid */ 1:
                    message.playerUuid = reader.string();
                    break;
                case /* string request_id */ 2:
                    message.requestId = reader.string();
                    break;
                case /* StrategoResponseStatus status */ 3:
                    message.status = StrategoResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* PlayerAuthenticationResponse authentication_response */ 4:
                    message.authenticationResponse = PlayerAuthenticationResponse.internalBinaryRead(reader, reader.uint32(), options, message.authenticationResponse);
                    break;
                case /* MoveFigureResponse move_figure_response */ 5:
                    message.moveFigureResponse = MoveFigureResponse.internalBinaryRead(reader, reader.uint32(), options, message.moveFigureResponse);
                    break;
                case /* PlayerPossibleMovesResponse possible_moves_response */ 6:
                    message.possibleMovesResponse = PlayerPossibleMovesResponse.internalBinaryRead(reader, reader.uint32(), options, message.possibleMovesResponse);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StrategoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string player_uuid = 1; */
        if (message.playerUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.playerUuid);
        /* string request_id = 2; */
        if (message.requestId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.requestId);
        /* StrategoResponseStatus status = 3; */
        if (message.status)
            StrategoResponseStatus.internalBinaryWrite(message.status, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* PlayerAuthenticationResponse authentication_response = 4; */
        if (message.authenticationResponse)
            PlayerAuthenticationResponse.internalBinaryWrite(message.authenticationResponse, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* MoveFigureResponse move_figure_response = 5; */
        if (message.moveFigureResponse)
            MoveFigureResponse.internalBinaryWrite(message.moveFigureResponse, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* PlayerPossibleMovesResponse possible_moves_response = 6; */
        if (message.possibleMovesResponse)
            PlayerPossibleMovesResponse.internalBinaryWrite(message.possibleMovesResponse, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StrategoResponse
 */
export const StrategoResponse = new StrategoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StrategoResponseStatus$Type extends MessageType<StrategoResponseStatus> {
    constructor() {
        super("StrategoResponseStatus", [
            { no: 1, name: "succeeded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StrategoResponseStatus>): StrategoResponseStatus {
        const message = { succeeded: false, status: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StrategoResponseStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StrategoResponseStatus): StrategoResponseStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool succeeded */ 1:
                    message.succeeded = reader.bool();
                    break;
                case /* string status */ 2:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StrategoResponseStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool succeeded = 1; */
        if (message.succeeded !== false)
            writer.tag(1, WireType.Varint).bool(message.succeeded);
        /* string status = 2; */
        if (message.status !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StrategoResponseStatus
 */
export const StrategoResponseStatus = new StrategoResponseStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlayerAuthenticationResponse$Type extends MessageType<PlayerAuthenticationResponse> {
    constructor() {
        super("PlayerAuthenticationResponse", [
            { no: 1, name: "initial_configuration", kind: "message", T: () => PlayerDto },
            { no: 2, name: "access_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PlayerAuthenticationResponse>): PlayerAuthenticationResponse {
        const message = { accessToken: "", refreshToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlayerAuthenticationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlayerAuthenticationResponse): PlayerAuthenticationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PlayerDto initial_configuration */ 1:
                    message.initialConfiguration = PlayerDto.internalBinaryRead(reader, reader.uint32(), options, message.initialConfiguration);
                    break;
                case /* string access_token */ 2:
                    message.accessToken = reader.string();
                    break;
                case /* string refresh_token */ 3:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlayerAuthenticationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PlayerDto initial_configuration = 1; */
        if (message.initialConfiguration)
            PlayerDto.internalBinaryWrite(message.initialConfiguration, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string access_token = 2; */
        if (message.accessToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accessToken);
        /* string refresh_token = 3; */
        if (message.refreshToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PlayerAuthenticationResponse
 */
export const PlayerAuthenticationResponse = new PlayerAuthenticationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MoveFigureResponse$Type extends MessageType<MoveFigureResponse> {
    constructor() {
        super("MoveFigureResponse", [
            { no: 1, name: "moveError", kind: "enum", T: () => ["MoveErrorEnum", MoveErrorEnum] }
        ]);
    }
    create(value?: PartialMessage<MoveFigureResponse>): MoveFigureResponse {
        const message = { moveError: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MoveFigureResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MoveFigureResponse): MoveFigureResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* MoveErrorEnum moveError */ 1:
                    message.moveError = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MoveFigureResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* MoveErrorEnum moveError = 1; */
        if (message.moveError !== 0)
            writer.tag(1, WireType.Varint).int32(message.moveError);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MoveFigureResponse
 */
export const MoveFigureResponse = new MoveFigureResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlayerPossibleMovesResponse$Type extends MessageType<PlayerPossibleMovesResponse> {
    constructor() {
        super("PlayerPossibleMovesResponse", [
            { no: 1, name: "possible_moves", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PositionDto }
        ]);
    }
    create(value?: PartialMessage<PlayerPossibleMovesResponse>): PlayerPossibleMovesResponse {
        const message = { possibleMoves: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlayerPossibleMovesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlayerPossibleMovesResponse): PlayerPossibleMovesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PositionDto possible_moves */ 1:
                    message.possibleMoves.push(PositionDto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlayerPossibleMovesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated PositionDto possible_moves = 1; */
        for (let i = 0; i < message.possibleMoves.length; i++)
            PositionDto.internalBinaryWrite(message.possibleMoves[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PlayerPossibleMovesResponse
 */
export const PlayerPossibleMovesResponse = new PlayerPossibleMovesResponse$Type();
