/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {generateRequestID} from "../../helper/Utils";
import {DrawAction} from "../../model/proto/request";
import {PlayerDto} from "../../model/proto/dto";
import {notifications} from "@mantine/notifications";
import {Handshake} from "@styled-icons/fluentui-system-filled/Handshake";
import {RoomConnectionContext} from "../rooms/useRoomConnection";

/**
 * Type that represents the output structure of the draw controller.
 *
 * sendChangeDrawRequest: Callback that is invoked when the draw button was selected.
 * offered: state that shows if a draw request is offered
 * opened: state that shows if a modal should open in the FE
 * setDrawOpened: method that can set the opened parameter
 * drawDisabled: state that shows if the player declined a request in this turn
 */
type DrawHookStructure = [
    sendChangeDrawRequest: (drawAction: DrawAction) => void,
    offered: boolean,
    opened: boolean,
    setDrawOpened: Dispatch<SetStateAction<boolean>>,
    drawDisabled: boolean,
];

/**
 * Custom hook that contains the implementation of the change drawState logic.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const useDrawController = (): DrawHookStructure => {

    const [offered, setOffered] = useState<boolean>(false);
    const [drawOpened, setDrawOpened] = useState(false);
    const [drawDisabled, setDrawDisabled] = useState(false);
    const roomConnection = useContext(RoomConnectionContext);

    useEffect(() => {
        roomConnection.registerEventListener({
            listenerID: "draw_offered_event_listener",
            filterPredicate: event => event.drawOfferedEvent !== undefined,
            fire: event => {
                setOffered(true);
                const player: PlayerDto = roomConnection.applicationState.playerList.find(player =>
                    player.uuid === event.drawOfferedEvent.causePlayerId);

                if (player && player.uuid !== roomConnection.applicationStateReference.current.localPlayer.uuid) {
                    setDrawOpened(true);
                }
            }
        });

        roomConnection.registerEventListener({
            listenerID: "draw_declined_event_listener",
            filterPredicate: event => event.drawDeclinedEvent !== undefined,
            fire: event => {
                setOffered(false);
                const player: PlayerDto = roomConnection.applicationState.playerList.find(player =>
                    player.uuid === event.drawDeclinedEvent.causePlayerId);
                const opponentPlayer = roomConnection.applicationState.playerList.find(player => player.uuid !== roomConnection.applicationState.localPlayer?.uuid);

                if (player && player.uuid !== roomConnection.applicationStateReference.current.localPlayer.uuid) {
                    notifications.show({
                        id: "draw_declined",
                        autoClose: 1000,
                        color: "red",
                        title: "Request declined",
                        message: opponentPlayer.name + " declined your request!",
                        icon: <Handshake width={20} height={20}/>
                    });
                    setDrawDisabled(true);
                }
            }
        });

        roomConnection.registerEventListener({
            listenerID: "draw_player_update_listener",
            filterPredicate: (event) => event.currentPlayerChangedEvent !== undefined,
            fire: () => setDrawDisabled(false),
        });

        return () => {
            roomConnection.unregisterEventListener("draw_offered_event_listener");
            roomConnection.unregisterEventListener("draw_declined_event_listener");
            roomConnection.unregisterEventListener("draw_player_update_listener");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Method that sends an offer or accept draw request to the backend.
     *
     * @param drawAction: {@link DrawAction} that is sent to the backend.
     */
    const sendOfferOrAcceptDrawRequest = (drawAction: DrawAction) => {
        const requestID = generateRequestID("offer_or_accept_draw_request");
        roomConnection.sendRequestWithoutCallback(
            {
                accessToken: "",
                requestId: requestID,
                offerOrAcceptDrawRequest: {
                    drawAction: drawAction
                }
            }
        );

        setDrawOpened(false);
    };

    return [
        sendOfferOrAcceptDrawRequest,
        offered,
        drawOpened,
        setDrawOpened,
        drawDisabled
    ];
};

export default useDrawController;