/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { Button, Popover, Title, useMantineTheme } from "@mantine/core";
import { GroupAdd } from "@styled-icons/material-rounded/GroupAdd";
import TextButton from "../../components/TextButton";
import { Copy } from "@styled-icons/boxicons-regular/Copy";
import { copyToClipboard, generateJoinURL } from "../../helper/Utils";

/**
 * Type that represents the props of the {@link LobbyShareSheet}.
 *
 * roomCode: Code of the room that should be sharable.
 */
type LobbyShareSheetProps = {
    roomCode: string;
};

/**
 * Component that allows the user to view and share room invitation information.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const LobbyShareSheet = (props: LobbyShareSheetProps) => {

    const theme = useMantineTheme();

    const joinURL = generateJoinURL(props.roomCode);

    return (
        <div>
            <Popover position="bottom" withArrow>
                <Popover.Target>
                    <Button
                        style={{color: theme.colors.blue[9]}}
                        variant="subtle"
                        rightIcon={<GroupAdd width={25} height={25}/>}>
                        <Title order={3}>
                            Invite
                        </Title>
                    </Button>
                </Popover.Target>
                <Popover.Dropdown>
                    <Title order={3}>
                        To invite your friends to this room, you can
                    </Title>
                    <Title order={4}>
                        1. Scan the QR-Code above
                    </Title>
                    <Title order={4}>
                        2. Enter the code
                    </Title>
                    <TextButton
                        rightIcon={<Copy width={25} height={25}/>}
                        onClick={() => copyToClipboard(props.roomCode)}>
                        <Title order={4}>
                            {props.roomCode}
                        </Title>
                    </TextButton>
                    <Title order={4}>
                        3. Share this link
                    </Title>
                    <TextButton
                        rightIcon={<Copy width={25} height={25}/>}
                        onClick={() => copyToClipboard(joinURL)}>
                        <Title order={4}>
                            {joinURL}
                        </Title>
                    </TextButton>
                </Popover.Dropdown>
            </Popover>
        </div>
    );
};

export default LobbyShareSheet;