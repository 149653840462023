/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import BaseGridNode, {BaseGridNodeProps, DIAMETER_DEFAULT, NodeState} from "./BaseGridNode";
import {colors} from "../../helper/ColorPalette";

/**
 * Type that represents the props of the {@link BaseGridBorderNode} component.
 *
 * color: Main color of the border node.
 * semiTransparentColor: Semi-transparent color of the border node.
 */
type BaseGridBorderNodeProps = {
    color: string;
    semiTransparentColor: string;
};

/**
 * Component that represents a border node of the grid.
 * A border node fades to transparency to the top border of the component.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const BaseGridBorderNode = (props: BaseGridBorderNodeProps & BaseGridNodeProps) => {
    return (
        <BaseGridNode
            text=""
            state={NodeState.DEFAULT}
            color={`linear-gradient(0deg, ${props.color} 0%, ${props.semiTransparentColor} 40%, ${colors.transparent} 100%)`}
            diameter={props.diameter ?? DIAMETER_DEFAULT}
            hoverable={false}/>
    );
};

export default BaseGridBorderNode;