/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import {Card, createStyles, Image, Indicator} from "@mantine/core";
import * as React from "react";
import {useEffect} from "react";
import {BaseStackDto, Rank} from "../../model/proto/dto";
import {useDrag} from "react-dnd";
import {colors} from "../../helper/ColorPalette";
import {ItemTypes} from "../../helper/DragAndDropConfiguration";

/**
 * Type that represents the props of the {@link PlayerInventoryStack} component.
 *
 * playerColor: Color of the player.
 * figureStack: Stack that is displayed by the component.
 * onStackClicked: Callback that is invoked when a stack of the inventory is clicked.
 * onDragStart: Callback that is invoked when the user started dragging a stack.
 * onDragStop: Callback that is invoked when the user stopped dragging a stack.
 * selected: Determines whether the stack currently is selected.
 */
type PlayerInventoryStackProps = {
    playerColor: string;
    figureStack: BaseStackDto;
    onStackClicked: (stack: BaseStackDto) => void;
    onDragStart?: () => void;
    onDragStop?: () => void;
    selected: boolean;
};

const useStyles = createStyles((theme, props: PlayerInventoryStackProps) => ({
    stackContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        transition: "transform .1s",
        transform: props.selected ? "translateY(-0.7rem)" : "",
        "&:hover": {
            transform: props.figureStack.figures.length > 0 ? "translateY(-0.7rem)" : null,
        },
        marginTop: "1rem",
        marginLeft: "1rem",
        marginRight: "1rem",
        marginBottom: "2rem",
        cursor: "pointer",
        userSelect: "none",
    },
    card: {
        display: "flex",
        width: "4.5vw",
        minWidth: "60px",
        aspectRatio: "5 / 7",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "16px",
        borderWidth: props.selected ? "0.2rem" : "1px",
        borderStyle: "solid",
        borderColor: props.selected ? colors.red : "#868E96",
        padding: "3vw",
        position: "relative",
    },
    figurePicture: {
        width: "100%",
        height: "100%",
        filter: `blur(2px) brightness(${props.figureStack.figures.length > 0 ? "100" : "20"}%)`,
        position: "relative",
        userSelect: "none",
        pointerEvents: "none",
    },
    figureRank: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: props.playerColor,
        textShadow: "-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white",
        fontSize: "4vw",
        fontWeight: "bold",
        fontFamily: "Montserrat, sans-serif",
        pointerEvents: "none"
    },
    figureName: {
        marginBottom: "-1.4rem",
        color: "#868E96",
        fontWeight: "bold",
        fontSize: "0.8rem",
        fontFamily: "Montserrat, sans-serif",
        textAlign: "center",
        position: "absolute",
    },
    indicator: {
        borderRadius: "16px",
        color: "#868E96",
        fontFamily: "Montserrat, sans-serif",
    },
}));

/**
 * Component for a single stack of figures in the player inventory.
 * Displays the amount of figures in the stack and the rank of the specific figure.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const PlayerInventoryStack = (props: PlayerInventoryStackProps) => {

    const [{isDragging}, drag] = useDrag(() => ({
        type: ItemTypes.FIGURE_STACK,
        canDrag: () => props.figureStack.figures.length > 0,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
            canDrag: !!monitor.canDrag(),
        })
    }));

    const {classes} = useStyles(props);

    useEffect(() => {
        if (isDragging && props.onDragStart)
            props.onDragStart();
        else if (!isDragging && props.onDragStop)
            props.onDragStop();
        // eslint-disable-next-line
    }, [isDragging]);

    return (
        <div
            className={classes.stackContainer}
            data-cy={`PlayerInventoryStack-${props.figureStack.rank}`}
            ref={drag}>
            <Indicator className={classes.indicator}
                       inline label={props.figureStack.figures.length}
                       size={25}
                       color={props.figureStack.figures.length > 0 ? "#696969" : colors.ground}
                       radius="md"
                       processing>
                <Card
                    className={classes.card}
                    onClick={() => props.onStackClicked(props.figureStack)}>
                    <Card.Section>
                        <Image
                            className={classes.figurePicture}
                            src={"/strategoFigures/" + Rank[props.figureStack.rank].toLowerCase() + ".png"}
                            alt={Rank[props.figureStack.rank]}
                        />
                        {props.figureStack.figures.length > 0 && props.figureStack.rank !== Rank.FLAG &&
                            props.figureStack.rank !== Rank.BOMB && (
                                <div
                                    className={classes.figureRank}>{(parseInt(props.figureStack.rank.toString()) - 1).toString()}</div>
                            )}
                    </Card.Section>
                </Card>
            </Indicator>
            <div className={classes.figureName}>{Rank[props.figureStack.rank]}</div>
        </div>
    );
};

export default PlayerInventoryStack;


