/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import BaseGridNode, { BaseGridNodeProps, NodeState } from "./BaseGridNode";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../helper/DragAndDropConfiguration";

/**
 * Type that represents the props of the {@link DraggableGridNode} component.
 *
 * onFigureDropped: Callback that is invoked when a figure was dropped on the node.
 * data-cy: Cypress Testing Tag.
 */
type DraggableGridNodeProps = {
    onFigureDropped: () => void;
    "data-cy"?: string;
};

/**
 * Component that implements additional drag/drop functionality for the node.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const DraggableGridNode = (props: DraggableGridNodeProps & BaseGridNodeProps) => {

    const [{isOver}, drop] = useDrop(
        () => ({
            accept: ItemTypes.FIGURE_STACK,
            canDrop: () => props.onFigureDropped !== undefined,
            drop: () => props.onFigureDropped(),
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            })
        }),
        []
    );

    return (
        <div ref={drop} data-cy={props["data-cy"]}>
            <BaseGridNode
                text={props.text}
                state={isOver ? NodeState.HIGHLIGHTED : NodeState.DEFAULT}
                color={props.color}
                diameter={props.diameter}
                onClick={props.onClick}
                hoverable={true}/>
        </div>
    );
};

export default DraggableGridNode;