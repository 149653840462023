/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";
import { useMantineTheme } from "@mantine/core";
import { generateJoinURL } from "../../helper/Utils";

/**
 * Type that represents the props of the {@link LobbyQRCode} component.
 *
 * roomCode: Room code that should be embedded in the QR-Code.
 * width: Width of the QR-Code.
 * height: Height of the QR-Code.
 */
type LobbyQRCodeProps = {
    roomCode: string;
    width: number;
    height: number;
};

const qrCode = new QRCodeStyling({
    width: 150,
    height: 150,
    dotsOptions: {
        type: "rounded",
        gradient: {
            type: "linear",
            colorStops: [
                {
                    offset: 0,
                    color: "rgb(58, 104, 132)"
                },
                {
                    offset: 1.0,
                    color: "rgb(120, 173, 206)"
                }
            ]
        }
    },
    cornersSquareOptions: {
        type: undefined,
        color: "black"
    },
    cornersDotOptions: {
        type: undefined,
        color: "black"
    }
});

/**
 * Component that represents a QR-Code that can be used to join a lobby.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const LobbyQRCode = (props: LobbyQRCodeProps) => {

    const theme = useMantineTheme();
    const qrCodeRef = useRef(null);

    useEffect(() => {
        qrCode.append(qrCodeRef.current);
    }, []);

    useEffect(() => {
        qrCode.update({
            width: props.width,
            height: props.height,
            data: generateJoinURL(props.roomCode),
            backgroundOptions: {
                color: theme.colorScheme === "dark" ? "rgb(26, 27, 30)" : "rgb(255, 255, 255)",
            },
            cornersSquareOptions: {
                color: theme.colorScheme === "dark" ? "white" : "black",
            },
            cornersDotOptions: {
                color: theme.colorScheme === "dark" ? "white" : "black",
            },
            dotsOptions: {
                gradient: {
                    type: "linear",
                    colorStops: [
                        {
                            offset: 0,
                            color: theme.colors.blue[9]
                        },
                        {
                            offset: 1.0,
                            color: theme.colors.blue[5]
                        }
                    ],
                },
            },
        })
        // eslint-disable-next-line
    }, [theme]);

    return (
        <div ref={qrCodeRef}/>
    );
};

export default LobbyQRCode;