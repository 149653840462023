/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import { useState } from "react";
import axios from "axios";

export type AvailabilityCheckResult = "available" | "at_capacity" | "not_existing" | "no_connection" | "none";

/**
 * Type that represents the elements that are returned by the room availability check hook.
 *
 * result: Result of the last availability check.
 * error: Error of the last availability check.
 * pending: Determines whether the availability check is currently pending.
 * checkAvailability: Method that checks the availability of a specific room code.
 * clearStatus: Method that clears the last availability check status.
 */
type RoomAvailabilityCheckHookStructure = [
    result: AvailabilityCheckResult,
    error: string,
    pending: boolean,
    checkAvailability: (roomCode: string, refreshToken?: string) => void,
    clearStatus: () => void,
];

const ERROR_CONNECTIVITY = "Unable to connect to the server. Please try again later.";
const ERROR_AT_CAPACITY = "This room is already full";
const ERROR_NOT_EXISTING = "This room does not exist";

/**
 * Custom hook used to check the availability of a specific room.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const useRoomAvailabilityCheck = (): RoomAvailabilityCheckHookStructure => {

    const [result, setResult] = useState<AvailabilityCheckResult>("none");
    const [pending, setPending] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);

    /**
     * Method that checks the availability of a specific room.
     * This method invokes a request to the REST API endpoint used to check the availability.
     * When the result is returned, the state values are updated.
     *
     * @param roomCode Code of the room that should be verified.
     * @param refreshToken Optional refresh token that is taken into consideration.
     */
    const checkAvailability = (roomCode: string, refreshToken?: string) => {
        setPending(true);
        axios.post(`/api/v1/rooms/${roomCode}/available`, refreshToken, {
            headers: {
                "Content-Type": "text/plain",
            },
        })
            .then(result => {
                setPending(false);
                setResult(result.data);

                if (result.data === "at_capacity") {
                    setError(ERROR_AT_CAPACITY);
                } else if (result.data === "not_existing") {
                    setError(ERROR_NOT_EXISTING);
                } else {
                    setError(null);
                }
            })
            .catch(_ => {
                setPending(false);
                setResult("no_connection");
                setError(ERROR_CONNECTIVITY);
            });
    };

    /**
     * Method that clears the current result, error and pending status.
     */
    const clearStatus = () => {
        setResult("none");
        setError(null);
        setPending(false);
    };

    return [
        result,
        error,
        pending,
        checkAvailability,
        clearStatus
    ];
};

export default useRoomAvailabilityCheck;