/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {Box, createStyles, Title, useMantineTheme} from "@mantine/core";
import CircleProgress from "../../components/loaders/CircleProgress";
import {ReconnectionMaxAttempts} from "../../hooks/rooms/useRoomConnection";
import SlideAnimation, {SlideDirection} from "../../components/animation/SlideAnimation";

/**
 * Type that represents the props of the {@link HostReconnectingView}.
 *
 * - attempt: Current reconnection attempt.
 */
type HostReconnectingViewProps = {
    attempt: number;
};

const useStyles = createStyles({
    reconnectingContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
    },
    loaderContainer: {
        display: "flex",
        width: "100%",
        margin: "3rem",
        alignItems: "center",
        justifyContent: "center",
    }
});

/**
 * Component that is displayed when the game is attempting to reconnect to the server.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const HostReconnectingView = (props: HostReconnectingViewProps) => {

    const {classes} = useStyles();
    const theme = useMantineTheme();

    /**
     * Method that resolves the color from the current reconnection attempt.
     * The color is used to display the attempt to the user.
     *
     * @return Returns the resolved color.
     */
    const _colorFromAttempt = () => {
        if (props.attempt === 0) {
            return {
                color: theme.colors.blue[9],
                shadowColor: theme.colors.blue[5] + "80",
            };
        } else if (props.attempt === 1) {
            return {
                color: theme.colors.orange[9],
                shadowColor: theme.colors.orange[5] + "80",
            };
        } else if (props.attempt === 2) {
            return {
                color: theme.colors.red[9],
                shadowColor: theme.colors.red[5] + "80",
            };
        }
    };

    return (
        <Box className={classes.reconnectingContainer}>
            <SlideAnimation
                className={classes.loaderContainer}
                direction={SlideDirection.TOP}>
                <CircleProgress {..._colorFromAttempt()}/>
            </SlideAnimation>
            <SlideAnimation direction={SlideDirection.BOTTOM}>
                <Title order={3}>
                    Aw snap!
                </Title>
            </SlideAnimation>
            <SlideAnimation direction={SlideDirection.BOTTOM}>
                <Title order={4}>
                    Something went wrong. We're trying to get you back into the game.
                </Title>
            </SlideAnimation>
            <SlideAnimation direction={SlideDirection.BOTTOM}>
                <Title order={4}>
                    Attempts Remaining: {ReconnectionMaxAttempts - props.attempt}
                </Title>
            </SlideAnimation>
        </Box>
    );
};

export default HostReconnectingView;