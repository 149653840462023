/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useState} from "react";
import {createStyles, Title} from "@mantine/core";
import SlideAnimation, {SlideDirection} from "./animation/SlideAnimation";

const useStyles = createStyles({
    titleContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
    },
    title: {
        fontSize: "6rem",
        display: "flex",
        flexDirection: "row",
    },
    logo: {
        height: "5.0rem",
        marginRight: "0.1rem",
    }
});

/**
 * Component that displays the title of the game.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const GameTitle = () => {

    const {classes} = useStyles();

    const [alternativeEnabled, setAlternativeEnabled] = useState<boolean>(false);

    const title = alternativeEnabled ? "temgs Gatatooo" : "tratego";

    return (
        <div className={classes.titleContainer}>
            <img
                className={classes.logo}
                alt="Stratego Logo"
                src="stratego_logo_secondary.png"/>
            <Title
                className={classes.title}
                onClick={() => setAlternativeEnabled(!alternativeEnabled)}>
                {title.split("").map((character, index) => (
                    <SlideAnimation
                        style={{marginLeft: character === " " ? "1rem" : ""}}
                        direction={SlideDirection.BOTTOM}
                        delay={index * 0.1}
                        translation={130}>
                        {character}
                    </SlideAnimation>
                ))}
            </Title>
        </div>
    );
};

export default GameTitle;