/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { Button, createStyles, MantineGradient } from "@mantine/core";
import "./BoardProgress.css";
import { calculateAnimationDelay } from "../EntryAnimation";

/**
 * Type that represents the props of the {@link BoardProgress} component.
 *
 * width: Width of the cell matrix.
 * height: Height of the cell matrix.
 * dimensionUnit: Unit the diameter of the cells is measured in.
 * diameter: Diameter of a single cell.
 * gradient: Gradient of a single cell.
 */
type BoardProgressProps = {
    width: number;
    height: number;
    dimensionUnit: string;
    diameter?: number;
    gradient?: MantineGradient;
};

/**
 * Type that represents the props that are passed to the styles hook.
 *
 * cellSize: Diameter of a single cell.
 */
type BoardProgressStyleProps = {
    cellSize: string;
}

const useStyles = createStyles((theme, {cellSize}: BoardProgressStyleProps) => ({
    rowContainer: {
        height: cellSize,
        margin: "0.1rem"
    }
}));

/**
 * Component that represents a loading animation of the application.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const BoardProgress = (props: BoardProgressProps) => {

    const DIAMETER = props.diameter ?? 3;
    const CELL_SIZE = `${props.width / DIAMETER}${props.dimensionUnit}`;
    const DEFAULT_DELAY = 100;

    const {classes} = useStyles({
        cellSize: CELL_SIZE
    });

    return (
        <div>
            {[...Array(DIAMETER)].map((_, y) => (
                <div className={classes.rowContainer}>
                    {[...Array(DIAMETER)].map((_, x) => (
                        <Button
                            key={`${x},${y}`}
                            className="cell"
                            variant="gradient"
                            style={{
                                width: CELL_SIZE,
                                height: CELL_SIZE,
                                padding: 0,
                                transform: "scale(1)",
                                animation: "pulse 1s infinite",
                                animationDelay: calculateAnimationDelay(x, y, DIAMETER, DEFAULT_DELAY),
                                margin: "0.1rem",
                            }}
                            gradient={props.gradient ?? {
                                from: "indigo",
                                to: "cyan"
                            }}/>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default BoardProgress;