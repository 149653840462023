/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {createStyles} from "@mantine/core";
import {BaseStackDto} from "../../model/proto/dto";
import PlayerInventoryStack from "./PlayerInventoryStack";
import SlideAnimation, {SlideDirection} from "../../components/animation/SlideAnimation";

/**
 * Type that represents the props of the {@link PlayerInventory} component.
 *
 * playerColor: Color of the local player.
 * figureStacks: Stacks that should be displayed inside the inventory.
 * selectedStack: Currently selected stack of the inventory.
 * setSelectedStack: Callback that is invoked to update the currently selected stack of the inventory.
 */
type PlayerInventoryProps = {
    playerColor: string;
    figureStacks: BaseStackDto[];
    selectedStack: BaseStackDto;
    setSelectedStack: (stack: BaseStackDto) => void;
};

const useStyles = createStyles((theme) => ({
    root: {
        display: "inline-flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-around",
        flexWrap: "wrap",
    },
}));

/**
 * Component for the player inventory.
 * It renders the inventory out of {@link PlayerInventoryStack}s with the given {@link PlayerInventoryStackDto} data.
 *
 * @param props - {@link PlayerInventoryProps}
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const PlayerInventory = (props: PlayerInventoryProps) => {

    const {classes} = useStyles();

    return (
        <div className={classes.root} data-cy="PlayerInventory">
            {
                props.figureStacks.sort((n1, n2) => n1.rank - n2.rank).map((figureStack: BaseStackDto, index) => (
                    <SlideAnimation
                        direction={SlideDirection.BOTTOM}
                        speedFactor={1.0}
                        delay={index * 0.04}>
                        <PlayerInventoryStack
                            key={figureStack.rank}
                            onDragStart={() => props.setSelectedStack(figureStack)}
                            playerColor={props.playerColor}
                            selected={props.selectedStack !== null && props.selectedStack.rank === figureStack.rank}
                            figureStack={figureStack}
                            onStackClicked={props.setSelectedStack}/>
                    </SlideAnimation>
                ))
            }
        </div>
    )
};

export default PlayerInventory;