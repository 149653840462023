/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useContext, useState} from "react";
import {ActionIcon, Box, Card, createStyles, Popover, Text} from "@mantine/core";
import {PlayerDto, PlayerState} from "../../model/proto/dto";
import {RoomConnectionContext} from "../../hooks/rooms/useRoomConnection";
import LobbyUsernameEditor from "./LobbyUsernameEditor";
import {generateRequestID} from "../../helper/Utils";
import {Edit} from "@styled-icons/fluentui-system-filled/Edit";
import {CheckCircleFill} from "@styled-icons/bootstrap/CheckCircleFill";
import {XCircle} from "@styled-icons/bootstrap/XCircle";
import {AnimatePresence, LayoutGroup} from "framer-motion";
import SlideAnimation, {SlideDirection} from "../../components/animation/SlideAnimation";

/**
 * Type that represents the props of the {@link LobbyPlayerListItemAccountSummary}.
 *
 * player: {@link PlayerDto} that is represented by the component.
 */
type LobbyPlayerInformationProps = {
    player: PlayerDto
};

const useStyles = createStyles({
    playerInformationBox: {
        height: '5.2rem',
        width: '20rem',
        margin: '0.3rem'
    },
    playerInformationText: {
        marginBottom: '0.4rem'
    },
    editIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        margin: "0.6rem",
    }
});

/**
 * Component that represents the player with name.
 * This component is nested in the {@link LobbyPlayerListItemAccountSummary}.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const LobbyPlayerListItemAccountSummary = (props: LobbyPlayerInformationProps) => {

    const {classes} = useStyles();

    const connection = useContext(RoomConnectionContext);

    const isCurrentPlayer = connection.applicationState?.localPlayer?.uuid === props.player?.uuid;

    const [usernameEditorOpen, setUsernameEditorOpen] = useState<boolean>(false);

    return (
        <Popover width={320} opened={usernameEditorOpen}>
            <Popover.Target>
                <Card
                    className={classes.playerInformationBox}
                    radius="md"
                    shadow={"sm"}
                    withBorder>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <Text
                                size="sm"
                                fw={700}
                                className={classes.playerInformationText}
                                data-cy="UserAccountBox">
                                {props.player?.name ?? "Stranger"}
                            </Text>
                            {isCurrentPlayer && (
                                <Text
                                    sx={{marginLeft: "0.3rem"}}
                                    size="sm"
                                    fw={900}
                                    color="blue">
                                    (You)
                                </Text>
                            )}
                            {isCurrentPlayer && (
                                <ActionIcon
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        margin: "0.3rem"
                                    }}
                                    variant="outline"
                                    color="blue"
                                    data-cy="UsernameEditorButton"
                                    onClick={() => setUsernameEditorOpen(true)}>
                                    <Edit width={20} height={20}/>
                                </ActionIcon>
                            )}
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}>
                            <LayoutGroup>
                                <AnimatePresence>
                                    <SlideAnimation layout direction={SlideDirection.RIGHT}>
                                        {props.player?.playerState === PlayerState.READY ? (
                                            <CheckCircleFill
                                                size={18}
                                                color="green"/>
                                        ) : (
                                            <XCircle
                                                size={18}
                                                color="red"/>
                                        )}
                                    </SlideAnimation>
                                    <SlideAnimation layout direction={SlideDirection.RIGHT}>
                                        <Text
                                            data-cy="PlayerItemStatusField"
                                            weight={500}
                                            sx={{marginLeft: "0.4rem"}}
                                            color={props.player?.playerState === PlayerState.READY ? "green" : "red"}>
                                            {props.player?.playerState === PlayerState.READY ? "Ready" : "Not Ready"}
                                        </Text>
                                    </SlideAnimation>
                                </AnimatePresence>
                            </LayoutGroup>
                        </Box>
                    </Box>
                </Card>
            </Popover.Target>
            <Popover.Dropdown>
                <LobbyUsernameEditor
                    username={props.player?.name ?? "Stranger"}
                    onDismiss={() => setUsernameEditorOpen(false)}
                    onSubmit={updatedUsername => {
                        const updateRequestID = generateRequestID("lobby_name_update");
                        connection.sendRequestWithoutCallback(
                            {
                                accessToken: "",
                                requestId: updateRequestID,
                                configurationRequest: {
                                    name: updatedUsername,
                                    color: null
                                }
                            },
                        );
                    }}/>
            </Popover.Dropdown>
        </Popover>
    );
};

export default LobbyPlayerListItemAccountSummary;