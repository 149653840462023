/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {PlayerDto} from "../../model/proto/dto";
import {Box, Card, createStyles, Image, Title} from "@mantine/core";
import {Trophy} from "@styled-icons/fa-solid/Trophy"

/**
 * Type that represents the props of the {@link PlayerResultCardProps}.
 *
 * player: Player that is represented by the card.
 * state: State of the card.
 * draw: Determines whether There currently is a draw.
 */
type PlayerResultCardProps = {
    player: PlayerDto | null;
    state: PlayerResultCardState;
    draw?: boolean;
};

export enum PlayerResultCardState {
    DEFAULT,
    HIGHLIGHTED,
    WINNER,
    LOSER
}

const useStyles = createStyles((theme, props: PlayerResultCardProps) => ({
    container: {
        width: (props.state === PlayerResultCardState.WINNER && !props.draw) ? "100%" : "85%",
        maxWidth: "11vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        transition: "width 0.7s",
    },
    cardContainer: {
        borderRadius: "1rem",
        borderWidth: ((props.state === PlayerResultCardState.WINNER ||
            props.state === PlayerResultCardState.HIGHLIGHTED) && !props.draw) ? "0.3rem" : "0",
        borderStyle: "solid",
        borderColor: "gold",
        background: props?.player?.color,
        padding: "0.4rem",
    },
    imageContainer: {
        borderRadius: "0.45rem",
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "10rem",
    },
    playerContainer: {
        padding: "1rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    trophy: {
        marginLeft: "1rem",
    }
}));

/**
 * Component that represents a player in the game.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const PlayerCard = (props: PlayerResultCardProps) => {

    const {classes} = useStyles(props);

    const splitAndInsertSpace = (input: string): string => {
        const words = input.split(' ');

        const processedWords = words.map((word) => {
            if (word.length > 9) {
                const middleIndex = Math.floor(word.length / 2);
                return `${word.slice(0, middleIndex)} ${word.slice(middleIndex)}`;
            }
            return word;
        });

        return processedWords.join(' ');
    };


    return (
        <Box className={classes.container}>
            <Box className={classes.cardContainer}>
                <Card className={classes.imageContainer}>
                    <Card.Section>
                        <Image
                            src="https://images.unsplash.com/photo-1628563694622-5a76957fd09c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1287&q=80"
                            alt="Profile Picture"
                            className={classes.image}/>
                    </Card.Section>
                </Card>
            </Box>
            <Box className={classes.playerContainer}>
                <Title
                    order={3}>
                    {splitAndInsertSpace(props?.player?.name.toString())}
                </Title>
                {props.state === PlayerResultCardState.WINNER && !props.draw && (
                    <Trophy
                        color="gold"
                        className={classes.trophy} size={25}/>
                )}
            </Box>
        </Box>
    );
};

export default PlayerCard;