/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {createStyles} from "@mantine/core";
import LobbyAccountBox from "./LobbyPlayerListItemProfilePicture";
import LobbyPlayerAccount from "./LobbyPlayerListItemAccountSummary";
import {PlayerDto} from "../../model/proto/dto";
import SlideAnimation, {SlideDirection} from "../../components/animation/SlideAnimation";

/**
 * Type that represents the props of the {@link LobbyPlayerListItem} component.
 */
type LobbyPlayerBoxProps = {
    player: PlayerDto,
};

const useStyles = createStyles({
    playerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }
});

/**
 * Component that represents a player with state, name and avatar.
 * This component consists of {@link LobbyAccountBox} and {@link LobbyPlayerAccount} instances.
 * This component is nested in the {@link LobbyPlayerList}.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const LobbyPlayerListItem = (props: LobbyPlayerBoxProps) => {

    const {classes} = useStyles();

    return (
        <SlideAnimation
            layout
            direction={SlideDirection.RIGHT}
            className={classes.playerBox}>
            <LobbyAccountBox imageSrc="PortraitMuster.png"/>
            <LobbyPlayerAccount player={props.player}/>
        </SlideAnimation>
    );
};

export default LobbyPlayerListItem;
