/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {Box, createStyles, Text} from "@mantine/core";
import {Rank} from "../model/proto/dto";
import BaseGridNode from "./grid/BaseGridNode";
import {capitalizeFirstLetter} from "./FigureExplanationPanel";

/**
 * Type that represents the props of the {@link FigureExplanationListItem}.
 *
 * onClick: function used to return the clicked rank via the handleClick function
 * playerColor: color of the player
 * rank: showed Rank
 */
type FigureExplanationListItemProps = {
    onClick?: (rank: Rank) => void;
    playerColor: string;
    rank: Rank;
};

const useStyles = createStyles({
    itemBox: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: "3rem"
    }
});

/**
 * Item contains the name and the node of one specific {@link Rank}.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const FigureExplanationListItem = (props: FigureExplanationListItemProps) => {

    const {classes} = useStyles();

    /**
     * Callback method that is invoked when the item was clicked.
     */
    const _handleClick = () => {
        if (props.onClick)
            props.onClick(props.rank);
    };

    return (
        <Box
            onClick={_handleClick}
            className={classes.itemBox}>
            <BaseGridNode
                color={props.playerColor}
                text={Rank[props.rank].toString()}
                diameter={4}/>
            <Text sx={{marginLeft: '0.5rem'}}>
                {capitalizeFirstLetter(props.rank.toString().toLowerCase())}
            </Text>
        </Box>
    );
};

export default FigureExplanationListItem;