/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import {notifications} from "@mantine/notifications";
import {PlayerDto, PositionDto} from "../model/proto/dto";
import jwt_decode from "jwt-decode";

/**
 * Function that generates the join URL for a specific room.
 *
 * @param roomCode Code of the room the URL should be generated for.
 */
export const generateJoinURL = (roomCode: string): string => {
    return `${window.location.origin}/join?code=${roomCode}`;
};

/**
 * Function that generates a new request ID.
 *
 * @param prefix Prefix of the generated ID.
 */
export const generateRequestID = (prefix: string): string => {
    return `${prefix}${new Date().getTime()}`;
};

/**
 * Function that copies a given content to the clipboard.
 * This function also displays an error message in case the process fails.
 *
 * @param content Content that should be copied to the clipboard.
 */
export const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content).catch(() => {
        notifications.show({
            title: "Error",
            message: "Unable to copy contents to clipboard",
            withBorder: true,
            autoClose: true,
            color: "red"
        });
    });
};

/**
 * Function that returns the player object from a given player list.
 * The player object is identified by the given UUID.
 *
 * @param uuid ID of the player that should be obtained.
 * @param playerList List of available players.
 *
 * @return Returns the obtained player instance.
 */
export const getPlayerFromUUID = (uuid: string, playerList: PlayerDto[]): PlayerDto => {
    return playerList.find(player => player.uuid === uuid);
}

/**
 * Function that compares two given {@link PositionDto} instances.
 *
 * @param a First {@link PositionDto} that should be compared.
 * @param b Second {@link PositionDto} that should be compared.
 */
export const coordinateEquals = (a: PositionDto, b: PositionDto): boolean => {
    return a.positionX === b.positionX && a.positionY === b.positionY;
};

/**
 * Function that returns the rank name for a given rank number.
 *
 * @param rankNumber The rank number.
 */
export const rankNumberToRankName = (rankNumber: number): string => {
    switch (rankNumber) {
        case 0:
            return "Unknown";
        case 1:
            return "Flag";
        case 2:
            return "Spy";
        case 3:
            return "Scout";
        case 4:
            return "Miner";
        case 5:
            return "Sergeant";
        case 6:
            return "Lieutenant";
        case 7:
            return "Captain";
        case 8:
            return "Major";
        case 9:
            return "Colonel";
        case 10:
            return "General";
        case 11:
            return "Marshal";
        case 12:
            return "Bomb";
        default:
            return "Unknown";
    }
}

/**
 * Function that returns the maximum quantity of a rank.
 *
 * @param rankNumber The rank number.
 */
export const maxQuantityOfRank = (rankNumber: number): number => {
    switch (rankNumber) {
        case 0:
            return null;
        case 1:
            return 1;
        case 2:
            return 1;
        case 3:
            return 8;
        case 4:
            return 5;
        case 5:
            return 4;
        case 6:
            return 4;
        case 7:
            return 4;
        case 8:
            return 3;
        case 9:
            return 2;
        case 10:
            return 1;
        case 11:
            return 1;
        case 12:
            return 6;
        default:
            return 0;
    }
}

/**
 * Function that extracts the room code from a given JWT refresh token.
 *
 * @param token Token the room code should be extracted from.
 *
 * @returns Returns the room code if present in the token.
 */
export const retrieveRoomCodeFromToken = (token: string): string | null => {
    try {
        const decoded = jwt_decode(token);
        // @ts-ignore
        return decoded.room;
    } catch {
        return null;
    }
}