// @ts-nocheck
// @generated by protobuf-ts 2.9.0
// @generated from protobuf file "dto.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message PositionDto
 */
export interface PositionDto {
    /**
     * @generated from protobuf field: int32 position_x = 1;
     */
    positionX: number;
    /**
     * @generated from protobuf field: int32 position_y = 2;
     */
    positionY: number;
}
/**
 * @generated from protobuf message PlayerDto
 */
export interface PlayerDto {
    /**
     * @generated from protobuf field: string uuid = 1;
     */
    uuid: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string color = 3;
     */
    color: string;
    /**
     * @generated from protobuf field: PlayerState player_state = 4;
     */
    playerState: PlayerState;
    /**
     * @generated from protobuf field: BaseStackContainerDto graveyard = 5;
     */
    graveyard?: BaseStackContainerDto;
    /**
     * @generated from protobuf field: DrawState draw_state = 6;
     */
    drawState: DrawState;
    /**
     * @generated from protobuf field: bool connected = 7;
     */
    connected: boolean;
}
/**
 * @generated from protobuf message BaseStackContainerDto
 */
export interface BaseStackContainerDto {
    /**
     * @generated from protobuf field: repeated BaseStackDto stacks = 1;
     */
    stacks: BaseStackDto[];
}
/**
 * @generated from protobuf message BaseStackDto
 */
export interface BaseStackDto {
    /**
     * @generated from protobuf field: Rank rank = 1;
     */
    rank: Rank;
    /**
     * @generated from protobuf field: repeated FigureDto figures = 2;
     */
    figures: FigureDto[];
}
/**
 * @generated from protobuf message FigureDto
 */
export interface FigureDto {
    /**
     * @generated from protobuf field: Rank rank = 1;
     */
    rank: Rank;
    /**
     * @generated from protobuf field: string player_id = 2;
     */
    playerId: string;
    /**
     * @generated from protobuf field: bool alive = 3;
     */
    alive: boolean;
}
/**
 * @generated from protobuf message NodeDto
 */
export interface NodeDto {
    /**
     * @generated from protobuf field: bool traversable = 1;
     */
    traversable: boolean;
    /**
     * @generated from protobuf field: FigureDto figure = 2;
     */
    figure?: FigureDto;
    /**
     * @generated from protobuf field: PositionDto coordinate = 3;
     */
    coordinate?: PositionDto;
}
/**
 * @generated from protobuf message RowDto
 */
export interface RowDto {
    /**
     * @generated from protobuf field: repeated NodeDto nodes = 1;
     */
    nodes: NodeDto[];
}
/**
 * @generated from protobuf message BoardDto
 */
export interface BoardDto {
    /**
     * @generated from protobuf field: repeated RowDto rows = 1;
     */
    rows: RowDto[];
}
/**
 * @generated from protobuf enum PlayerState
 */
export enum PlayerState {
    /**
     * @generated from protobuf enum value: NOT_READY = 0;
     */
    NOT_READY = 0,
    /**
     * @generated from protobuf enum value: READY = 1;
     */
    READY = 1
}
/**
 * @generated from protobuf enum DrawState
 */
export enum DrawState {
    /**
     * @generated from protobuf enum value: DRAW_STATE_REQUESTED = 0;
     */
    REQUESTED = 0,
    /**
     * @generated from protobuf enum value: DRAW_STATE_ACCEPTED = 1;
     */
    ACCEPTED = 1,
    /**
     * @generated from protobuf enum value: DRAW_STATE_NONE = 2;
     */
    NONE = 2
}
/**
 * @generated from protobuf enum MoveErrorEnum
 */
export enum MoveErrorEnum {
    /**
     * @generated from protobuf enum value: NODE_NOT_TRAVERSABLE = 0;
     */
    NODE_NOT_TRAVERSABLE = 0,
    /**
     * @generated from protobuf enum value: NODE_NOT_REACHABLE = 1;
     */
    NODE_NOT_REACHABLE = 1,
    /**
     * @generated from protobuf enum value: NODE_CONTAINS_OWN_FIGURE = 2;
     */
    NODE_CONTAINS_OWN_FIGURE = 2,
    /**
     * @generated from protobuf enum value: NO_ERROR = 3;
     */
    NO_ERROR = 3,
    /**
     * @generated from protobuf enum value: NODE_NOT_SELECTABLE = 4;
     */
    NODE_NOT_SELECTABLE = 4,
    /**
     * @generated from protobuf enum value: MULTIPLE_FIELD_RULE = 5;
     */
    MULTIPLE_FIELD_RULE = 5,
    /**
     * @generated from protobuf enum value: TWO_FIELD_RULE = 6;
     */
    TWO_FIELD_RULE = 6
}
/**
 * @generated from protobuf enum Rank
 */
export enum Rank {
    /**
     * @generated from protobuf enum value: RANK_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: RANK_FLAG = 1;
     */
    FLAG = 1,
    /**
     * @generated from protobuf enum value: RANK_SPY = 2;
     */
    SPY = 2,
    /**
     * @generated from protobuf enum value: RANK_SCOUT = 3;
     */
    SCOUT = 3,
    /**
     * @generated from protobuf enum value: RANK_MINER = 4;
     */
    MINER = 4,
    /**
     * @generated from protobuf enum value: RANK_SERGEANT = 5;
     */
    SERGEANT = 5,
    /**
     * @generated from protobuf enum value: RANK_LIEUTENANT = 6;
     */
    LIEUTENANT = 6,
    /**
     * @generated from protobuf enum value: RANK_CAPTAIN = 7;
     */
    CAPTAIN = 7,
    /**
     * @generated from protobuf enum value: RANK_MAJOR = 8;
     */
    MAJOR = 8,
    /**
     * @generated from protobuf enum value: RANK_COLONEL = 9;
     */
    COLONEL = 9,
    /**
     * @generated from protobuf enum value: RANK_GENERAL = 10;
     */
    GENERAL = 10,
    /**
     * @generated from protobuf enum value: RANK_MARSHAL = 11;
     */
    MARSHAL = 11,
    /**
     * @generated from protobuf enum value: RANK_BOMB = 12;
     */
    BOMB = 12
}
// @generated message type with reflection information, may provide speed optimized methods
class PositionDto$Type extends MessageType<PositionDto> {
    constructor() {
        super("PositionDto", [
            { no: 1, name: "position_x", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "position_y", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PositionDto>): PositionDto {
        const message = { positionX: 0, positionY: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PositionDto>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PositionDto): PositionDto {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 position_x */ 1:
                    message.positionX = reader.int32();
                    break;
                case /* int32 position_y */ 2:
                    message.positionY = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PositionDto, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 position_x = 1; */
        if (message.positionX !== 0)
            writer.tag(1, WireType.Varint).int32(message.positionX);
        /* int32 position_y = 2; */
        if (message.positionY !== 0)
            writer.tag(2, WireType.Varint).int32(message.positionY);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PositionDto
 */
export const PositionDto = new PositionDto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlayerDto$Type extends MessageType<PlayerDto> {
    constructor() {
        super("PlayerDto", [
            { no: 1, name: "uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "player_state", kind: "enum", T: () => ["PlayerState", PlayerState] },
            { no: 5, name: "graveyard", kind: "message", T: () => BaseStackContainerDto },
            { no: 6, name: "draw_state", kind: "enum", T: () => ["DrawState", DrawState, "DRAW_STATE_"] },
            { no: 7, name: "connected", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PlayerDto>): PlayerDto {
        const message = { uuid: "", name: "", color: "", playerState: 0, drawState: 0, connected: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlayerDto>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlayerDto): PlayerDto {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uuid */ 1:
                    message.uuid = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string color */ 3:
                    message.color = reader.string();
                    break;
                case /* PlayerState player_state */ 4:
                    message.playerState = reader.int32();
                    break;
                case /* BaseStackContainerDto graveyard */ 5:
                    message.graveyard = BaseStackContainerDto.internalBinaryRead(reader, reader.uint32(), options, message.graveyard);
                    break;
                case /* DrawState draw_state */ 6:
                    message.drawState = reader.int32();
                    break;
                case /* bool connected */ 7:
                    message.connected = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlayerDto, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uuid = 1; */
        if (message.uuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uuid);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string color = 3; */
        if (message.color !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.color);
        /* PlayerState player_state = 4; */
        if (message.playerState !== 0)
            writer.tag(4, WireType.Varint).int32(message.playerState);
        /* BaseStackContainerDto graveyard = 5; */
        if (message.graveyard)
            BaseStackContainerDto.internalBinaryWrite(message.graveyard, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* DrawState draw_state = 6; */
        if (message.drawState !== 0)
            writer.tag(6, WireType.Varint).int32(message.drawState);
        /* bool connected = 7; */
        if (message.connected !== false)
            writer.tag(7, WireType.Varint).bool(message.connected);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PlayerDto
 */
export const PlayerDto = new PlayerDto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BaseStackContainerDto$Type extends MessageType<BaseStackContainerDto> {
    constructor() {
        super("BaseStackContainerDto", [
            { no: 1, name: "stacks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BaseStackDto }
        ]);
    }
    create(value?: PartialMessage<BaseStackContainerDto>): BaseStackContainerDto {
        const message = { stacks: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BaseStackContainerDto>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BaseStackContainerDto): BaseStackContainerDto {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BaseStackDto stacks */ 1:
                    message.stacks.push(BaseStackDto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BaseStackContainerDto, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BaseStackDto stacks = 1; */
        for (let i = 0; i < message.stacks.length; i++)
            BaseStackDto.internalBinaryWrite(message.stacks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BaseStackContainerDto
 */
export const BaseStackContainerDto = new BaseStackContainerDto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BaseStackDto$Type extends MessageType<BaseStackDto> {
    constructor() {
        super("BaseStackDto", [
            { no: 1, name: "rank", kind: "enum", T: () => ["Rank", Rank, "RANK_"] },
            { no: 2, name: "figures", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FigureDto }
        ]);
    }
    create(value?: PartialMessage<BaseStackDto>): BaseStackDto {
        const message = { rank: 0, figures: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BaseStackDto>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BaseStackDto): BaseStackDto {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Rank rank */ 1:
                    message.rank = reader.int32();
                    break;
                case /* repeated FigureDto figures */ 2:
                    message.figures.push(FigureDto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BaseStackDto, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Rank rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).int32(message.rank);
        /* repeated FigureDto figures = 2; */
        for (let i = 0; i < message.figures.length; i++)
            FigureDto.internalBinaryWrite(message.figures[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BaseStackDto
 */
export const BaseStackDto = new BaseStackDto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FigureDto$Type extends MessageType<FigureDto> {
    constructor() {
        super("FigureDto", [
            { no: 1, name: "rank", kind: "enum", T: () => ["Rank", Rank, "RANK_"] },
            { no: 2, name: "player_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "alive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FigureDto>): FigureDto {
        const message = { rank: 0, playerId: "", alive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FigureDto>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FigureDto): FigureDto {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Rank rank */ 1:
                    message.rank = reader.int32();
                    break;
                case /* string player_id */ 2:
                    message.playerId = reader.string();
                    break;
                case /* bool alive */ 3:
                    message.alive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FigureDto, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Rank rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).int32(message.rank);
        /* string player_id = 2; */
        if (message.playerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.playerId);
        /* bool alive = 3; */
        if (message.alive !== false)
            writer.tag(3, WireType.Varint).bool(message.alive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FigureDto
 */
export const FigureDto = new FigureDto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NodeDto$Type extends MessageType<NodeDto> {
    constructor() {
        super("NodeDto", [
            { no: 1, name: "traversable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "figure", kind: "message", T: () => FigureDto },
            { no: 3, name: "coordinate", kind: "message", T: () => PositionDto }
        ]);
    }
    create(value?: PartialMessage<NodeDto>): NodeDto {
        const message = { traversable: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NodeDto>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NodeDto): NodeDto {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool traversable */ 1:
                    message.traversable = reader.bool();
                    break;
                case /* FigureDto figure */ 2:
                    message.figure = FigureDto.internalBinaryRead(reader, reader.uint32(), options, message.figure);
                    break;
                case /* PositionDto coordinate */ 3:
                    message.coordinate = PositionDto.internalBinaryRead(reader, reader.uint32(), options, message.coordinate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NodeDto, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool traversable = 1; */
        if (message.traversable !== false)
            writer.tag(1, WireType.Varint).bool(message.traversable);
        /* FigureDto figure = 2; */
        if (message.figure)
            FigureDto.internalBinaryWrite(message.figure, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* PositionDto coordinate = 3; */
        if (message.coordinate)
            PositionDto.internalBinaryWrite(message.coordinate, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NodeDto
 */
export const NodeDto = new NodeDto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RowDto$Type extends MessageType<RowDto> {
    constructor() {
        super("RowDto", [
            { no: 1, name: "nodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NodeDto }
        ]);
    }
    create(value?: PartialMessage<RowDto>): RowDto {
        const message = { nodes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RowDto>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RowDto): RowDto {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated NodeDto nodes */ 1:
                    message.nodes.push(NodeDto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RowDto, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated NodeDto nodes = 1; */
        for (let i = 0; i < message.nodes.length; i++)
            NodeDto.internalBinaryWrite(message.nodes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RowDto
 */
export const RowDto = new RowDto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BoardDto$Type extends MessageType<BoardDto> {
    constructor() {
        super("BoardDto", [
            { no: 1, name: "rows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RowDto }
        ]);
    }
    create(value?: PartialMessage<BoardDto>): BoardDto {
        const message = { rows: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BoardDto>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BoardDto): BoardDto {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated RowDto rows */ 1:
                    message.rows.push(RowDto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BoardDto, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated RowDto rows = 1; */
        for (let i = 0; i < message.rows.length; i++)
            RowDto.internalBinaryWrite(message.rows[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BoardDto
 */
export const BoardDto = new BoardDto$Type();
