/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {Button, createStyles, useMantineTheme} from "@mantine/core";

/**
 * Type that represents the props of the {@link TextButton} component.
 *
 * children: Optional child components of the component.
 * disabled: Optional prop that determines whether the button currently is disabled.
 * onClick: Optional callback that is invoked when the button is clicked.
 * rightIcon: Optional right icon of the button.
 * data-cy: Cypress Test Tag.
 */
type TextButtonProps = {
    children?: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    rightIcon?: React.ReactNode;
    "data-cy"?: string;
};

const useStyles = createStyles({
    linkContainer: {
        paddingLeft: "0.3rem",
        paddingRight: "0.3rem"
    }
});

/**
 * Component that represents a clickable text along with an icon.
 *
 * @param props Props of the component.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const TextButton = (props: TextButtonProps) => {

    const theme = useMantineTheme();
    const {classes} = useStyles();

    return (
        <Button
            data-cy={props["data-cy"]}
            className={classes.linkContainer}
            style={{color: theme.colors.blue[9]}}
            variant="subtle"
            disabled={props.disabled}
            onClick={props.onClick}
            rightIcon={props.rightIcon}>
            {props.children}
        </Button>
    );
};

export default TextButton;