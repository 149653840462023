/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import React from "react";
import { BoardDto, NodeDto, PlayerDto, RowDto } from "../../model/proto/dto";
import { getPlayerFromUUID } from "../../helper/Utils";
import { colors } from "../../helper/ColorPalette";
import { createStyles } from "@mantine/core";
import { DIAMETER_DEFAULT } from "./BaseGridNode";
import DraggableGridNode from "./DraggableGridNode";
import BaseGridBorderNode from "./BaseGridBorderNode";
import PositionedSlideAnimation from "../animation/PositionedSlideAnimation";

/**
 * Type that represents the props of the {@link PositioningGrid}.
 *
 * posGridData: Board data that is loaded.
 * playerList: List of players associated with the board.
 * onNodeClicked: Callback that is invoked when a node on the positioning board has been clicked.
 * onNodeDropped: Callback that is invoked when a figure has been dropped on a node on the positioning board.
 */
type PositioningGridProps = {
    posGridData: BoardDto;
    playerList: PlayerDto[];
    onNodeClicked: (x: number, y: number, node: NodeDto) => void;
    onNodeDropped: (x: number, y: number, node: NodeDto) => void;
}

const useStyles = createStyles({
    grid: {
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
        flexDirection: "row",
    }
});

/**
 * Component for the positioning grid.
 * It renders the grid out of {@link DraggableGridNode}s with the given {@link BoardDto} data.
 *
 * @param props - {@link PositioningGridProps}
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const PositioningGrid = (props: PositioningGridProps) => {

    const {classes} = useStyles();

    const isLakeNode = (position: number) => [2, 3, 6, 7].includes(position);
    
    return (
        <div className={classes.grid} data-cy="PositioningGrid">
            <div className={classes.row}>
                {props.posGridData.rows[0].nodes.map((_, index) => (
                    <PositionedSlideAnimation
                        offsetX={index}
                        offsetY={0}
                        diameter={props.posGridData.rows[0].nodes.length}>
                        <BaseGridBorderNode
                            key={`border-node-${index}`}
                            diameter={DIAMETER_DEFAULT}
                            color={isLakeNode(index) ? colors.lake : colors.ground}
                            semiTransparentColor={isLakeNode(index) ? colors.semiTransparentLake : colors.semiTransparentGround}/>
                    </PositionedSlideAnimation>
                ))}
            </div>
            {props.posGridData.rows.map((row: RowDto, rowIndex: number) => (
                <div className={classes.row} key={rowIndex}>
                    {row.nodes.map((node: NodeDto, columnIndex: number) => {
                        const figure = node?.figure;
                        const owner = getPlayerFromUUID(figure?.playerId, props.playerList);

                        return (
                            <PositionedSlideAnimation
                                offsetX={columnIndex}
                                offsetY={rowIndex + 1}
                                diameter={props.posGridData.rows[rowIndex].nodes.length}>
                                <DraggableGridNode
                                    data-cy={`PositioningGridNode-${columnIndex}-${rowIndex}`}
                                    key={`${rowIndex}-${columnIndex}`}
                                    text={figure?.rank.toString() || ""}
                                    diameter={DIAMETER_DEFAULT}
                                    color={(owner?.color ?? (node.traversable ? colors.ground : colors.lake))}
                                    onClick={() => props.onNodeClicked(columnIndex, rowIndex, node)}
                                    onFigureDropped={() => props.onNodeDropped(columnIndex, rowIndex, node)}
                                />
                            </PositionedSlideAnimation>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};

export default PositioningGrid;