/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { Box, Title } from "@mantine/core";
import BoardProgress from "../../components/loaders/BoardProgress";

/**
 * Component that displays the pending status of the {@link HostPage} component.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const HostPendingView = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100vh"
            }}>
            <BoardProgress
                width={25}
                height={25}
                dimensionUnit="vw"
                diameter={9}/>
            <Title
                sx={{
                    marginTop: "3rem"
                }}
                order={3}>
                Connecting to the Lobby
            </Title>
        </Box>
    );
};

export default HostPendingView;