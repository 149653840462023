/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import {useEffect, useState} from "react";
import axios from "axios";
import {FigureExplanation} from "../model/figureExplanations";

/**
 * Type that represents the output structure of the figure explanation hook.
 *
 * figureExplanations: {@link FigureExplanation} that was fetched.
 * pending: Determines whether the request is currently pending.
 * error: Contains any error that occurred during the process.
 */
type FigureExplanationHookStructure = [
    figureExplanations: FigureExplanation[],
    pending: boolean,
    error: string,
];

/**
 * Custom hook that fetches the {@link FigureExplanation} with a specific rank using the REST API.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const useFigureExplanation = (): FigureExplanationHookStructure => {

    const [figureExplanations, setFigureExplanations] = useState<FigureExplanation[]>(null);
    const [pending, setPending] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);

    /**
     * Method that fetches a {@link FigureExplanation} with a specific ID.
     *
     * @param rank Rank of the {@link FigureExplanation} that should be fetched.
     */
    useEffect(() => {
        setPending(true);
        axios.get("/api/v1/explanations")
            .then(result => {
                setPending(false);
                setError(null);
                setFigureExplanations(result.data);
            })
            .catch(() => {
                setPending(false);
                setError("Unable to fetch figure explanations");
            })
    }, []);

    return [
        figureExplanations,
        pending,
        error,
    ];
};

export default useFigureExplanation;