/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HomePage from "../pages/HomePage";
import HostPage from "../pages/HostPage";
import PositioningPage from "../pages/PositioningPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage/>
    },
    {
        path: "/join",
        element: <HostPage/>
    },
    {
        path: "/game",
        element: <PositioningPage/>
    }
]);

/**
 * Main component of the application.
 * This component hosts the {@link RouterProvider} of the application.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const App = () => {
    return (
        <RouterProvider router={router}/>
    );
};

export default App;