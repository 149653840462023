/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useRoomConnection, {
    ConnectionErrorRoomAtCapacity,
    ConnectionErrorRoomNotFound,
    RefreshTokenCookie,
    RoomConnectionContext
} from "../../hooks/rooms/useRoomConnection";
import LobbyPage from "../LobbyPage";
import { CauseAction, GamePhase } from "../../model/proto/event";
import HostPendingView from "./HostPendingView";
import HostErrorView, { HostErrorViewIcon } from "./HostErrorView";
import PositioningPage from "../PositioningPage";
import { notifications } from "@mantine/notifications";
import { PersonRemove } from "@styled-icons/material-twotone/PersonRemove";
import { PersonAddAlt } from "@styled-icons/material-twotone/PersonAddAlt";
import GamePage from "../GamePage";
import HostReconnectingView from "./HostReconnectingView";
import { useCookies } from "react-cookie";

/**
 * Main page of the game.
 * This page hosts the WebSocket connection and descendant game pages.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const HostPage = () => {

    const [searchParams] = useSearchParams();
    const [, , removeCookie] = useCookies();
    const roomCode = searchParams.get("code");
    const roomConnection = useRoomConnection({roomCode: roomCode});
    const navigate = useNavigate();

    /**
     * Method that builds the state dependant component of the page.
     *
     * @return Returns the state dependant component of the page.
     */
    const _buildStateComponent = () => {
        if (roomConnection.applicationState.connectionState === "failed") {
            return (
                <HostErrorView
                    icon={_obtainErrorIcon()}
                    title={_obtainErrorTitle()}
                    subtitle={_obtainErrorSubtitle()}/>
            );
        } else if (roomConnection.applicationState.connectionState === "reconnecting") {
            return <HostReconnectingView attempt={roomConnection.applicationState.connectionAttempt}/>
        } else if (roomConnection.applicationState.connectionState === "connecting") {
            return <HostPendingView/>
        } else {
            return _buildMainComponent();
        }
    };

    /**
     * Method that obtains the error icon of the page.
     *
     * @return Returns the obtained error icon of the page.
     */
    const _obtainErrorIcon = (): HostErrorViewIcon => {
        return roomConnection.applicationState.connectionError === ConnectionErrorRoomNotFound
            ? "not_found"
            : roomConnection.applicationState.connectionError === ConnectionErrorRoomAtCapacity
                ? "at_capacity"
                : "general";
    };

    /**
     * Method that obtains the error title of the page.
     *
     * @return Returns the obtained error title of the page.
     */
    const _obtainErrorTitle = (): string => {
        return roomConnection.applicationState.connectionError === ConnectionErrorRoomNotFound
            ? "Room Not Found"
            : roomConnection.applicationState.connectionError === ConnectionErrorRoomAtCapacity
                ? "Room At Capacity"
                : "An Error Occurred";
    };

    /**
     * Method that obtains the error subtitle of the page.
     *
     * @return Returns the obtained error subtitle of the page.
     */
    const _obtainErrorSubtitle = (): string => {
        return roomConnection.applicationState.connectionError === ConnectionErrorRoomNotFound
            ? `The room with the code ${roomCode} does not exist`
            : roomConnection.applicationState.connectionError === ConnectionErrorRoomAtCapacity
                ? `The room with the code ${roomCode} is already full`
                : roomConnection.applicationState.connectionError;
    };

    /**
     * Method that builds the main component of the page.
     */
    const _buildMainComponent = () => {
        if (roomConnection.applicationState.gamePhase === GamePhase.LOBBY) {
            return <LobbyPage/>;
        } else if (roomConnection.applicationState.gamePhase === GamePhase.POSITIONING) {
            return <PositioningPage/>;
        } else {
            return <GamePage/>;
        }
    }

    useEffect(() => {
        roomConnection.registerEventListener({
            listenerID: "room_closed_listener",
            filterPredicate: event => event.roomClosedEvent !== undefined,
            fire: () => {
                navigate("/");
                removeCookie(RefreshTokenCookie);
            },
        });

        roomConnection.registerEventListener({
            listenerID: "player_room_event_listener",
            filterPredicate: event => event.playerListUpdatedEvent !== undefined,
            fire: event => {
                const player = event.playerListUpdatedEvent.players.find(player =>
                    player.uuid === event.playerListUpdatedEvent.causePlayerId);

                if (player
                    && roomConnection.applicationStateReference.current?.localPlayer?.uuid
                    && roomConnection.applicationStateReference.current.localPlayer.uuid.length > 0
                    && player.uuid !== roomConnection.applicationStateReference.current.localPlayer.uuid
                    && event.playerListUpdatedEvent.causeAction === CauseAction.JOIN) {
                    notifications.show({
                        id: "lobby_player_joined",
                        autoClose: 4000,
                        color: "green",
                        title: "Player joined the room",
                        message: `The player '${player.name}' just joined the room.`,
                        icon: <PersonAddAlt width={20} height={20}/>
                    });
                } else if (event.playerListUpdatedEvent.causeAction === CauseAction.LEAVE) {
                    notifications.show({
                        id: "lobby_player_left",
                        autoClose: 4000,
                        color: "red",
                        title: "Player left the room",
                        message: `A player just left the room.`,
                        icon: <PersonRemove width={20} height={20}/>
                    });
                }
            }
        });

        return () => {
            roomConnection.unregisterEventListener("player_room_event_listener");
            roomConnection.unregisterEventListener("room_closed_listener");
        }
        // eslint-disable-next-line
    }, []);

    return (
        <RoomConnectionContext.Provider value={roomConnection}>
            {_buildStateComponent()}
        </RoomConnectionContext.Provider>
    );
};

export default HostPage;