/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {Box, Table} from "@mantine/core";
import {Rank} from "../model/proto/dto";
import FigureExplanationListItem from "./FigureExplanationListItem";

/**
 * Type that represents the props of the {@link FigureExplanationList}.
 *
 * listItems: list of ranks that are going to get listed
 * onRankClick: function used to return the clicked rank via the handleClick function
 * playerColor: the color of the player
 */
type FigureExplanationListProps = {
    listItems: Rank[];
    onRankClick?: (rank: Rank) => void;
    playerColor: string;
};

/**
 * List of {@link FigureExplanationListItem}.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const FigureExplanationList = (props: FigureExplanationListProps) => {

    /**
     * Callback method that handles the click of an item inside the list.
     *
     * @param rank {@link Rank} of the item that was clicked.
     */
    const _handleItemClick = (rank: Rank) => {
        if (props.onRankClick)
            props.onRankClick(rank);
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            width: "20rem"
        }}>
            <Table highlightOnHover>
                <tbody>
                {props.listItems.map(rank => (
                    <tr key={rank}>
                        <td>
                            <FigureExplanationListItem
                                playerColor={props.playerColor}
                                rank={rank}
                                onClick={() => _handleItemClick(rank)}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Box>
    );
};

export default FigureExplanationList;