/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import axios from "axios";

/**
 * Type that represents the elements that are returned by the room surrender hook.
 *
 * surrenderRoom: Method that lets a player surrender in a room with a given code and refresh token.
 */
type RoomSurrenderHookStructure = [
    surrenderRoom: (roomCode: string, refreshToken: string, callback: () => void) => void,
];

/**
 * Custom hook used to let a specific player surrender in a given room.
 * The player is automatically inferred using the given refresh token.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const useRoomSurrender = (): RoomSurrenderHookStructure => {

    /**
     * Method that lets a given player surrender in a given room.
     * This method invokes a request to the REST API endpoint used to let the player surrender.
     * The player is inferred using the information of the given refresh token.
     *
     * @param roomCode Code of the room the player should surrender in.
     * @param refreshToken Refresh token used to identify the player and authorize the request.
     * @param callback Callback that is invoked once the action was completed.
     */
    const surrenderRoom = (roomCode: string, refreshToken: string, callback: () => void) => {
        axios.post(`/api/v1/rooms/${roomCode}/surrender`, refreshToken, {
            headers: {
                "Content-Type": "text/plain",
            },
        }).then(() => {
            callback();
        }).catch(() => {
            callback();
        });
    };

    return [
        surrenderRoom,
    ];
};

export default useRoomSurrender;