/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {Box, createStyles, Title} from "@mantine/core";
import {RefreshTokenCookie, RoomConnectionContext,} from "../../hooks/rooms/useRoomConnection";
import {BaseStackDto, NodeDto, PlayerState, Rank,} from "../../model/proto/dto";
import PositioningGrid from "../../components/grid/PositioningGrid";
import PlayerInventory from "./PlayerInventory";
import {PuzzleCube} from "@styled-icons/fluentui-system-regular/PuzzleCube";
import {DeleteSweep} from "@styled-icons/material-rounded/DeleteSweep";
import LobbyPlayerStateButton from "../LobbyPage/LobbyPlayerStateButton";
import TextButton from "../../components/TextButton";
import SelectPositioningPresetDialog from "./SelectPositioningPresetDialog";
import usePositioningController from "../../hooks/controller/usePositioningController";
import AbortGameButton from "../../components/AbortGameButton";
import {AnimatePresence} from "framer-motion";
import SlideAnimation, {SlideDirection,} from "../../components/animation/SlideAnimation";
import useRoomDeletion from "../../hooks/rooms/useRoomDeletion";
import {useCookies} from "react-cookie";
import usePlayerReadyState from "../../hooks/usePlayerReadyState";
import FigureExplanationDialog from "../../components/FigureExplanationDialog";
import GameMenu from "../GamePage/GameMenu";
import GameInstructionsDialog from "../../components/GameInstructionsDialog";

const useStyles = createStyles({
    rootContainer: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    boardContainer: {
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    inventoryContainer: {
        marginLeft: "5rem",
        marginRight: "5rem",
        marginBottom: "3rem",
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "5rem",
    },
    headerContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        margin: "2rem",
    },
});

/**
 * Page that represents the positioning phase.
 * It renders the {@link PositioningGrid} and the {@link PlayerInventory}.
 * The {@link PositioningGrid} is used to position the figures on the board.
 * The {@link PlayerInventory} is used to select the figures that should be positioned.
 * The {@link PositioningGrid} and the {@link PlayerInventory} are connected via the {@link RoomConnectionContext}.
 * The {@link RoomConnectionContext} is used to get the board and the {@link PlayerDto}.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const PositioningPage = () => {
    const [explanationDialogOpen, setExplanationDialogOpen] =
        useState<boolean>(false);
    const [instructionsDialogOpen, setInstructionsDialogOpen] =
        useState<boolean>(false);

    const [selectedStack, setSelectedStack] = useState<BaseStackDto>(null);
    const selectedStackRef = useRef<BaseStackDto>(null);

    const [presetDialogOpen, setPresetDialogOpen] = useState<boolean>(false);

    const roomConnection = useContext(RoomConnectionContext);
    const appState = roomConnection.applicationState;

    const [
        placeFigure,
        removeFigure,
        loadPreset,
        resetPositioning,
        showErrorMessage,
    ] = usePositioningController();

    const [changePlayerState] = usePlayerReadyState();

    const [cookies, , removeCookie] = useCookies();
    const [deleteRoom] = useRoomDeletion();

    const {classes} = useStyles();

    useEffect(() => {
        selectedStackRef.current = selectedStack;
    }, [selectedStack]);

    /**
     * Method that is invoked when a node on the positioning board has been clicked.
     * This method does some pre-conditionally checks and sends the request to place the figure on the positioning
     * board.
     *
     * @param x X-Coordinate of the node that was clicked.
     * @param y Y-Coordinate of the node that was clicked.
     * @param node Node that was clicked.
     */
    const _handleNodeClick = (x: number, y: number, node: NodeDto) => {
        if (!selectedStack && !node.figure) {
            showErrorMessage("No Selection", "Please select a figure first");
            return;
        }

        // Place the figure
        if (node.figure) {
            _removeFigure(x, y, node);
        } else {
            _placeFigure(x, y, node, selectedStack.rank);
        }
    };

    /**
     * Method that is invoked when a figure has been dropped on the positioning board.
     * This method does some pre-conditionally checks and sends the request to place the figure on the positioning
     * board.
     *
     * @param x X-Coordinate of the node the figure was dropped on.
     * @param y Y-Coordinate of the node the figure was dropped on.
     * @param node Node that was clicked.
     */
    const _handleNodeDrop = (x: number, y: number, node: NodeDto) => {
        // Place the figure
        if (selectedStackRef.current) {
            _placeFigure(x, y, node, selectedStackRef.current.rank);
        }
    };

    /**
     * Method that places a figure on a node on the positioning board.
     * This is done by sending the corresponding request to the backend.
     *
     * @param x X-Coordinate of the node the figure should be placed on.
     * @param y Y-Coordinate of the node the figure should be placed on.
     * @param node Node the figure should be placed on.
     * @param rank Rank of the figure that should be placed on the node.
     */
    const _placeFigure = (x: number, y: number, node: NodeDto, rank: Rank) => {
        if (!node.traversable) {
            showErrorMessage(
                "Not Traversable",
                "No figure can be placed on this node"
            );
            return;
        }

        placeFigure({positionX: x, positionY: y}, node, rank, (response) => {
            if (!response.status.succeeded) {
                showErrorMessage("Unable to place figure", response.status.status);
            }
        });
    };

    /**
     * Method that removes a figure from a node on the positioning board.
     * This is done by sending the corresponding request to the backend.
     *
     * @param x X-Coordinate of the node the figure should be removed from.
     * @param y Y-Coordinate of the node the figure should be removed from.
     * @param node Node the figure should be removed from.
     */
    const _removeFigure = (x: number, y: number, node: NodeDto) => {
        if (
            roomConnection.applicationState.localPlayer.playerState ===
            PlayerState.READY
        ) {
            changePlayerState();
        }

        removeFigure({positionX: x, positionY: y}, (response) => {
            if (!response.status.succeeded)
                showErrorMessage("Unable to remove figure", response.status.status);
        });
    };

    return (
        <div className={classes.rootContainer}>
            <FigureExplanationDialog
                playerColor={
                    roomConnection.applicationState.localPlayer?.color ?? "blue"
                }
                opened={explanationDialogOpen}
                onDismiss={() => setExplanationDialogOpen(false)}
            />
            <GameInstructionsDialog
                opened={instructionsDialogOpen}
                onDismiss={() => setInstructionsDialogOpen(false)}
            />
            <GameMenu
                onHelpRequested={() => setExplanationDialogOpen(true)}
                onGameInstructionsRequested={() => setInstructionsDialogOpen(true)}
                roomCode={roomConnection.applicationState.roomCode}
                drawNotPossiblePhase={true}
            />
            <SelectPositioningPresetDialog
                playerColor={
                    roomConnection.applicationState.localPlayer?.color ?? "blue"
                }
                opened={presetDialogOpen}
                onDismiss={() => setPresetDialogOpen(false)}
                onPresetConfirmed={(presetId) => {
                    loadPreset(presetId, (response) => {
                        if (!response.status.succeeded)
                            showErrorMessage("Unable to load preset", response.status.status);
                    });
                    setPresetDialogOpen(false);
                }}
            />
            <SlideAnimation
                direction={SlideDirection.TOP}
                className={classes.headerContainer}
            >
                <Title order={4}>
                    Positioning your figures by clicking or dragging them onto the field
                </Title>
                <AnimatePresence>
                    {roomConnection.applicationState?.playerList?.some(
                        (player) => !player.connected
                    ) && (
                        <SlideAnimation direction={SlideDirection.CENTER}>
                            <Title order={4} weight="bolder">
                                The opponent player disconnected. Waiting for player...
                            </Title>
                        </SlideAnimation>
                    )}
                </AnimatePresence>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    <TextButton
                        data-cy="SelectPositioningTemplateButton"
                        onClick={() => setPresetDialogOpen(true)}
                        rightIcon={<PuzzleCube width={20} height={20}/>}
                    >
                        <Title order={4}>Select A Template</Title>
                    </TextButton>
                    <Box sx={{width: "1rem"}}/>
                    <TextButton
                        data-cy="ResetPositioningConfigurationButton"
                        onClick={resetPositioning}
                        rightIcon={<DeleteSweep width={20} height={20}/>}
                    >
                        <Title order={4}>Reset All Figures</Title>
                    </TextButton>
                </Box>
            </SlideAnimation>
            {roomConnection.applicationState.positioningConfiguration ? (
                <>
                    <div className={classes.boardContainer}>
                        <PositioningGrid
                            playerList={appState.playerList}
                            posGridData={
                                roomConnection.applicationState?.positioningConfiguration
                                    ?.positioningBoard
                            }
                            onNodeClicked={_handleNodeClick}
                            onNodeDropped={_handleNodeDrop}
                        />
                    </div>
                    {roomConnection.applicationState?.positioningConfiguration?.playerInventory?.stacks?.some(
                        (stack) => stack.figures.length > 0
                    ) ? (
                        <div className={classes.inventoryContainer}>
                            <PlayerInventory
                                playerColor={
                                    roomConnection.applicationState?.localPlayer?.color ?? "red"
                                }
                                figureStacks={
                                    roomConnection.applicationState?.positioningConfiguration
                                        ?.playerInventory?.stacks ?? []
                                }
                                selectedStack={selectedStack}
                                setSelectedStack={(stack) => {
                                    if (stack.figures.length > 0) {
                                        selectedStackRef.current = selectedStack;
                                        setSelectedStack(stack !== selectedStack ? stack : null);
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <div className={classes.buttonContainer}>
                            <LobbyPlayerStateButton
                                pending={roomConnection.applicationState?.playerList?.some(
                                    (player) => !player.connected
                                )}
                                playerState={
                                    roomConnection.applicationState?.localPlayer?.playerState ??
                                    PlayerState.NOT_READY
                                }
                                onClick={changePlayerState}
                            />
                            <AnimatePresence>
                                {roomConnection.applicationState?.playerList?.some(
                                    (player) => !player.connected
                                ) && (
                                    <AbortGameButton
                                        onClick={() =>
                                            deleteRoom(
                                                roomConnection.applicationState.roomCode,
                                                cookies.refresh_token,
                                                () => removeCookie(RefreshTokenCookie)
                                            )
                                        }
                                    />
                                )}
                            </AnimatePresence>
                        </div>
                    )}
                </>
            ) : (
                <div>Positioning Configuration Loading</div>
            )}
        </div>
    );
};

export default PositioningPage;
