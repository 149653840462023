/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { ActionIcon, Box, createStyles, Tooltip } from "@mantine/core";
import SlideAnimation, { SlideDirection } from "../../components/animation/SlideAnimation";

/**
 * Type that represents the props of the {@link GameMenuItem}.
 */
type GameMenuItemProps = {
    label: string;
    IconClass: React.ComponentType;
    onClick?: () => void;
    disabled?: boolean;
};

const useStyles = createStyles({
    iconContainer: {
        margin: "0.5rem",
    }
});

/**
 * Component that represents a single item in the {@link GameMenu} component.
 *
 * @param label Label of the item.
 * @param IconClass Class of the icon of the item.
 * @param onClick Callback that is invoked when the icon was clicked.
 * @param disabled Determines whether the item is disabled.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const GameMenuItem: React.FC<GameMenuItemProps> = ({
                                                       label,
                                                       IconClass,
                                                       onClick,
                                                       disabled,
                                                   }) => {

    const {classes} = useStyles();

    return (
        <SlideAnimation direction={SlideDirection.RIGHT}>
            <Tooltip
                label={label}
                withArrow
                transitionProps={{
                    transition: "scale",
                    duration: 300,
                }}>
                <Box>
                    <ActionIcon
                        className={classes.iconContainer}
                        size={30}
                        disabled={disabled}
                        onClick={onClick}>
                        <IconClass/>
                    </ActionIcon>
                </Box>
            </Tooltip>
        </SlideAnimation>
    );
};

export default GameMenuItem;