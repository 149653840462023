/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import {BaseStackDto, PlayerDto} from "../model/proto/dto";
import {Accordion, createStyles, List, Text} from "@mantine/core";
import React from "react";
import {maxQuantityOfRank} from "../helper/Utils";
import BaseGridNode, {DIAMETER_DEFAULT} from "./grid/BaseGridNode";

/**
 * Type that represents the props of the {@link Graveyard}.
 *
 * graveyardStacks: {@link BaseStackDto} instances that hold the actual data.
 * player: Owning player.
 */
type GraveyardProps = {
    graveyardStacks: BaseStackDto[];
    player: PlayerDto;
};

const useStyles = createStyles(() => ({
    accordion: {
        width: "80%",
        marginTop: "-2.5rem",
        marginLeft: "3.5rem",
        marginRight: "3.5rem",
        alignItems: "center",
        justifyContent: "center",
    },
    accordionPanel: {
        maxHeight: "30vh",
        overflow: "scroll",
    },
    graveyardList: {
        display: "flex",
        alignItems: "center",
    },
    quantityCounter: {
        display: "flex",
        fontSize: "1.25rem",
        marginTop: "0.25rem",
        alignItems: "center",
    },
    nodeContainer: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    accControl: {
        display: "flex",
    },
}));

/**
 * Component that represents a graveyard of the player.
 * A graveyard contains a summary of all dead figures of a player.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const Graveyard = (props: GraveyardProps) => {

    const {classes} = useStyles();

    /**
     * Function that determines the color of a stack.
     * The color of the stack is determined by the amount of dead figures.
     *
     * @param deadFigures Amount of dead figures.
     * @param deadFigureStackRank Rank of the figures that are dead.
     *
     * @return Returns the color of the stack.
     */
    return (
        <Accordion
            variant="separated"
            chevronPosition="left"
            className={classes.accordion}>
            <Accordion.Item value="Graveyardlist">
                <Accordion.Control className={classes.accControl}>
                    Graveyard
                </Accordion.Control>
                <Accordion.Panel className={classes.accordionPanel}>
                    {props.graveyardStacks
                        .sort((a, b) => a.rank - b.rank)
                        .map(
                            (stack) =>
                                stack.figures.length >= 0 && (
                                    <List key={stack.rank}>
                                        <List.Item
                                            className={classes.graveyardList}
                                            icon={
                                                <BaseGridNode
                                                    text={stack.rank.toString()}
                                                    diameter={DIAMETER_DEFAULT - 2.5}
                                                    hoverable
                                                    color={props.player.color}
                                                />
                                            }>
                                            <div className={classes.nodeContainer}>
                                                <Text className={classes.quantityCounter}>
                                                    {stack.figures.length}/{maxQuantityOfRank(stack.rank)}
                                                </Text>
                                            </div>
                                        </List.Item>
                                    </List>
                                )
                        )}
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
};

export default Graveyard;
