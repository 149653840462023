/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Button, createStyles} from "@mantine/core";
import {Cancel} from "@styled-icons/typicons/Cancel";
import "./AbortGameButton.css";
import SlideAnimation, {SlideDirection} from "./animation/SlideAnimation";

/**
 * Type that represents the props of the {@link AbortGameButtonProps}.
 *
 * onClick: Callback that is invoked when the button was clicked.
 */
type AbortGameButtonProps = {
    onClick: () => void;
}

const useStyles = createStyles({
    button: {
        margin: "0.2",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "2.5rem",
        transition: "all .2s",
        padding: 0,
        width: "20rem",
        marginLeft: "0.2rem",
        marginTop: "1rem",
    }
});

/**
 * Component that allows the player to cancel a game after 30 seconds.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const AbortGameButton = (props: AbortGameButtonProps) => {

    const {classes} = useStyles();

    const [abortTimeout, setAbortTimeout] = useState<number>(30);
    const abortTimeoutRef = useRef<number>(30);
    const timeoutRef = useRef<NodeJS.Timeout>(null);

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        abortTimeoutRef.current = 30;
        _reduceTimer();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setAbortTimeout(abortTimeoutRef.current);
        // eslint-disable-next-line
    }, [timeoutRef.current]);

    /**
     * Method that recursively reduces the timout to abort the game.
     * This method reduces the timeout value every second.
     */
    const _reduceTimer = () => {
        timeoutRef.current = setTimeout(() => {
            abortTimeoutRef.current -= 1;
            setAbortTimeout(abortTimeoutRef.current);
            _reduceTimer();
        }, 1000);
    };

    return (
        <SlideAnimation direction={SlideDirection.BOTTOM}>
            <Button
                id="abort-button"
                className={classes.button}
                variant="gradient"
                rightIcon={<Cancel size={20}/>}
                radius="md"
                disabled={abortTimeout > 0}
                gradient={{
                    from: "orange",
                    to: "red",
                }}
                onClick={props.onClick}>
                Abort Game {abortTimeout > 0 ? `${abortTimeout}` : ""}
            </Button>
        </SlideAnimation>
    );
};

export default AbortGameButton;