/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import React from "react";
import { createStyles } from "@mantine/core";
import { BoardDto, NodeDto, PlayerDto, PositionDto, RowDto } from "../../model/proto/dto";
import { coordinateEquals, getPlayerFromUUID } from "../../helper/Utils";
import { colors } from "../../helper/ColorPalette";
import BaseGridNode, { DIAMETER_DEFAULT, NodeState } from "./BaseGridNode";
import { clamp } from "@mantine/hooks";
import PositionedSlideAnimation from "../animation/PositionedSlideAnimation";

/**
 * Type that represents the props of the {@link GameGrid} component.
 *
 * gridData: Board that should be represented.
 * playerList: List of players that is associated to the board.
 * onNodeClicked: Optional click listener of the grid.
 * target: Optional currently selected target node of the grid.
 * source: Optional currently selected source node of the grid.
 * emphasisData: Optional {@link EmphasisData} of the grid.
 */
type GameGridProps = {
    gridData: BoardDto;
    playerList: PlayerDto[];
    onNodeClicked?: (node: NodeDto) => void;
    target?: PositionDto;
    source?: PositionDto;
    emphasisData?: EmphasisData;
};

/**
 * Type that represents the data that indicates which nodes of the grid are emphasized.
 *
 * emphasizedPositions: List of elements that should be emphasized within the grid.
 * emphasizeWithZoom: Determines whether the board should be zoomed to only contain the emphasized positions.
 */
export type EmphasisData = {
    emphasizedPositions: EmphasizedPosition[];
    emphasizeWithZoom: boolean;
};

/**
 * Type that represents a single emphasized position on the grid.
 *
 * position: Position of the node that is emphasized.
 * state: State of the node.
 * contentOverride: Optional content the actual content of the node is replaced with when emphasized.
 */
export type EmphasizedPosition = {
    position: PositionDto;
    state: NodeState;
    contentOverride?: string;
};

/**
 * Function that converts a given server {@link PositionDto} to a client coordinate.
 * Every {@link NodeDto} contains its own position on the board.
 * However, these values are not consistent between all clients and the backend, because every client views
 * a rotated representation of the game board.
 * The client coordinate is the position of the node inside the frontend grid.
 *
 * @param coordinate {@link PositionDto} that should be transformed.
 * @param props {@link GameGridProps} that contains the required data for the transformation.
 */
const toClientCoordinate = (coordinate: PositionDto, props: GameGridProps): PositionDto => {
    for (let y = 0; y < props.gridData.rows.length; y++) {
        for (let x = 0; x < props.gridData.rows.length; x++) {
            const potentialNode = props.gridData.rows[y].nodes[x];
            if (potentialNode.coordinate.positionX === coordinate.positionX
                && potentialNode.coordinate.positionY === coordinate.positionY) {
                return {
                    positionX: x,
                    positionY: y,
                };
            }
        }
    }

    return {
        positionX: 5,
        positionY: 5,
    };
}

/**
 * Function that creates the transformation instructions for the grid to zoom to a list of specific nodes.
 * This function starts by calculating the combined bounds of all emphasized nodes.
 * The result is a rectangle that contains all emphasized nodes and is the zoom target.
 * After that, the center of the bounds is calculated as this is the actual target of the translation.
 * The translation instruction is calculated by multiplying the difference from the bounds center with the current
 * node dimensions.
 * Finally, the scaling factor of the grid is calculated, to contain all emphasized nodes.
 * All operations are clamped to prevent overshooting.
 *
 * @param props {@link GameGridProps} that contains the required data.
 *
 * @return Returns the CSS transform attribute that is attached to the grid.
 */
const createTransformationInstructions = (props: GameGridProps): string => {
    // Estimate the targets
    const emphasizedNodes = props.emphasisData?.emphasizedPositions ?? [];
    const targets = emphasizedNodes.map(position => toClientCoordinate(position.position, props));

    // Calculate the bounds that should be emphasized
    const bounds = {
        minimumBounds: {
            x: Math.min(...targets.map(target => target.positionX)),
            y: Math.min(...targets.map(target => target.positionY)),
        },
        maximumBounds: {
            x: Math.max(...targets.map(target => target.positionX)),
            y: Math.max(...targets.map(target => target.positionY)),
        }
    };

    // Calculate the center coordinates of the bounds
    const boundsCenter = {
        x: bounds.minimumBounds.x + (bounds.maximumBounds.x - bounds.minimumBounds.x) / 2,
        y: bounds.minimumBounds.y + (bounds.maximumBounds.y - bounds.minimumBounds.y) / 2,
    };

    const differenceFromCenter = {
        x: clamp(4.5 - boundsCenter.x, -2.45, 2.45),
        y: clamp(4.5 - boundsCenter.y, -2.45, 2.45),
    }

    const nodeDiameter = Math.min(
        document.documentElement.clientHeight * (DIAMETER_DEFAULT / 100),
        document.documentElement.clientWidth * (DIAMETER_DEFAULT / 100),
    );

    // Calculate the translation offsets
    const translation = {
        x: nodeDiameter * differenceFromCenter.x,
        y: nodeDiameter * differenceFromCenter.y,
    };

    // Calculate the scaling factor
    const scalingFactor = clamp(10 / Math.max(
        bounds.maximumBounds.x - bounds.minimumBounds.x,
        bounds.maximumBounds.y - bounds.minimumBounds.y,
    ) * 0.7, 1.0, 1.8);

    return `scale(${scalingFactor}) translate(${translation.x}px, ${translation.y}px)`;
};

const useStyles = createStyles((theme, props: GameGridProps) => ({
    gridContainer: {
        display: "flex",
        overflow: "hidden",
        flexShrink: 0,
    },
    grid: {
        display: "flex",
        flexGrow: 1,
        flexBasis: 1,
        flexShrink: 1,
        flexDirection: "column",
        transform: props.emphasisData?.emphasizeWithZoom ? createTransformationInstructions(props) : "",
        transition: "transform 1.5s",
        clipPath: "inset(0)"
    },
    rows: {
        display: "flex",
        flexDirection: "row",
    },
}));

/**
 * Component for the game grid.
 * It renders the grid out of {@link BaseGridNode}s with the given {@link BoardDto} data.
 *
 * @param props - {@link GameGridProps}
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const GameGrid = (props: GameGridProps) => {

    const {classes} = useStyles(props);

    /**
     * Function that acquires the {@link EmphasizedPosition} for a given {@link NodeDto}.
     *
     * @param node {@link NodeDto} the {@link EmphasizedPosition} should be acquired for.
     *
     * @return Returns the acquired {@link EmphasizedPosition} or null if not available.
     */
    const _getEmphasizedPosition = (node: NodeDto): EmphasizedPosition => {
        return props.emphasisData?.emphasizedPositions?.find(coordinate => coordinateEquals(coordinate.position, node.coordinate));
    };

    /**
     * Function that acquires the {@link NodeState} for a given {@link NodeDto}.
     * This function takes the click targets and the emphasis zoom state into consideration.
     *
     * @param node {@link NodeDto} the {@link NodeState} should be acquired for.
     *
     * @return Returns the acquired {@link NodeState} or {@link NodeState#DEFAULT} if not available.
     */
    const _getNodeState = (node: NodeDto): NodeState => {
        if (props.emphasisData?.emphasizeWithZoom) {
            return _getEmphasizedPosition(node)?.state ?? NodeState.BLURRED;
        } else {
            if (node?.coordinate === props.target || node?.coordinate === props.source) {
                return NodeState.SELECTED;
            } else {
                return _getEmphasizedPosition(node)?.state ?? NodeState.DEFAULT;
            }
        }
    };

    /**
     * Function that acquires the content for a {@link NodeDto}.
     * The {@link EmphasizedPosition} can contain content the actual node content is overridden with.
     * By default, the rank of the hosted figure of the {@link NodeDto} is returned.
     *
     * @param node {@link NodeDto} the content should be acquired for.
     *
     * @return Returns the acquired content or an empty string if not available.
     */
    const _getNodeContent = (node: NodeDto): string => {
        return _getEmphasizedPosition(node)?.contentOverride ?? node.figure?.rank?.toString() ?? "";
    };

    return props.gridData ? (
        <div className={classes.gridContainer}>
            <div className={classes.grid}>
                {props.gridData.rows.map((row: RowDto, rowIndex: number) => (
                    <div key={rowIndex} className={classes.rows}>
                        {row.nodes.map((node: NodeDto, columnIndex: number) => {
                            const figure = node?.figure;
                            const owner = getPlayerFromUUID(figure?.playerId, props.playerList);

                            return (
                                <PositionedSlideAnimation
                                    offsetX={columnIndex}
                                    offsetY={rowIndex}
                                    diameter={10}
                                    stiffness={300}>
                                    <BaseGridNode
                                        data-cy={`GameGridNode-${columnIndex}-${rowIndex}`}
                                        key={`${rowIndex}-${columnIndex}`}
                                        text={_getNodeContent(node)}
                                        diameter={DIAMETER_DEFAULT}
                                        hoverable
                                        state={_getNodeState(node)}
                                        color={(owner?.color ?? (node.traversable ? colors.ground : colors.lake))}
                                        onClick={() => props.onNodeClicked(node)}
                                    />
                                </PositionedSlideAnimation>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <div>
            Board Loading
        </div>
    );
};

export default GameGrid;
