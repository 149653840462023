// @ts-nocheck
// @generated by protobuf-ts 2.9.0
// @generated from protobuf file "event.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BaseStackContainerDto } from "./dto";
import { NodeDto } from "./dto";
import { BoardDto } from "./dto";
import { PlayerDto } from "./dto";
/**
 * @generated from protobuf message StrategoEvent
 */
export interface StrategoEvent {
    /**
     * @generated from protobuf field: PlayerListUpdatedEvent player_list_updated_event = 1;
     */
    playerListUpdatedEvent?: PlayerListUpdatedEvent;
    /**
     * @generated from protobuf field: GamePhaseChangedEvent game_phase_changed_event = 2;
     */
    gamePhaseChangedEvent?: GamePhaseChangedEvent;
    /**
     * @generated from protobuf field: BoardChangedEvent board_changed_event = 3;
     */
    boardChangedEvent?: BoardChangedEvent;
    /**
     * @generated from protobuf field: PositioningConfigurationChangedEvent positioning_configuration_changed_event = 4;
     */
    positioningConfigurationChangedEvent?: PositioningConfigurationChangedEvent;
    /**
     * @generated from protobuf field: CurrentPlayerChangedEvent current_player_changed_event = 5;
     */
    currentPlayerChangedEvent?: CurrentPlayerChangedEvent;
    /**
     * @generated from protobuf field: FightResultEvent fight_result_event = 6;
     */
    fightResultEvent?: FightResultEvent;
    /**
     * @generated from protobuf field: GameOverEvent game_over_event = 7;
     */
    gameOverEvent?: GameOverEvent;
    /**
     * @generated from protobuf field: RoomClosedEvent room_closed_event = 8;
     */
    roomClosedEvent?: RoomClosedEvent;
    /**
     * @generated from protobuf field: DrawOfferedEvent draw_offered_event = 9;
     */
    drawOfferedEvent?: DrawOfferedEvent;
    /**
     * @generated from protobuf field: DrawDeclinedEvent draw_declined_event = 10;
     */
    drawDeclinedEvent?: DrawDeclinedEvent;
}
/**
 * @generated from protobuf message PlayerListUpdatedEvent
 */
export interface PlayerListUpdatedEvent {
    /**
     * @generated from protobuf field: repeated PlayerDto players = 1;
     */
    players: PlayerDto[];
    /**
     * @generated from protobuf field: string cause_player_id = 2;
     */
    causePlayerId: string;
    /**
     * @generated from protobuf field: CauseAction cause_action = 3;
     */
    causeAction: CauseAction;
}
/**
 * @generated from protobuf message GamePhaseChangedEvent
 */
export interface GamePhaseChangedEvent {
    /**
     * @generated from protobuf field: GamePhase game_phase = 1;
     */
    gamePhase: GamePhase;
}
/**
 * @generated from protobuf message BoardChangedEvent
 */
export interface BoardChangedEvent {
    /**
     * @generated from protobuf field: BoardDto board = 1;
     */
    board?: BoardDto;
}
/**
 * @generated from protobuf message CurrentPlayerChangedEvent
 */
export interface CurrentPlayerChangedEvent {
    /**
     * @generated from protobuf field: PlayerDto current_player = 1;
     */
    currentPlayer?: PlayerDto;
}
/**
 * @generated from protobuf message FightResultEvent
 */
export interface FightResultEvent {
    /**
     * @generated from protobuf field: NodeDto attacker = 1;
     */
    attacker?: NodeDto;
    /**
     * @generated from protobuf field: NodeDto defender = 2;
     */
    defender?: NodeDto;
    /**
     * @generated from protobuf field: FightResult result = 3;
     */
    result: FightResult;
}
/**
 * @generated from protobuf message PositioningConfigurationChangedEvent
 */
export interface PositioningConfigurationChangedEvent {
    /**
     * @generated from protobuf field: BoardDto board = 1;
     */
    board?: BoardDto;
    /**
     * @generated from protobuf field: BaseStackContainerDto inventory = 2;
     */
    inventory?: BaseStackContainerDto;
}
/**
 * @generated from protobuf message GameOverEvent
 */
export interface GameOverEvent {
    /**
     * @generated from protobuf field: PlayerDto winner = 1;
     */
    winner?: PlayerDto;
    /**
     * @generated from protobuf field: PlayerDto loser = 2;
     */
    loser?: PlayerDto;
}
/**
 * @generated from protobuf message RoomClosedEvent
 */
export interface RoomClosedEvent {
    /**
     * @generated from protobuf field: PlayerDto sender = 1;
     */
    sender?: PlayerDto;
}
/**
 * @generated from protobuf message DrawOfferedEvent
 */
export interface DrawOfferedEvent {
    /**
     * @generated from protobuf field: string cause_player_id = 1;
     */
    causePlayerId: string;
}
/**
 * @generated from protobuf message DrawDeclinedEvent
 */
export interface DrawDeclinedEvent {
    /**
     * @generated from protobuf field: string cause_player_id = 1;
     */
    causePlayerId: string;
}
/**
 * @generated from protobuf enum CauseAction
 */
export enum CauseAction {
    /**
     * @generated from protobuf enum value: CAUSE_ACTION_JOIN = 0;
     */
    JOIN = 0,
    /**
     * @generated from protobuf enum value: CAUSE_ACTION_LEAVE = 1;
     */
    LEAVE = 1,
    /**
     * @generated from protobuf enum value: CAUSE_ACTION_UPDATE = 2;
     */
    UPDATE = 2
}
/**
 * @generated from protobuf enum GamePhase
 */
export enum GamePhase {
    /**
     * @generated from protobuf enum value: GAME_PHASE_LOBBY = 0;
     */
    LOBBY = 0,
    /**
     * @generated from protobuf enum value: GAME_PHASE_POSITIONING = 1;
     */
    POSITIONING = 1,
    /**
     * @generated from protobuf enum value: GAME_PHASE_PLAYING = 2;
     */
    PLAYING = 2,
    /**
     * @generated from protobuf enum value: GAME_PHASE_COMPLETED = 3;
     */
    COMPLETED = 3
}
/**
 * @generated from protobuf enum FightResult
 */
export enum FightResult {
    /**
     * @generated from protobuf enum value: FIGHT_RESULT_ATTACKER_WON = 0;
     */
    ATTACKER_WON = 0,
    /**
     * @generated from protobuf enum value: FIGHT_RESULT_DEFENDER_WON = 1;
     */
    DEFENDER_WON = 1,
    /**
     * @generated from protobuf enum value: FIGHT_RESULT_DRAW = 2;
     */
    DRAW = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class StrategoEvent$Type extends MessageType<StrategoEvent> {
    constructor() {
        super("StrategoEvent", [
            { no: 1, name: "player_list_updated_event", kind: "message", T: () => PlayerListUpdatedEvent },
            { no: 2, name: "game_phase_changed_event", kind: "message", T: () => GamePhaseChangedEvent },
            { no: 3, name: "board_changed_event", kind: "message", T: () => BoardChangedEvent },
            { no: 4, name: "positioning_configuration_changed_event", kind: "message", T: () => PositioningConfigurationChangedEvent },
            { no: 5, name: "current_player_changed_event", kind: "message", T: () => CurrentPlayerChangedEvent },
            { no: 6, name: "fight_result_event", kind: "message", T: () => FightResultEvent },
            { no: 7, name: "game_over_event", kind: "message", T: () => GameOverEvent },
            { no: 8, name: "room_closed_event", kind: "message", T: () => RoomClosedEvent },
            { no: 9, name: "draw_offered_event", kind: "message", T: () => DrawOfferedEvent },
            { no: 10, name: "draw_declined_event", kind: "message", T: () => DrawDeclinedEvent }
        ]);
    }
    create(value?: PartialMessage<StrategoEvent>): StrategoEvent {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StrategoEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StrategoEvent): StrategoEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PlayerListUpdatedEvent player_list_updated_event */ 1:
                    message.playerListUpdatedEvent = PlayerListUpdatedEvent.internalBinaryRead(reader, reader.uint32(), options, message.playerListUpdatedEvent);
                    break;
                case /* GamePhaseChangedEvent game_phase_changed_event */ 2:
                    message.gamePhaseChangedEvent = GamePhaseChangedEvent.internalBinaryRead(reader, reader.uint32(), options, message.gamePhaseChangedEvent);
                    break;
                case /* BoardChangedEvent board_changed_event */ 3:
                    message.boardChangedEvent = BoardChangedEvent.internalBinaryRead(reader, reader.uint32(), options, message.boardChangedEvent);
                    break;
                case /* PositioningConfigurationChangedEvent positioning_configuration_changed_event */ 4:
                    message.positioningConfigurationChangedEvent = PositioningConfigurationChangedEvent.internalBinaryRead(reader, reader.uint32(), options, message.positioningConfigurationChangedEvent);
                    break;
                case /* CurrentPlayerChangedEvent current_player_changed_event */ 5:
                    message.currentPlayerChangedEvent = CurrentPlayerChangedEvent.internalBinaryRead(reader, reader.uint32(), options, message.currentPlayerChangedEvent);
                    break;
                case /* FightResultEvent fight_result_event */ 6:
                    message.fightResultEvent = FightResultEvent.internalBinaryRead(reader, reader.uint32(), options, message.fightResultEvent);
                    break;
                case /* GameOverEvent game_over_event */ 7:
                    message.gameOverEvent = GameOverEvent.internalBinaryRead(reader, reader.uint32(), options, message.gameOverEvent);
                    break;
                case /* RoomClosedEvent room_closed_event */ 8:
                    message.roomClosedEvent = RoomClosedEvent.internalBinaryRead(reader, reader.uint32(), options, message.roomClosedEvent);
                    break;
                case /* DrawOfferedEvent draw_offered_event */ 9:
                    message.drawOfferedEvent = DrawOfferedEvent.internalBinaryRead(reader, reader.uint32(), options, message.drawOfferedEvent);
                    break;
                case /* DrawDeclinedEvent draw_declined_event */ 10:
                    message.drawDeclinedEvent = DrawDeclinedEvent.internalBinaryRead(reader, reader.uint32(), options, message.drawDeclinedEvent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StrategoEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PlayerListUpdatedEvent player_list_updated_event = 1; */
        if (message.playerListUpdatedEvent)
            PlayerListUpdatedEvent.internalBinaryWrite(message.playerListUpdatedEvent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* GamePhaseChangedEvent game_phase_changed_event = 2; */
        if (message.gamePhaseChangedEvent)
            GamePhaseChangedEvent.internalBinaryWrite(message.gamePhaseChangedEvent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BoardChangedEvent board_changed_event = 3; */
        if (message.boardChangedEvent)
            BoardChangedEvent.internalBinaryWrite(message.boardChangedEvent, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* PositioningConfigurationChangedEvent positioning_configuration_changed_event = 4; */
        if (message.positioningConfigurationChangedEvent)
            PositioningConfigurationChangedEvent.internalBinaryWrite(message.positioningConfigurationChangedEvent, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* CurrentPlayerChangedEvent current_player_changed_event = 5; */
        if (message.currentPlayerChangedEvent)
            CurrentPlayerChangedEvent.internalBinaryWrite(message.currentPlayerChangedEvent, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* FightResultEvent fight_result_event = 6; */
        if (message.fightResultEvent)
            FightResultEvent.internalBinaryWrite(message.fightResultEvent, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* GameOverEvent game_over_event = 7; */
        if (message.gameOverEvent)
            GameOverEvent.internalBinaryWrite(message.gameOverEvent, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* RoomClosedEvent room_closed_event = 8; */
        if (message.roomClosedEvent)
            RoomClosedEvent.internalBinaryWrite(message.roomClosedEvent, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* DrawOfferedEvent draw_offered_event = 9; */
        if (message.drawOfferedEvent)
            DrawOfferedEvent.internalBinaryWrite(message.drawOfferedEvent, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* DrawDeclinedEvent draw_declined_event = 10; */
        if (message.drawDeclinedEvent)
            DrawDeclinedEvent.internalBinaryWrite(message.drawDeclinedEvent, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StrategoEvent
 */
export const StrategoEvent = new StrategoEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlayerListUpdatedEvent$Type extends MessageType<PlayerListUpdatedEvent> {
    constructor() {
        super("PlayerListUpdatedEvent", [
            { no: 1, name: "players", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PlayerDto },
            { no: 2, name: "cause_player_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "cause_action", kind: "enum", T: () => ["CauseAction", CauseAction, "CAUSE_ACTION_"] }
        ]);
    }
    create(value?: PartialMessage<PlayerListUpdatedEvent>): PlayerListUpdatedEvent {
        const message = { players: [], causePlayerId: "", causeAction: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlayerListUpdatedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlayerListUpdatedEvent): PlayerListUpdatedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PlayerDto players */ 1:
                    message.players.push(PlayerDto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cause_player_id */ 2:
                    message.causePlayerId = reader.string();
                    break;
                case /* CauseAction cause_action */ 3:
                    message.causeAction = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlayerListUpdatedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated PlayerDto players = 1; */
        for (let i = 0; i < message.players.length; i++)
            PlayerDto.internalBinaryWrite(message.players[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cause_player_id = 2; */
        if (message.causePlayerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.causePlayerId);
        /* CauseAction cause_action = 3; */
        if (message.causeAction !== 0)
            writer.tag(3, WireType.Varint).int32(message.causeAction);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PlayerListUpdatedEvent
 */
export const PlayerListUpdatedEvent = new PlayerListUpdatedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GamePhaseChangedEvent$Type extends MessageType<GamePhaseChangedEvent> {
    constructor() {
        super("GamePhaseChangedEvent", [
            { no: 1, name: "game_phase", kind: "enum", T: () => ["GamePhase", GamePhase, "GAME_PHASE_"] }
        ]);
    }
    create(value?: PartialMessage<GamePhaseChangedEvent>): GamePhaseChangedEvent {
        const message = { gamePhase: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GamePhaseChangedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GamePhaseChangedEvent): GamePhaseChangedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* GamePhase game_phase */ 1:
                    message.gamePhase = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GamePhaseChangedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* GamePhase game_phase = 1; */
        if (message.gamePhase !== 0)
            writer.tag(1, WireType.Varint).int32(message.gamePhase);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GamePhaseChangedEvent
 */
export const GamePhaseChangedEvent = new GamePhaseChangedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BoardChangedEvent$Type extends MessageType<BoardChangedEvent> {
    constructor() {
        super("BoardChangedEvent", [
            { no: 1, name: "board", kind: "message", T: () => BoardDto }
        ]);
    }
    create(value?: PartialMessage<BoardChangedEvent>): BoardChangedEvent {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BoardChangedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BoardChangedEvent): BoardChangedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BoardDto board */ 1:
                    message.board = BoardDto.internalBinaryRead(reader, reader.uint32(), options, message.board);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BoardChangedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BoardDto board = 1; */
        if (message.board)
            BoardDto.internalBinaryWrite(message.board, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BoardChangedEvent
 */
export const BoardChangedEvent = new BoardChangedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CurrentPlayerChangedEvent$Type extends MessageType<CurrentPlayerChangedEvent> {
    constructor() {
        super("CurrentPlayerChangedEvent", [
            { no: 1, name: "current_player", kind: "message", T: () => PlayerDto }
        ]);
    }
    create(value?: PartialMessage<CurrentPlayerChangedEvent>): CurrentPlayerChangedEvent {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CurrentPlayerChangedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CurrentPlayerChangedEvent): CurrentPlayerChangedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PlayerDto current_player */ 1:
                    message.currentPlayer = PlayerDto.internalBinaryRead(reader, reader.uint32(), options, message.currentPlayer);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CurrentPlayerChangedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PlayerDto current_player = 1; */
        if (message.currentPlayer)
            PlayerDto.internalBinaryWrite(message.currentPlayer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CurrentPlayerChangedEvent
 */
export const CurrentPlayerChangedEvent = new CurrentPlayerChangedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FightResultEvent$Type extends MessageType<FightResultEvent> {
    constructor() {
        super("FightResultEvent", [
            { no: 1, name: "attacker", kind: "message", T: () => NodeDto },
            { no: 2, name: "defender", kind: "message", T: () => NodeDto },
            { no: 3, name: "result", kind: "enum", T: () => ["FightResult", FightResult, "FIGHT_RESULT_"] }
        ]);
    }
    create(value?: PartialMessage<FightResultEvent>): FightResultEvent {
        const message = { result: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FightResultEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FightResultEvent): FightResultEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* NodeDto attacker */ 1:
                    message.attacker = NodeDto.internalBinaryRead(reader, reader.uint32(), options, message.attacker);
                    break;
                case /* NodeDto defender */ 2:
                    message.defender = NodeDto.internalBinaryRead(reader, reader.uint32(), options, message.defender);
                    break;
                case /* FightResult result */ 3:
                    message.result = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FightResultEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* NodeDto attacker = 1; */
        if (message.attacker)
            NodeDto.internalBinaryWrite(message.attacker, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* NodeDto defender = 2; */
        if (message.defender)
            NodeDto.internalBinaryWrite(message.defender, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* FightResult result = 3; */
        if (message.result !== 0)
            writer.tag(3, WireType.Varint).int32(message.result);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FightResultEvent
 */
export const FightResultEvent = new FightResultEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PositioningConfigurationChangedEvent$Type extends MessageType<PositioningConfigurationChangedEvent> {
    constructor() {
        super("PositioningConfigurationChangedEvent", [
            { no: 1, name: "board", kind: "message", T: () => BoardDto },
            { no: 2, name: "inventory", kind: "message", T: () => BaseStackContainerDto }
        ]);
    }
    create(value?: PartialMessage<PositioningConfigurationChangedEvent>): PositioningConfigurationChangedEvent {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PositioningConfigurationChangedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PositioningConfigurationChangedEvent): PositioningConfigurationChangedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BoardDto board */ 1:
                    message.board = BoardDto.internalBinaryRead(reader, reader.uint32(), options, message.board);
                    break;
                case /* BaseStackContainerDto inventory */ 2:
                    message.inventory = BaseStackContainerDto.internalBinaryRead(reader, reader.uint32(), options, message.inventory);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PositioningConfigurationChangedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BoardDto board = 1; */
        if (message.board)
            BoardDto.internalBinaryWrite(message.board, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* BaseStackContainerDto inventory = 2; */
        if (message.inventory)
            BaseStackContainerDto.internalBinaryWrite(message.inventory, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PositioningConfigurationChangedEvent
 */
export const PositioningConfigurationChangedEvent = new PositioningConfigurationChangedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GameOverEvent$Type extends MessageType<GameOverEvent> {
    constructor() {
        super("GameOverEvent", [
            { no: 1, name: "winner", kind: "message", T: () => PlayerDto },
            { no: 2, name: "loser", kind: "message", T: () => PlayerDto }
        ]);
    }
    create(value?: PartialMessage<GameOverEvent>): GameOverEvent {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GameOverEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GameOverEvent): GameOverEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PlayerDto winner */ 1:
                    message.winner = PlayerDto.internalBinaryRead(reader, reader.uint32(), options, message.winner);
                    break;
                case /* PlayerDto loser */ 2:
                    message.loser = PlayerDto.internalBinaryRead(reader, reader.uint32(), options, message.loser);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GameOverEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PlayerDto winner = 1; */
        if (message.winner)
            PlayerDto.internalBinaryWrite(message.winner, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* PlayerDto loser = 2; */
        if (message.loser)
            PlayerDto.internalBinaryWrite(message.loser, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GameOverEvent
 */
export const GameOverEvent = new GameOverEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomClosedEvent$Type extends MessageType<RoomClosedEvent> {
    constructor() {
        super("RoomClosedEvent", [
            { no: 1, name: "sender", kind: "message", T: () => PlayerDto }
        ]);
    }
    create(value?: PartialMessage<RoomClosedEvent>): RoomClosedEvent {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RoomClosedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomClosedEvent): RoomClosedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PlayerDto sender */ 1:
                    message.sender = PlayerDto.internalBinaryRead(reader, reader.uint32(), options, message.sender);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomClosedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PlayerDto sender = 1; */
        if (message.sender)
            PlayerDto.internalBinaryWrite(message.sender, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomClosedEvent
 */
export const RoomClosedEvent = new RoomClosedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DrawOfferedEvent$Type extends MessageType<DrawOfferedEvent> {
    constructor() {
        super("DrawOfferedEvent", [
            { no: 1, name: "cause_player_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DrawOfferedEvent>): DrawOfferedEvent {
        const message = { causePlayerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DrawOfferedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DrawOfferedEvent): DrawOfferedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cause_player_id */ 1:
                    message.causePlayerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DrawOfferedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cause_player_id = 1; */
        if (message.causePlayerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.causePlayerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DrawOfferedEvent
 */
export const DrawOfferedEvent = new DrawOfferedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DrawDeclinedEvent$Type extends MessageType<DrawDeclinedEvent> {
    constructor() {
        super("DrawDeclinedEvent", [
            { no: 1, name: "cause_player_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DrawDeclinedEvent>): DrawDeclinedEvent {
        const message = { causePlayerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DrawDeclinedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DrawDeclinedEvent): DrawDeclinedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cause_player_id */ 1:
                    message.causePlayerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DrawDeclinedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cause_player_id = 1; */
        if (message.causePlayerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.causePlayerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DrawDeclinedEvent
 */
export const DrawDeclinedEvent = new DrawDeclinedEvent$Type();
