/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import {useContext} from "react";
import {invertState} from "../pages/LobbyPage/LobbyPlayerStateButton";
import {RoomConnectionContext} from "./rooms/useRoomConnection";

/**
 * Type that represents the output structure of the {@link usePlayerReadyState} hook.
 *
 * changePlayerState: Method that inverts the state of the local player (ready and unready).
 */
type PlayerReadyStateHookStructure = [
    changePlayerState: () => void,
];

/**
 * Custom hook that sends a change State request
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const usePlayerReadyState = (): PlayerReadyStateHookStructure => {

    const roomConnection = useContext(RoomConnectionContext);

    /**
     * Method that inverts the player state.
     * This method sends the request to invert the state of the current player.
     */
    const _changePlayerState = () => {
        roomConnection.sendRequestWithoutCallback({
            accessToken: "",
            requestId: "change_state",
            changeStateRequest: {
                playerState: invertState(roomConnection.applicationState.localPlayer.playerState),
            }
        });
    }

    return [
        _changePlayerState
    ];
};

export default usePlayerReadyState;