/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {createStyles} from "@mantine/core";
import RoomCodeInputActionButton from "./RoomCodeInputActionButton";
import {AvailabilityCheckResult} from "../../hooks/rooms/useRoomAvailabilityCheck";
import SlideAnimation, {SlideDirection} from "../../components/animation/SlideAnimation";
import RoomCodeInputDigit from "./RoomCodeInputDigit";

/**
 * Type that represents the props of the {@link RoomCodeInput} component.
 *
 * digitCount: Amount of digits of the input.
 * state: Result of the last availability check.
 * onSubmit: Callback that is invoked when a complete code has been entered.
 * onClear: Callback that is invoked when a digit is removed from a complete code input.
 * onActionButtonClick: Callback that is invoked when the action button of the input is clicked.
 * disabled: Determines whether the input is enabled.
 * pending: Determines whether the input is currently in pending state.
 */
type RoomCodeInputProps = {
    digitCount: number;
    state: AvailabilityCheckResult;
    onSubmit: (roomCode: string) => void;
    onClear: () => void;
    onActionButtonClick: (roomCode: string) => void;
    disabled: boolean;
    pending: boolean;
};

const useStyles = createStyles({
    inputContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "1rem",
        marginBottom: "0.7rem"
    },
    hiddenMobileInput: {
        width: 0,
        overflow: "hidden",
    }
});

/**
 * Component that represents the input used to enter a room code.
 * This component consists of various {@link RoomCodeInputDigit} instances.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const RoomCodeInput = (props: RoomCodeInputProps) => {

    const KEYSTROKE_BACKSPACE = "Backspace";
    const KEYSTROKE_ENTER = "Enter";

    const {classes} = useStyles();
    const hiddenMobileInput = useRef(null);

    const [roomCode, setRoomCode] = useState<string[]>([]);
    const [hiddenMobileValue, setHiddenMobileValue] = useState<string>(null);

    /**
     * Method that is called when the user pressed a key.
     * This method updates the current room code and displays it.
     */
    const _handleKeyboardInput = useCallback((event: KeyboardEvent) => {
        if (props.disabled) {
            // The component is disabled
            setRoomCode(roomCode);
            return;
        }

        if (event.key === KEYSTROKE_BACKSPACE) {
            // Remove a digit from the input
            const clonedCode = [...roomCode];
            clonedCode.pop();
            setRoomCode(clonedCode);
            props.onClear();
        } else if (event.key === KEYSTROKE_ENTER) {
            // Submit the current code
            if (props.digitCount - roomCode.length === 0 && props.state === "available" && !props.disabled) {
                props.onActionButtonClick(roomCode.join(""));
            }
        } else {
            // Get the key that was pressed
            const potentialNumber = parseInt(event.key);
            if (typeof potentialNumber === "number" && potentialNumber >= 0) {
                // Add the digit to the input
                if (roomCode.length < props.digitCount) {
                    const clonedCode = [...roomCode];
                    clonedCode.push(potentialNumber.toString());

                    if (props.digitCount - clonedCode.length === 0) {
                        props.onSubmit(clonedCode.join(""));
                    }

                    setRoomCode(clonedCode);
                }
            }
        }
        // eslint-disable-next-line
    }, [roomCode, props.disabled]);

    /**
     * Hook that registers/unregisters a keyboard event listener to the window.
     * This listener is used to change the code when the user is typing.
     */
    useEffect(() => {
        document.addEventListener("keydown", _handleKeyboardInput);
        return () => document.removeEventListener("keydown", _handleKeyboardInput);
    }, [_handleKeyboardInput, props.disabled]);

    return (
        <div
            className={classes.inputContainer}
            onClick={() => hiddenMobileInput.current.focus()}
            onPaste={e => {
                // Paste the clipboard contents into the input
                const clipboardContents = e.clipboardData.getData("text/plain");
                if (clipboardContents.length === props.digitCount && typeof parseInt(clipboardContents) === "number") {
                    setRoomCode(clipboardContents.split(""));
                    props.onSubmit(clipboardContents);
                }
            }}>
            {[...Array(props.digitCount)].map((element, index) => (
                <SlideAnimation
                    layout
                    direction={SlideDirection.BOTTOM}
                    speedFactor={1.0 + index * 0.05}
                    delay={index * 0.05}
                    translation={200}>
                    <RoomCodeInputDigit
                        key={index}
                        digit={(roomCode[index] ?? "_").toString()}/>
                </SlideAnimation>
            ))}
            <RoomCodeInputActionButton
                state={props.state}
                pending={props.pending}
                visible={roomCode.length === props.digitCount}
                onClick={() => {
                    if (props.state === "available")
                        props.onActionButtonClick(roomCode.join(""));
                }}/>
            <div className={classes.hiddenMobileInput}>
                <input
                    ref={hiddenMobileInput}
                    type="text"
                    value={hiddenMobileValue}
                    onChange={e => {
                        setHiddenMobileValue(e.target.value)
                    }}/>
            </div>
        </div>
    );
};

export default RoomCodeInput;