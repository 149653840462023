/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { Button, createStyles, Text } from "@mantine/core";
import { FlagFill } from "@styled-icons/bootstrap/FlagFill";
import { Bomb } from "@styled-icons/fa-solid/Bomb";
import { Question } from "@styled-icons/bootstrap/Question";
import { Skull } from "@styled-icons/fa-solid/Skull";

/**
 * Type that represents the props of the {@link BaseGridNode} component.
 *
 * text: Text of the node.
 * color: Color of the node.
 * onClick: Click listener of the node.
 * diameter: Diameter of the node.
 * hoverable: Determines whether the node reacts to hover events.
 * highlighted: Determines whether the node is highlighted.
 * clicked: Determines whether the node should show a click animation.
 */
export type BaseGridNodeProps = {
    text?: string;
    color?: string;
    onClick?: () => void;
    diameter?: number;
    hoverable?: boolean;
    state?: NodeState;
    "data-cy"?: string;
};

/**
 * Enum that represents all possible states of a node.
 */
export enum NodeState {
    DEFAULT,
    SELECTED,
    HIGHLIGHTED,
    FOCUSED,
    BLURRED,
    FIGHT_WINNER,
    FIGHT_LOSER,
}

export const DIAMETER_DEFAULT = 6;
export const DIAMETER_FROZEN = 3.7;
export const DIAMETER_REDUCED = 4;
const CONTENT_DIAMETER = "min(2.7vh, 2.7vw)";

/**
 * Function that acquires the border color of a node by its current {@link NodeState}.
 *
 * @param state {@link NodeState} the border color should be acquired for.
 *
 * @return Returns the acquired border color.
 */
const borderColorFromState = (state: NodeState): string => {
    switch (state) {
        case NodeState.HIGHLIGHTED:
            return "rgba(251, 221, 144, 1)";
        case NodeState.FOCUSED:
            return "red";
        case NodeState.FIGHT_WINNER:
            return "green";
        default:
            return "rgba(251,221,144,0.6)";
    }
};

/**
 * Function that acquires the border width of a node by its current {@link NodeState}.
 *
 * @param state {@link NodeState} the border width should be acquired for.
 *
 * @return Returns the acquired border width.
 */
const borderWidthFromState = (state: NodeState) => {
    switch (state) {
        case NodeState.HIGHLIGHTED:
        case NodeState.SELECTED:
        case NodeState.FOCUSED:
            return "0.3rem";
        case NodeState.FIGHT_WINNER:
            return "0.5rem";
        default:
            return "0rem";
    }
};

const useStyles = createStyles((theme, props: BaseGridNodeProps) => ({
    base: {
        width: `min(${props.diameter ?? DIAMETER_DEFAULT}vh, ${props.diameter ?? DIAMETER_DEFAULT}vw)`,
        height: `min(${props.diameter ?? DIAMETER_DEFAULT}vh, ${props.diameter ?? DIAMETER_DEFAULT}vw)`,
        padding: "0",
        margin: "0.2rem",
        background: props.color,
        filter: props.state === NodeState.BLURRED ? "brightness(40%)" : "",
        "&:hover": {
            background: props.color,
            filter: props.hoverable && props.state !== NodeState.BLURRED ? "brightness(90%)" : "",
        },
        borderColor: borderColorFromState(props.state),
        borderWidth: borderWidthFromState(props.state),
        transition: "background-color .5s, border .1s",
    }
}));

/**
 * Component that represents a base node.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const BaseGridNode = (props: BaseGridNodeProps) => {

    const {classes} = useStyles(props);

    /**
     * Function that creates the content of a node.
     * This function returns the content based on the rank of the hosted figure.
     *
     * @return Returns the created content of the node.
     */
    const _getNodeContent = (): React.ReactNode => {
        if (props.state === NodeState.FIGHT_LOSER) {
            return (
                <Skull
                    width={CONTENT_DIAMETER}
                    height={CONTENT_DIAMETER}/>
            );
        } else if (props.text === "0") {
            return (
                <Question
                    width={CONTENT_DIAMETER}
                    height={CONTENT_DIAMETER}/>
            );
        } else if (props.text === "1") {
            return (
                <FlagFill
                    width={CONTENT_DIAMETER}
                    height={CONTENT_DIAMETER}/>
            );
        } else if (props.text === "12") {
            return (
                <Bomb
                    width={CONTENT_DIAMETER}
                    height={CONTENT_DIAMETER}/>
            );
        } else {
            return (
                <Text sx={{
                    fontSize: CONTENT_DIAMETER,
                    visibility: props.text === "" ? "hidden" : "visible"
                }}>
                    {props.text === "0" ? "?" : (parseInt(props.text) - 1).toString()}
                </Text>
            );
        }
    };

    return (
        <Button
            data-cy={props["data-cy"]}
            className={classes.base}
            radius="md"
            onClick={props.onClick}>
            {_getNodeContent()}
        </Button>
    );
};

export default BaseGridNode;