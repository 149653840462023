/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useState} from "react";
import {Box, Loader, Modal, Text, Title} from "@mantine/core";
import FigureExplanationList from "./FigureExplanationList";
import {Rank} from "../model/proto/dto";
import FigureExplanationPanel from "./FigureExplanationPanel";
import useFigureExplanation from "../hooks/useFigureExplanation";

/**
 * Type that represents the props of the {@link FigureExplanationDialog}.
 *
 * playerColor: Color of the current player.
 * opened: Determines whether the dialog is currently displayed.
 * onDismiss: Callback method that is invoked when the dialog is dismissed.
 */
type FigureExplanationDialogProps = {
    playerColor: string;
    opened: boolean;
    onDismiss: () => void;
};

/**
 * Dialog that allows the user to ask for explanations for a chosen {@link Rank}
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const FigureExplanationDialog = (props: FigureExplanationDialogProps) => {

    const [
        figureExplanations,
        figureExplanationsPending,
        figureExplanationsError,
    ] = useFigureExplanation();

    const [askedRank, setAskedRank] = useState(null);

    /**
     * Method that returns the explanation for a given {@link Rank}.
     *
     * @param rank {@link Rank} the explanation should be returned for.
     */
    const _getExplanationByRank = (rank: Rank): string => {
        return figureExplanations?.find(
            (figureExplanation) => figureExplanation?.rank === rank
        )?.explanation;
    };

    /**
     * Method that builds the pending layout of the dialog.
     *
     * @return Returns the created layout.
     */
    const _buildPendingLayout = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10rem",
                    marginBottom: "10rem",
                }}
            >
                <Loader variant="dots"/>
                <Text sx={{marginTop: "1rem"}}>Loading Explanations</Text>
            </Box>
        );
    };

    /**
     * Method that builds the error layout of the dialog.
     *
     * @return Returns the error layout of the dialog.
     */
    const _buildErrorLayout = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10rem",
                    marginBottom: "10rem",
                }}
            >
                <Text>Unable to load explanations. Please try again later.</Text>
            </Box>
        );
    };

    /**
     * Method that builds the main layout of the dialog.
     *
     * @return Returns the created main layout.
     */
    const _buildMainLayout = () => {
        return (
            <Box
                sx={() => ({
                    display: "flex",
                    alignItems: "center",
                })}
            >
                <FigureExplanationList
                    listItems={figureExplanations?.map((item) => item.rank) ?? []}
                    onRankClick={setAskedRank}
                    playerColor={props?.playerColor}
                />
                <FigureExplanationPanel
                    rank={askedRank}
                    explanation={_getExplanationByRank(askedRank)}
                    playerColor={props?.playerColor}
                />
            </Box>
        );
    };

    return (
        <Modal
            opened={props.opened}
            onClose={props.onDismiss}
            title={<Title order={3}>Figure Explanations</Title>}
            centered
            transitionProps={{transition: "rotate-left"}}
            size="lg"
            overlayProps={{
                blur: 3,
            }}
        >
            {figureExplanationsPending
                ? _buildPendingLayout()
                : figureExplanationsError
                    ? _buildErrorLayout()
                    : _buildMainLayout()}
        </Modal>
    );
};

export default FigureExplanationDialog;
