/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import "./EntryAnimation.css";

/**
 * Type that represents the props of the {@link EntryAnimation} component.
 *
 * xOffset: X-Offset of the item that should be animated (0 by default, used when animating a grid).
 * yOffset: Y-Offset of the item that should be animated (0 by default, used when animating a grid).
 * width: Amount of elements in a row that are animated together (0 by default).
 * offsetFactor: Factor the offsets increase by when animating multiple items together (1 by default).
 * children: Children of the animation.
 */
type EntryAnimationProps = {
    xOffset?: number;
    yOffset?: number;
    width?: number;
    offsetFactor?: number;
    children: React.ReactNode;
};

/**
 * Method that calculates the animation delay for a single element within a grid.
 * The resulting animation will start from the top left and ends at the bottom right.
 * The animation consists of multiple diagonal lines of elements that are animated together.
 *
 * @param x X index of the element that is animated.
 * @param y Y index of the element that is animated.
 * @param width Total amount of items in the row.
 * @param factor Factor the delay is increased by for every diagonal.
 */
export const calculateAnimationDelay = (x: number, y: number, width: number, factor: number): string => {
    let referenceX = x;
    let referenceY = y;

    while (referenceX < width && referenceY < 0) {
        referenceX++;
        referenceY--;
    }

    const animationOrder = referenceX + referenceY;
    return `${animationOrder * factor}ms`
};

/**
 * Component that invokes an entry animation.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const EntryAnimation = (props: EntryAnimationProps) => {
    return (
        <div
            className="entry-animation"
            style={{
                animationDelay: calculateAnimationDelay(
                    props.xOffset ?? 0,
                    props.yOffset ?? 0,
                    props.width ?? 0,
                    props.offsetFactor ?? 1,
                )
            }}>
            {props.children}
        </div>
    );
};

export default EntryAnimation;