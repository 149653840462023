/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {createStyles} from "@mantine/core";
import GameMenuItem from "./GameMenuItem";
import {Handshake} from "@styled-icons/fluentui-system-filled/Handshake";
import {PersonQuestionMark} from "@styled-icons/fluentui-system-filled/PersonQuestionMark";
import {BookQuestionMark} from "@styled-icons/fluentui-system-filled/BookQuestionMark";
import GameMenuShareSheet from "./GameMenuShareSheet";

/**
 * Type that represents the props of the {@link GameMenu} component.
 */
type GameMenuProps = {
  onDrawRequested?: () => void;
  drawAvailable?: boolean;
  onHelpRequested?: () => void;
  roomCode: string;
  drawNotPossiblePhase: boolean;
  onGameInstructionsRequested?: () => void;
};

const useStyles = createStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    position: "absolute",
    width: "100%",
    padding: "1rem",
  },
});

/**
 * Component that represents the menu of the game.
 *
 * @param onDrawRequested Callback that is invoked when a draw was requested.
 * @param drawAvailable Determines whether a draw can be requested.
 * @param onHelpRequested Callback that is invoked when help was requested.
 * @param roomCode Code of the current room.
 * @param positioning Determines whether the draw button should exist
 * @param onGameInstructionsRequested Callback that is invoked when the game instructions were requested.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const GameMenu: React.FC<GameMenuProps> = ({
                                               onDrawRequested,
                                               drawAvailable,
                                               onHelpRequested,
                                               roomCode,
                                               drawNotPossiblePhase,
                                               onGameInstructionsRequested,
                                           }) => {

  const { classes } = useStyles();

    return (
        <div className={classes.container}>
            <GameMenuItem
                label="Show Instructions"
                IconClass={BookQuestionMark}
                onClick={onGameInstructionsRequested}
            />
            <GameMenuItem
                label="Help"
                IconClass={PersonQuestionMark}
                onClick={onHelpRequested}/>
            {!drawNotPossiblePhase && (
                <GameMenuItem
                    label="Offer Draw"
                    IconClass={Handshake}
                    onClick={onDrawRequested}
                    disabled={drawAvailable}
                />
            )}
            <GameMenuShareSheet
                roomCode={roomCode}/>
        </div>
    );
};

export default GameMenu;
