/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import { PositioningTemplate } from "../model/templates";
import { useState } from "react";
import axios from "axios";

/**
 * Type that represents the output structure of the positioning template hook.
 *
 * template: {@link PositioningTemplate} that was fetched.
 * pending: Determines whether the request is currently pending.
 * error: Contains any error that occurred during the process.
 * fetchTemplate: Method that fetches the {@link PositioningTemplate} with a specific ID.
 */
type PositioningTemplateHookStructure = [
    template: PositioningTemplate,
    pending: boolean,
    error: string,
    fetchTemplate: (id: string) => void
];

/**
 * Custom hook that fetches the {@link PositioningTemplate} with a specific ID using the REST API.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const usePositioningTemplate = (): PositioningTemplateHookStructure => {

    const [template, setTemplate] = useState<PositioningTemplate>(null);
    const [pending, setPending] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);

    /**
     * Method that fetches a {@link PositioningTemplate} with a specific ID.
     *
     * @param id ID of the {@link PositioningTemplate} that should be fetched.
     */
    const fetchTemplate = (id: string) => {
        setPending(true);
        axios.get(`/api/v1/templates/${id}`)
            .then(result => {
                setPending(false);
                setError(null);
                setTemplate(result.data);
            })
            .catch(() => {
                setPending(false);
                setError("Unable to fetch template");
            });
    };

    return [
        template,
        pending,
        error,
        fetchTemplate,
    ];
};

export default usePositioningTemplate;