/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import axios from "axios";

/**
 * Type that represents the elements that are returned by the room deletion hook.
 *
 * deleteRoom: Method that deletes a room with a given code and refresh token.
 */
type RoomDeletionHookStructure = [
    deleteRoom: (roomCode: string, refreshToken: string, callback: () => void) => void,
];

/**
 * Custom hook used to delete a specific room.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const useRoomDeletion = (): RoomDeletionHookStructure => {

    /**
     * Method that deletes a specific room.
     * This method invokes a request to the REST API endpoint used to delete the room.
     * The refresh token is required as the room can only be closed when authenticated.
     *
     * @param roomCode Code of the room that should be deleted.
     * @param refreshToken Refresh token used to authorize the request.
     * @param callback Callback that is invoked once the room was closed.
     */
    const deleteRoom = (roomCode: string, refreshToken: string, callback: () => void) => {
        axios.post(`/api/v1/rooms/${roomCode}/close`, refreshToken, {
            headers: {
                "Content-Type": "text/plain",
            },
        }).then(() => {
            callback();
        }).then(() => {
            callback();
        });
    };

    return [
        deleteRoom,
    ];
};

export default useRoomDeletion;