/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { Button, createStyles, Loader, MantineGradient } from "@mantine/core";
import { MeetingRoom } from '@styled-icons/material-outlined/MeetingRoom';
import { Network } from '@styled-icons/zondicons/Network';
import { Block } from '@styled-icons/material-outlined/Block';
import { GroupAdd } from '@styled-icons/material-outlined/GroupAdd';
import { AvailabilityCheckResult } from "../../hooks/rooms/useRoomAvailabilityCheck";

/**
 * Type that represents the props of the {@link RoomCodeInputActionButton} component.
 *
 * state: Last availability check result.
 * pending: Determines whether the button currently is in pending state.
 * visible: Determines whether the button is visible.
 * onClick: Callback that is invoked when the button is clicked.
 */
type RoomCodeInputActionButtonProps = {
    state: AvailabilityCheckResult;
    pending: boolean;
    visible: boolean;
    onClick?: () => void;
};

const useStyles = createStyles((theme, props: RoomCodeInputActionButtonProps) => ({
    button: {
        margin: "0.2",
        alignItems: "center",
        justifyContent: "center",
        height: "6rem",
        transition: "all .2s",
        padding: 0,
        opacity: props.visible ? 1 : 0,
        visibility: props.visible ? "visible" : "hidden",
        width: props.visible ? "5rem" : "0rem",
        marginLeft: "0.2rem"
    },
    icon: {
        marginLeft: props.visible ? "0.5rem" : 0,
        marginRight: props.visible ? "0.5rem" : 0,
        opacity: props.visible ? 1 : 0,
        transition: "all .5s"
    }
}));

const ICON_DIAMETER = 35;

/**
 * Component that represents the current state of the room query and allows to execute actions.
 * This component is nested in the {@link RoomCodeInput}.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const RoomCodeInputActionButton = (props: RoomCodeInputActionButtonProps) => {

    const buttonGradientMap: { [key: string]: MantineGradient } = {
        not_existing: {
            from: "red",
            to: "orange"
        },
        at_capacity: {
            from: "red",
            to: "orange"
        },
        no_connection: {
            from: "red",
            to: "orange"
        },
        pending: {
            from: "gray",
            to: "gray"
        },
        available: {
            from: "teal",
            to: "blue",
            deg: 60
        }
    };

    const {classes} = useStyles(props);

    /**
     * Method that builds the child element of the action button.
     * The child element depends on the current status of the button.
     */
    const _buildChild = () => {
        if (props.pending) {
            return <Loader color="white"/>
        } else {
            switch (props.state) {
                case "available":
                    return <MeetingRoom
                        className={classes.icon}
                        width={ICON_DIAMETER}
                        height={ICON_DIAMETER}/>
                case "no_connection":
                    return <Network
                        className={classes.icon}
                        width={ICON_DIAMETER}
                        height={ICON_DIAMETER}/>
                case "not_existing":
                    return <Block
                        className={classes.icon}
                        width={ICON_DIAMETER}
                        height={ICON_DIAMETER}/>
                case "at_capacity":
                    return <GroupAdd
                        className={classes.icon}
                        width={ICON_DIAMETER}
                        height={ICON_DIAMETER}/>
            }
        }
    };

    return (
        <Button
            className={classes.button}
            variant="gradient"
            gradient={props.pending ? buttonGradientMap.pending : buttonGradientMap[props.state]}
            radius="lg"
            onClick={props.onClick}>
            {_buildChild()}
        </Button>
    );
};

export default RoomCodeInputActionButton;