/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import "./CircleProgress.css";

/**
 * Type that represents the props of the {@link CircleProgress} component.
 *
 * color: Color of the pulse animation.
 * shadowColor: Shadow color of the pulse animation.
 */
type CircleProgressProps = {
    color?: string;
    shadowColor?: string;
}

/**
 * Component that displays a circular pending animation.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const CircleProgress = (props: CircleProgressProps) => {

    const containerStyle = {
        "--animation-color": props.color ?? "white",
        "--animation-shadow-color": props.shadowColor ?? "white",
    };

    // @ts-ignore
    return <div className="circle-progress" style={containerStyle}></div>;
};

export default CircleProgress;