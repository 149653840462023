/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { Sword } from "@styled-icons/remix-fill/Sword";
import { ShieldFlash } from "@styled-icons/remix-fill/ShieldFlash";
import { createStyles, Overlay, Title } from "@mantine/core";
import { colors } from "../../helper/ColorPalette";
import { DrawState, PlayerDto } from "../../model/proto/dto";
import { motion } from "framer-motion";
import { Handshake } from "@styled-icons/fluentui-system-filled/Handshake";

/**
 * Type that represents the props of the {@link GameResultOverlayProps}.
 *
 * show: Determines whether the overlay is displayed.
 * localPlayer: Local player of the session.
 * gameResult: Current result of the game.
 * onClick: Callback that is invoked when any child of the component is clicked.
 */
export type GameResultOverlayProps = {
    show: boolean;
    localPlayer: PlayerDto | null;
    opponentPlayer?: PlayerDto | null;
    gameResult: {
        winner: PlayerDto;
        loser: PlayerDto;
    } | null;
    onClick?: () => void;
};

const useStyles = createStyles({
    resultContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    titleContainer: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "1rem",
    },
    victoryIcon: {
        marginTop: "2rem",
    }
});

/**
 * Component that displays the result of the game on the full screen.
 * This component can be dismissed by clicking anywhere.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const GameResultOverlay = (props: GameResultOverlayProps) => {

    const {classes} = useStyles();

    /**
     * Method that constructs the victory layout of the component.
     *
     * @return Returns the constructed component.
     */
    const _buildVictoryLayout = () => {
        return (
            <>
                <motion.div
                    initial={{scale: 0}}
                    animate={{rotate: 360, scale: 1}}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 40,
                    }}>
                    <Sword
                        className={classes.victoryIcon}
                        size={240}
                        color={colors.blue}/>
                </motion.div>
                <motion.div
                    className={classes.titleContainer}
                    initial={{translateY: -100, scale: 0}}
                    animate={{translateY: 0, scale: 1}}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 40
                    }}>
                    <Title
                        sx={{
                            fontSize: "7.5rem",
                            color: colors.blue,
                        }}>
                        Victory
                    </Title>
                    <Title>
                        You won the game!
                    </Title>
                </motion.div>
            </>
        );
    };

    /**
     * Method that constructs the defeat layout of the component.
     *
     * @return Returns the constructed component.
     */
    const _buildDefeatLayout = () => {
        return (
            <>
                <motion.div
                    initial={{scale: 0}}
                    animate={{rotate: 360, scale: 1}}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 40,
                    }}>
                    <ShieldFlash size={230} color={colors.red}/>
                </motion.div>
                <motion.div
                    className={classes.titleContainer}
                    initial={{translateY: -100, scale: 0}}
                    animate={{translateY: 0, scale: 1}}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 40
                    }}>
                    <Title
                        sx={{
                            fontSize: "7.5rem",
                            color: colors.red,
                        }}>
                        Defeat
                    </Title>
                    <Title order={2}>
                        {props?.gameResult?.winner?.name ?? ""} won the game!
                    </Title>
                </motion.div>
            </>
        );
    };

    /**
     * Method that constructs the draw layout of the component.
     *
     * @return Returns the constructed component.
     */
    const _buildDrawLayout = () => {
        return (
            <>
                <motion.div
                    initial={{scale: 0}}
                    animate={{rotate: 360, scale: 1}}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 40,
                    }}>
                    <Handshake size={230}/>
                </motion.div>
                <motion.div
                    className={classes.titleContainer}
                    initial={{translateY: -100, scale: 0}}
                    animate={{translateY: 0, scale: 1}}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 40
                    }}>
                    <Title
                        sx={{
                            fontSize: "7.5rem",
                        }}>
                        Draw
                    </Title>
                    <Title order={2}>
                        You have agreed to a draw!
                    </Title>
                </motion.div>
            </>
        );
    };

    /**
     * Method that determines whether the local player won the game.
     *
     * @return Returns whether the local player won the game.
     */
    const _playerWon = (): boolean => {
        return props?.localPlayer?.uuid === props?.gameResult?.winner?.uuid;
    };

    /**
     * Method that determines whether the game ending is a draw.
     *
     * @return Returns whether the game ending is a draw.
     */
    const _draw = (): boolean => {
        return props?.localPlayer?.drawState !== DrawState.NONE && props?.opponentPlayer?.drawState !== DrawState.NONE;
    };

    return (
        <Overlay
            sx={{
                pointerEvents: props.show ? "all" : "none",
                transition: "all 0.5s",
            }}
            opacity={props.show ? 0.7 : 0}
            blur={props.show ? 5 : 0}
            onClick={props.onClick}
            onKeyDown={props.onClick}>
            {props.show && (
                <div className={classes.resultContainer}>
                    {_draw() ? _buildDrawLayout() : _playerWon() ? _buildVictoryLayout() : _buildDefeatLayout()}
                </div>
            )}
        </Overlay>
    );
};

export default GameResultOverlay;