/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { useContext, useState } from "react";
import { MoveErrorEnum, NodeDto } from "../../model/proto/dto";
import { generateRequestID } from "../../helper/Utils";
import { RoomConnectionContext } from "../rooms/useRoomConnection";
import { notifications } from "@mantine/notifications";
import { NearbyError } from "@styled-icons/material-rounded/NearbyError";

/**
 * Type that represents the output structure of the movement controller.
 *
 * sourceNode: Currently selected source {@link NodeDto}.
 * targetNode: Currently selected target {@link NodeDto}.
 * handleNodeSelection: Callback that is invoked when a {@link NodeDto} was selected.
 */
type MovementControllerHookStructure = [
    sourceNode: NodeDto,
    targetNode: NodeDto,
    handleNodeSelection: (node: NodeDto) => void,
    setMovementLocked: (locked: boolean) => void,
];

/**
 * Custom hook that contains the implementation of the frontend movement logic.
 * This hook keeps track of the selected {@link NodeDto} instances and sends the requests to the backend.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const useMovementController = (): MovementControllerHookStructure => {

    const roomConnection = useContext(RoomConnectionContext);

    const [sourceNode, setSourceNode] = useState<NodeDto>(null);
    const [targetNode, setTargetNode] = useState<NodeDto>(null);
    const [movementLocked, setMovementLocked] = useState<boolean>(false);

    /**
     * Method that handles the selection of a {@link NodeDto} on the board.
     * If the local player is not the current player, an error is displayed.
     * When no source {@link NodeDto} was selected, the possible moves for the {@link Node} are requested.
     * Otherwise, the request to move the figure is sent to the backend.
     *
     * @param node {@link NodeDto} that was selected.
     */
    const handleNodeSelection = (node: NodeDto) => {
        if (!movementLocked) {
            if (roomConnection.applicationState.currentPlayer?.uuid === roomConnection.applicationState.localPlayer?.uuid) {
                if (sourceNode == null && targetNode == null) {
                    _requestPossibleMoves(node);
                } else if (sourceNode != null && targetNode == null) {
                    if (sourceNode === node) {
                        setSourceNode(null);
                        setTargetNode(null);
                        roomConnection.setApplicationState({
                            ...roomConnection.applicationState,
                            possibleMoves: [],
                        });
                    } else {
                        _moveFigure(node);
                    }
                } else if (sourceNode != null && targetNode != null) {
                    setSourceNode(node);
                    setTargetNode(null);
                }
            } else {
                notifications.show({
                    id: "error_pending_turn",
                    autoClose: 3000,
                    color: "red",
                    title: "Not Your Turn",
                    message: "The opponent player is currently taking his turn",
                    icon: <NearbyError width={20} height={20}/>
                });
            }
        }
    };

    /**
     * Method that requests all possible moves of a {@link NodeDto}.
     * This method sends the request to the backend and updates the application state on response.
     * In case an error occurred the {@link NodeDto} is unselected again.
     *
     * @param node {@link NodeDto} the moves should be requested for.
     */
    const _requestPossibleMoves = (node: NodeDto) => {
        const updateRequestID = generateRequestID("highlight_figures_request");
        roomConnection.sendRequest(
            {
                accessToken: "",
                requestId: updateRequestID,
                possibleMovesRequest: {
                    sourceCoordinate: node.coordinate,
                }
            },
            {
                requestID: updateRequestID,
                callback: response => {
                    if (response.status.succeeded) {
                        setSourceNode(node);
                        roomConnection.setApplicationState({
                            ...roomConnection.applicationState,
                            possibleMoves: response.possibleMovesResponse.possibleMoves,
                        });
                    } else {
                        roomConnection.setApplicationState({
                            ...roomConnection.applicationState,
                            possibleMoves: [],
                        });
                    }
                }
            }
        );
    };

    /**
     * Method that moves a single figure.
     * This method sends the request to the backend.
     * In case an error occurred, the message is displayed to the user.
     *
     * @param node {@link NodeDto} the figure should be moved to.
     */
    const _moveFigure = (node: NodeDto) => {
        const updateRequestID = generateRequestID("move_figure_request");
        roomConnection.sendRequest(
            {
                accessToken: "",
                requestId: updateRequestID,
                moveFigureRequest: {
                    targetCoordinate: node.coordinate,
                    sourceCoordinate: sourceNode?.coordinate,
                }
            }, {
                requestID: updateRequestID,
                callback: response => {
                    if (response.moveFigureResponse.moveError === MoveErrorEnum.NODE_NOT_REACHABLE) {
                        // Node is not reachable
                        notifications.show({
                            id: "node_not_reachable",
                            autoClose: 3000,
                            color: "red",
                            title: "Node Not Reachable",
                            message: `The chosen node is not reachable for your figure`,
                            icon: <NearbyError width={20} height={20}/>
                        });
                    } else if (response.moveFigureResponse.moveError === MoveErrorEnum.NODE_NOT_TRAVERSABLE) {
                        // Node is not traversable
                        notifications.show({
                            id: "node_not_traversable",
                            autoClose: 3000,
                            color: "red",
                            title: "Node Not Traversable",
                            message: `The chosen node is not traversable`,
                            icon: <NearbyError width={20} height={20}/>
                        });
                    } else if (response.moveFigureResponse.moveError === MoveErrorEnum.TWO_FIELD_RULE) {
                        notifications.show({
                            id: "two_field",
                            autoClose: 3000,
                            color: "red",
                            title: "You violated the two field rule",
                            message: "You already moved between two fields for 3 moves.",
                            icon: <NearbyError width={20} height={20}/>
                        });
                    } else if (response.moveFigureResponse.moveError === MoveErrorEnum.MULTIPLE_FIELD_RULE) {
                        notifications.show({
                            id: "multiple_field",
                            autoClose: 3000,
                            color: "red",
                            title: "You violated the multiple field rule",
                            message: `You already threatened the same figure for 2 moves.`,
                            icon: <NearbyError width={20} height={20}/>
                        });
                    } else if (response.moveFigureResponse.moveError === MoveErrorEnum.NODE_CONTAINS_OWN_FIGURE) {
                        // Node is an own figure
                        setSourceNode(targetNode);
                        setTargetNode(null);
                        _requestPossibleMoves(node);
                    } else if (response.moveFigureResponse.moveError === MoveErrorEnum.NO_ERROR) {
                        setSourceNode(null);
                        setSourceNode(null);
                    }
                }
            });
    };

    return [
        sourceNode,
        targetNode,
        handleNodeSelection,
        setMovementLocked,
    ];
};

export default useMovementController;