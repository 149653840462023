/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import { createStyles } from "@mantine/core";
import { Rank } from "../../model/proto/dto";
import { colors } from "../../helper/ColorPalette";
import BaseGridNode, { DIAMETER_REDUCED } from "./BaseGridNode";
import BaseGridBorderNode from "./BaseGridBorderNode";
import PositionedSlideAnimation from "../animation/PositionedSlideAnimation";

/**
 * Type that represents the props of the {@link TemplatePreviewGrid}.
 *
 * previewGrid: Preview data that should be displayed.
 * playerColor: Color of the player.
 */
type TemplatePreviewGridProps = {
    previewGrid: Rank[][],
    playerColor: string,
};

const useStyles = createStyles({
    grid: {
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
        flexDirection: "row",
    }
});

/**
 * Component that displays the preview of a positioning preset.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const TemplatePreviewGrid = (props: TemplatePreviewGridProps) => {

    const {classes} = useStyles();

    const isLakeNode = (position: number) => [2, 3, 6, 7].includes(position);

    return (
        <div className={classes.grid}>
            <div className={classes.row}>
                {[...Array(props.previewGrid[0].length)].map((_, index) => (
                    <PositionedSlideAnimation
                        offsetX={index}
                        offsetY={0}
                        diameter={props.previewGrid[0].length}>
                        <BaseGridBorderNode
                            color={isLakeNode(index) ? colors.lake : colors.ground}
                            diameter={DIAMETER_REDUCED}
                            semiTransparentColor={isLakeNode(index) ? colors.semiTransparentLake : colors.semiTransparentGround}/>
                    </PositionedSlideAnimation>
                ))}
            </div>
            {props.previewGrid.map((row, rowIndex) => (
                <div className={classes.row}>
                    {row.map((rank, columnIndex) => (
                        <PositionedSlideAnimation
                            offsetX={columnIndex}
                            offsetY={rowIndex + 1}
                            diameter={props.previewGrid[rowIndex].length}>
                            <BaseGridNode
                                text={rank.toString()}
                                diameter={DIAMETER_REDUCED}
                                color={props.playerColor}/>
                        </PositionedSlideAnimation>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default TemplatePreviewGrid;