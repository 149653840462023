/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import LobbyQRCode from "./LobbyQRCode";
import { createStyles, Title } from "@mantine/core";
import LobbyShareSheet from "./LobbyShareSheet";
import SlideAnimation, { SlideDirection } from "../../components/animation/SlideAnimation";

/**
 * Type that represents the props of the {@link LobbySharePanel} component.
 *
 * roomCode: Code of the room that should be sharable.
 */
type LobbySharePanelProps = {
    roomCode: string;
};

const useStyles = createStyles({
    panelContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }
});

/**
 * Component that allows the user to invite other users to the room.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const LobbySharePanel = (props: LobbySharePanelProps) => {

    const {classes} = useStyles();

    return (
        <SlideAnimation
            direction={SlideDirection.LEFT}
            className={classes.panelContainer}>
            <Title sx={{marginBottom: "1rem"}} order={3}>
                Your Room
            </Title>
            <LobbyQRCode
                width={150}
                height={150}
                roomCode={props.roomCode}/>
            <Title order={3}>
                Code: {props.roomCode}
            </Title>
            <LobbyShareSheet roomCode={props.roomCode}/>
        </SlideAnimation>
    );
};

export default LobbySharePanel;