/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {Button} from "@mantine/core";
import {PlayerDto, PlayerState} from "../../model/proto/dto";
import {Replay} from "@styled-icons/material-sharp/Replay";

/**
 * Type that represents the props of the {@link RevengeButton}.
 */
type RevengeButtonProps = {
    localPlayer?: PlayerDto;
    opponentPlayer?: PlayerDto;
    onClick?: () => void;
};

/**
 * Component that represents the revenge button.
 *
 * @param localPlayer Player
 * @param opponentPlayer The opponent Player
 * @param onClick Callback that is invoked when the icon was clicked.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const RevengeButton: React.FC<RevengeButtonProps> = ({
                                                         localPlayer,
                                                         opponentPlayer,
                                                         onClick,
                                                     }) => {
    return (
        <Button
            leftIcon={<Replay size={25}/>}
            onClick={onClick}>
            Revenge ({[localPlayer, opponentPlayer].filter(player => player.playerState === PlayerState.READY).length}/2)
        </Button>
    );
};

export default RevengeButton;