/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {PlugDisconnected} from "@styled-icons/fluentui-system-filled/PlugDisconnected";
import {Alert, Button, createStyles} from "@mantine/core";
import {CloseOutline} from "@styled-icons/evaicons-outline/CloseOutline";
import SlideAnimation, {SlideDirection} from "../../components/animation/SlideAnimation";

/**
 * Type that represents the props of the {@link RejoinAlertProps}.
 *
 * reconnect: Callback that is invoked once the user decided to reconnect.
 * surrender: Callback that is invoked once the user decided to surrender.
 */
type RejoinAlertProps = {
    reconnect: () => void;
    surrender: () => void;
};

const useStyles = createStyles({
    alertContainer: {
        margin: "1rem",
        maxWidth: "30rem",
    },
    actionContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: "1rem",
    }
});

/**
 * Component that is displayed when the user recently left a running game.
 * This alert lets the user decide to either reconnect to surrender.
 * When reconnecting, the connection to the recent game is attempted to be re-established.
 * When surrendering, the game is ended and counted as if the other player won.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const RejoinAlert = (props: RejoinAlertProps) => {

    const {classes} = useStyles();

    return (
        <SlideAnimation direction={SlideDirection.BOTTOM}>
            <Alert
                className={classes.alertContainer}
                title="Reconnect"
                icon={<PlugDisconnected/>}>
                <div>
                    You recently left a game that was still running. Do you want to reconnect to that session?
                    Surrendering will result in the other player winning the game.
                    <div className={classes.actionContainer}>
                        <Button
                            sx={{marginRight: "0.5rem"}}
                            fullWidth
                            variant="outline"
                            color="red"
                            rightIcon={<CloseOutline size={20}/>}
                            onClick={props.surrender}>
                            Surrender
                        </Button>
                        <Button
                            sx={{marginLeft: "0.5rem"}}
                            fullWidth
                            color="blue"
                            rightIcon={<PlugDisconnected size={20}/>}
                            onClick={props.reconnect}>
                            Reconnect
                        </Button>
                    </div>
                </div>
            </Alert>
        </SlideAnimation>
    );
};

export default RejoinAlert;