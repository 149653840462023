/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {DrawState, PlayerDto} from "../../model/proto/dto";
import {motion} from "framer-motion";
import {Stop} from "@styled-icons/fa-solid/Stop";
import {createStyles, Title} from "@mantine/core";
import {colors} from "../../helper/ColorPalette";

/**
 * Type that represents the props of the {@link GameResultPanel}.
 *
 * localPlayer: Local player of the game.
 * opponentPlayer: Opponent player from the perspective of the local player.
 * gameResult: result of the current game.
 */
type GameResultPanelProps = {
    localPlayer?: PlayerDto | null;
    opponentPlayer?: PlayerDto | null;
    gameResult: {
        winner: PlayerDto;
        loser: PlayerDto;
    } | null;
};

const useStyles = createStyles({
    panelContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    }
});

/**
 * Component that displays the result of the current game at the border of the screen.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */

const GameResultPanel = (props: GameResultPanelProps) => {

    const {classes} = useStyles();

    /**
     * Method that determines whether there currently is a draw.
     *
     * @return Returns whether there currently is a draw.
     */
    const _draw = (): boolean => {
        return (props?.localPlayer && props?.opponentPlayer) ?
            props?.localPlayer?.drawState !== DrawState.NONE && props?.opponentPlayer?.drawState !== DrawState.NONE :
            false;
    };

    return (
        <motion.div
            className={classes.panelContainer}
            initial={{scale: 0, translateY: 200}}
            animate={{scale: 1, translateY: 0}}
            transition={{
                type: "spring",
                stiffness: 260,
                damping: 60,
            }}>
            <div className={classes.titleContainer}>
                <Stop size={30}/>
                <Title sx={{
                    marginLeft: "1rem",
                    marginRight: "1rem",
                }}>
                    The Game is Over
                </Title>
                <Stop size={30}/>
            </div>
            <Title
                sx={_draw() ? {color: colors.ground} : {color: props?.gameResult?.winner?.color ?? "red"}}
                order={3}>
                {_draw() ? "Draw!" : `${props?.gameResult?.winner?.name} won the game!`}
            </Title>
        </motion.div>
    );
};

export default GameResultPanel;