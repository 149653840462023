/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {MessageType, StrategoMessage} from "../../model/proto/common";
import {BaseStackContainerDto, BoardDto, DrawState, PlayerDto, PlayerState, PositionDto} from "../../model/proto/dto";
import {StrategoResponse} from "../../model/proto/response";
import {GamePhase, StrategoEvent} from "../../model/proto/event";
import {retrieveRoomCodeFromToken} from "../../helper/Utils";
import {useCookies} from "react-cookie";
import {StrategoRequest} from "../../model/proto/request";

/**
 * Type that represents the elements that can be given to the room connection hook as props.
 *
 * roomCode: Code of the room the connection should be established to.
 * onOpen: Callback that is invoked when the WebSocket channel was opened.
 * onClose: Callback that is invoked when the WebSocket channel was closed.
 * onError: Callback that is invoked when an error occurs.
 */
type RoomConnectionHookProps = {
    roomCode: string;
    onOpen?: () => void;
    onClose?: () => void;
    onError?: (error: any) => void;
};

/**
 * Type that represents the current state of the game.
 *
 * connectionState: Current state of the WebSocket connection.
 * connectionError: Any error that occurred in the WebSocket connection.
 * connectionAttempt: Current attempt of the WebSocket connection (used when reconnecting).
 * roomCode: Current code of the game session.
 * gamePhase: Current phase of the game.
 * localPlayer: Local player (of the client).
 * positioningConfiguration: Positioning configuration of the player.
 * playerList: List of all players in the room.
 * gameResult: Result of the current game.
 * board: Current board of the game.
 * currentPlayer: Current player of the game (that is currently moving).
 * possibleMoves: All available possible moves for the local player.
 */
export type ApplicationState = {
    connectionState: ConnectionState,
    connectionError: string;
    connectionAttempt: number;
    roomCode: string;
    gamePhase: GamePhase;
    localPlayer: PlayerDto;
    positioningConfiguration: {
        positioningBoard: BoardDto;
        playerInventory: BaseStackContainerDto
    }
    playerList: PlayerDto[];
    gameResult: {
        winner: PlayerDto;
        loser: PlayerDto;
    }
    board: BoardDto;
    currentPlayer: PlayerDto;
    possibleMoves: PositionDto[];
};

/**
 * Type that represents a callback for a request.
 * This callback is invoked once the corresponding response was received.
 *
 * requestID: ID of the request used to match the corresponding response.
 * callback: Callback that is invoked when the response is received.
 */
export type StrategoRequestCallback = {
    requestID: string;
    callback: (response: StrategoResponse) => void;
};

/**
 * Type that represents an event listener.
 * This listener is invoked every time a specific event is received.
 *
 * listenerID: ID of the listener used to later remove it from the pipeline.
 * checkPredicate: Filter function that determines whether a given event should be handled.
 * fire: Callback that is invoked when a matching event is received.
 */
export type StrategoEventListener = {
    listenerID: string;
    filterPredicate: (event: StrategoEvent) => boolean;
    fire: (event: StrategoEvent) => void;
};

/**
 * Type that represents the elements that are returned by the connection hook.
 *
 * applicationState: Current application state.
 * applicationStateReference: Reference to the current application state.
 * sendRequest: Method used to send a new request to the backend.
 * sendRequestWithoutCallback: Method used to send a new request without callback to the backend.
 * registerEventListener: Method used to register a new event listener.
 * unregisterEventListener: Method used to remove an existing event listener.
 * setApplicationState: Set state action of the application state.
 */
type RoomConnection = {
    applicationState: ApplicationState,
    applicationStateReference: React.MutableRefObject<ApplicationState>,
    sendRequest: (request: StrategoRequest, callback: StrategoRequestCallback) => void,
    sendRequestWithoutCallback: (request: StrategoRequest) => void,
    registerEventListener: (listener: StrategoEventListener) => void,
    unregisterEventListener: (listenerID: string) => void,
    setApplicationState: (state: ApplicationState) => void,
};

export type ConnectionState = "connecting" | "connected" | "failed" | "closed" | "reconnecting";

export const CloseReasonRoomNotFound = "1";
export const CloseReasonRoomAtCapacity = "2";
export const ConnectionErrorRoomNotFound = "room_not_found";
export const ConnectionErrorRoomAtCapacity = "room_at_capacity";

export const AccessTokenCookie = "access_token";
export const RefreshTokenCookie = "refresh_token";

export const ReconnectionBackoff = 5000;
export const ReconnectionMaxAttempts = 3;

export const RoomConnectionContext = React.createContext<RoomConnection>({
    applicationState: {
        connectionState: "failed",
        connectionError: "",
        connectionAttempt: 0,
        roomCode: "",
        gamePhase: GamePhase.LOBBY,
        positioningConfiguration: null,
        localPlayer: {
            uuid: "",
            name: "",
            color: "",
            playerState: PlayerState.NOT_READY,
            graveyard: {
                stacks: []
            },
            drawState: DrawState.NONE,
            connected: false,
        },
        playerList: [],
        gameResult: null,
        board: null,
        currentPlayer: null,
        possibleMoves: [],
    },
    applicationStateReference: null,
    sendRequest: (message, callback) => {
    },
    sendRequestWithoutCallback: (message) => {
    },
    registerEventListener: (listener) => {
    },
    unregisterEventListener: (id) => {
    },
    setApplicationState: (state: ApplicationState) => {
    },
});

/**
 * Custom hook that establishes a WebSocket connection to a room with a given ID.
 *
 * @param props Props of the custom hook.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const useRoomConnection = (props: RoomConnectionHookProps): RoomConnection => {

    const registeredCallbacks = useRef<StrategoRequestCallback[]>([]);
    const registeredEventListeners = useRef<StrategoEventListener[]>([]);

    const roomSocket = useRef<WebSocket>(null);
    const [cookies, setCookie, removeCookie] = useCookies([AccessTokenCookie, RefreshTokenCookie]);

    const [applicationStateInstance, setApplicationStateInstance] = useState<ApplicationState>({
        connectionState: "closed",
        connectionError: "",
        connectionAttempt: 0,
        roomCode: props.roomCode,
        gamePhase: GamePhase.LOBBY,
        localPlayer: null,
        positioningConfiguration: null,
        playerList: [],
        gameResult: null,
        board: null,
        currentPlayer: null,
        possibleMoves: [],
    });

    const applicationState = useRef(applicationStateInstance);
    const reconnectRef = useRef<boolean>(true);

    const accessTokenRef = useRef<string>(cookies.access_token);
    const refreshTokenRef = useRef<string>(cookies.refresh_token);

    useEffect(() => {
        accessTokenRef.current = cookies.access_token;
    }, [cookies.access_token]);

    useEffect(() => {
        refreshTokenRef.current = cookies.refresh_token;
    }, [cookies.refresh_token]);

    /**
     * Callback that is invoked when the WebSocket connection is opened.
     * This method starts the authentication procedure with the backend.
     *
     * @param error Any error that occurs during the procedure.
     */
    const _onOpen = (error: any) => {
        let useCookie = true;
        if (applicationState.current.roomCode !== retrieveRoomCodeFromToken(refreshTokenRef.current)) {
            removeCookie(RefreshTokenCookie);
            useCookie = false;
        }

        // Reset the reconnection attempts
        updateApplicationState({
            ...applicationState.current,
            connectionAttempt: 0,
        });

        sendRequest(
            {
                requestId: "initial_auth",
                accessToken: "",
                authenticationRequest: {
                    refreshToken: useCookie ? refreshTokenRef.current : "",
                }
            },
            {
                requestID: "initial_auth",
                callback: (response: StrategoResponse) => {
                    // Save the initial configuration and show the lobby
                    if (response.status.succeeded && response.authenticationResponse.refreshToken) {
                        // Persist the tokens in the cookies
                        setCookie(AccessTokenCookie, response.authenticationResponse.accessToken);
                        setCookie(RefreshTokenCookie, response.authenticationResponse.refreshToken, {
                            maxAge: 60 * 60 * 24,
                        });

                        // Update the state
                        updateApplicationState({
                            ...applicationState.current,
                            connectionState: "connected",
                            localPlayer: response.authenticationResponse.initialConfiguration
                        })
                    } else {
                        updateApplicationState({
                            ...applicationState.current,
                            connectionState: "failed",
                            connectionError: response.status.status
                        });
                    }
                }
            }
        )

        // Delegate the event to the callback of the props
        if (props.onOpen)
            props.onOpen();
    };

    /**
     * Callback that is invoked when the WebSocket connection is closed.
     *
     * @param event Event that occurred while the connection was closed.
     */
    const _onClose = (event: CloseEvent) => {
        updateApplicationState({
            ...applicationState.current,
            connectionState: event.reason !== CloseReasonRoomNotFound && event.reason !== CloseReasonRoomAtCapacity ? "reconnecting" : "failed",
            connectionError: event.reason === CloseReasonRoomNotFound ? ConnectionErrorRoomNotFound
                : event.reason === CloseReasonRoomAtCapacity ? ConnectionErrorRoomAtCapacity
                    : "Remote connection was closed unexpectedly"
        });

        // Remove the cookie in case the game was completed or the players are still in the lobby
        if (applicationState.current.gamePhase === GamePhase.COMPLETED || applicationState.current.gamePhase === GamePhase.LOBBY) {
            removeCookie(RefreshTokenCookie);
        }

        // Attempt to reconnect
        if (reconnectRef.current && applicationState.current.connectionAttempt < ReconnectionMaxAttempts) {
            setTimeout(() => {
                if (reconnectRef.current) {
                    updateApplicationState({
                        ...applicationState.current,
                        connectionAttempt: applicationState.current.connectionAttempt + 1,
                    });
                    connect();
                }
            }, ReconnectionBackoff);
        } else {
            updateApplicationState({
                ...applicationState.current,
                connectionState: "failed",
                connectionError: "Unable to connect to the game server",
            });
        }

        // Delegate the event to the callback of the props
        if (props.onClose)
            props.onClose();
    };

    /**
     * Callback that is invoked when any error occurs in the WebSocket connection.
     *
     * @param event Error that was thrown.
     */
    const _onError = (event: any) => {
        updateApplicationState({
            ...applicationState.current,
            connectionState: "failed",
            connectionError: "Unable to connect to the room. It might not exist or is at capacity right now."
        });

        // Delegate the event to the callback of the props
        if (props.onError)
            props.onError(event);
    };

    /**
     * Callback that is invoked when a new message is received via the WebSocket connection.
     * This callback parses the incoming message into the respective Protocol Buffer instance.
     * In case the incoming message is a request, the corresponding callback is determined using the requestID.
     * When any of the registered callbacks matched, the response is delegated to it and the callback is removed.
     * In case the incoming message is an event, the corresponding event listener is determined using the filter.
     */
    const _handleMessage = (event: any) => {
        const incomingMessage = StrategoMessage.fromBinary(new Uint8Array(event.data));

        if (incomingMessage.messageType === MessageType.RESPONSE) {
            // Incoming response
            for (let callback of registeredCallbacks.current) {
                if (incomingMessage.response.requestId === callback.requestID) {
                    // Predicate matched
                    callback.callback(incomingMessage.response);
                    registeredCallbacks.current.splice(registeredCallbacks.current.indexOf(callback), 1)
                    break;
                }
            }
        } else if (incomingMessage.messageType === MessageType.EVENT) {
            // Incoming event
            for (let eventListener of registeredEventListeners.current) {
                if (eventListener.filterPredicate(incomingMessage.event)) {
                    // Predicate matched
                    eventListener.fire(incomingMessage.event);
                }
            }
        }
    };

    /**
     * Method that updates the wrapped {@link ApplicationState}.
     * The changes are transferred to two destinations:
     * - The {@link ApplicationState} wrapped by the useState: Used to update the UI.
     * - The {@link ApplicationState} wrapped by the useRef: Used to keep references in listeners up to date.
     *
     * @param updatedState New {@link ApplicationState} that should be stored.
     */
    const updateApplicationState = (updatedState: ApplicationState) => {
        setApplicationStateInstance(updatedState);
        applicationState.current = updatedState;
    };

    /**
     * Method that is used to send a new request to the backend.
     * This method additionally registers the given callback for the future response.
     *
     * @param request Request that is sent to the backend.
     * @param callback Callback for the future response that is registered.
     */
    const sendRequest = (request: StrategoRequest, callback: StrategoRequestCallback) => {
        registeredCallbacks.current.push(callback);
        sendRequestWithoutCallback(request);
    };

    /**
     * Method that is used to send a new request to the backend.
     * This method uses the paradigm "fire-and-forget".
     * Consequently, no callback is registered.
     *
     * @param request Request that is sent to the backend.
     */
    const sendRequestWithoutCallback = (request: StrategoRequest) => {
        roomSocket.current.send(StrategoMessage.toBinary({
            messageType: MessageType.REQUEST,
            request: {
                ...request,
                accessToken: accessTokenRef.current,
            },
        }));
    };

    /**
     * Method that registers a new event listener.
     *
     * @param listener Event listener that should be registered.
     */
    const registerEventListener = (listener: StrategoEventListener) => {
        registeredEventListeners.current.push(listener);
    };

    /**
     * Method that unregisters an existing event listener.
     *
     * @param listenerID ID of the listener that should be removed.
     */
    const unregisterEventListener = (listenerID: string) => {
        registeredEventListeners.current.splice(registeredEventListeners.current.indexOf(
            registeredEventListeners.current.find(listener => listener.listenerID === listenerID)
        ), 1);
    };

    /**
     * Method that establishes the websocket connection.
     * This method connects to the endpoint ws://<backend-url>/rooms/<room-code> of the backend.
     * This connection is used to play the actual game.
     * Moreover, this method registers all event listeners.
     */
    const connect = () => {
        // Socket initialization
        cleanup();
        roomSocket.current = new WebSocket(`${window.location.protocol === "https:" ? "wss" : "ws"}://${window.location.hostname}${window.location.port === "3000" ? ":8080" : ""}/rooms/${props.roomCode}`);
        roomSocket.current.binaryType = "arraybuffer";
        roomSocket.current.onopen = _onOpen;
        roomSocket.current.onclose = _onClose;
        roomSocket.current.onerror = _onError;
        roomSocket.current.onmessage = _handleMessage;

        // Register the player update event listener
        registerEventListener({
            listenerID: "player_list_update_listener",
            filterPredicate: event => event.playerListUpdatedEvent !== undefined,
            fire: event => {
                const potentialUpdatedConfiguration = event.playerListUpdatedEvent.players.find(player => player.uuid === applicationState.current.localPlayer?.uuid);

                updateApplicationState({
                    ...applicationState.current,
                    localPlayer: potentialUpdatedConfiguration ?? applicationState.current.localPlayer,
                    playerList: event.playerListUpdatedEvent.players,
                });
            },
        });

        // Register the game phase changed event listener
        registerEventListener({
            listenerID: "game_phase_changed_listener",
            filterPredicate: event => event.gamePhaseChangedEvent !== undefined,
            fire: event => {
                updateApplicationState({
                    ...applicationState.current,
                    gamePhase: event.gamePhaseChangedEvent.gamePhase,
                    gameResult: event.gamePhaseChangedEvent.gamePhase === GamePhase.POSITIONING ? null : applicationState.current.gameResult,
                });
            },
        });

        // Register the board updated event listener
        registerEventListener({
            listenerID: "board_update_listener",
            filterPredicate: (event) => event.boardChangedEvent !== undefined,
            fire: (event) => {
                updateApplicationState({
                    ...applicationState.current,
                    board: event.boardChangedEvent.board,
                });
            },
        });

        // Register the positioning configuration updated event listener
        registerEventListener({
            listenerID: "positioning_configuration_update_listener",
            filterPredicate: (event) => event.positioningConfigurationChangedEvent !== undefined,
            fire: (event) => {
                updateApplicationState({
                    ...applicationState.current,
                    positioningConfiguration: {
                        positioningBoard: event.positioningConfigurationChangedEvent.board,
                        playerInventory: event.positioningConfigurationChangedEvent.inventory,
                    }
                });
            },
        });

        // Register the current player updated event listener
        registerEventListener({
            listenerID: "current_player_update_listener",
            filterPredicate: (event) => event.currentPlayerChangedEvent !== undefined,
            fire: (event) => {
                console.log("Updating current player");
                updateApplicationState({
                    ...applicationState.current,
                    currentPlayer: event.currentPlayerChangedEvent.currentPlayer,
                });
            },
        });

        // Register the game over event listener
        registerEventListener({
            listenerID: "game_over_listener",
            filterPredicate: (event) => event.gameOverEvent !== undefined,
            fire: (event) => {
                updateApplicationState({
                    ...applicationState.current,
                    gameResult: {
                        winner: event.gameOverEvent.winner,
                        loser: event.gameOverEvent.loser,
                    }
                });
            },
        });
    };

    /**
     * Method that runs the cleanup for the server connection.
     * This method removes all event listeners.
     */
    const cleanup = () => {
        unregisterEventListener("player_list_update_listener");
        unregisterEventListener("game_phase_changed_listener");
        unregisterEventListener("board_update_listener");
        unregisterEventListener("positioning_configuration_update_listener");
        unregisterEventListener("current_player_update_listener");
        unregisterEventListener("game_over_listener");
    };

    useEffect(() => {
        connect();

        return () => {
            // Cleanup method
            roomSocket.current.close();
            reconnectRef.current = false;
            cleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        applicationState: applicationStateInstance,
        applicationStateReference: applicationState,
        sendRequest,
        sendRequestWithoutCallback,
        registerEventListener,
        unregisterEventListener,
        setApplicationState: setApplicationStateInstance,
    };
};

export default useRoomConnection;