/*
 *  ____  ____  ____   __  ____  ____  ___   __
 * / ___)(_  _)(  _ \ / _\(_  _)(  __)/ __) /  \
 * \___ \  )(   )   //    \ )(   ) _)( (_ \(  O )
 * (____/ (__) (__\_)\_/\_/(__) (____)\___/ \__/
 *
 * 2023 The Stratego Project - Team DT-Intern
 *
 * Authors:
 * Maximilian Flügel: maximilian.fluegel@tu-clausthal.de
 * Jannes Bikker: jannes.bikker@tu-clausthal.de
 * Alina Simon: alina.simon@tu-clausthal.de
 * Niklas Lugowski: niklas.lugowski@tu-clausthal.de
 */

import * as React from "react";
import {Box, Card, createStyles, Image, Text, Title} from "@mantine/core";
import {Rank} from "../model/proto/dto";

/**
 * Type that represents the props of the {@link FigureExplanationPanel}.
 *
 * rank: The explained rank.
 * explanation: Explanation of the rank.
 * playerColor: Color of the local player.
 */
type FigureExplanationPanelProps = {
    rank?: Rank;
    explanation?: string;
    playerColor: string;
};

const useStyles = createStyles((theme, {playerColor}: FigureExplanationPanelProps) => ({
    card: {
        display: "flex",
        width: "7vw",
        minWidth: "5rem",
        aspectRatio: "24 / 33",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "1rem",
        borderWidth: "0.3rem",
        borderStyle: "solid",
        borderColor: playerColor,
        padding: "3vw",
    },
    figurePicture: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    panelOuterBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        padding: "2rem",
        justifyContent: 'center',
        width: "100%",
    },
    panelInnerBox: {
        display: "flex",
        alignItems: 'end',
        justifyContent: 'center',
        height: "13vw"
    },
    textBox: {
        display: "flex",
        padding: "0.5rem",
        height: "5rem",
        alignItems: "baseline",
        justifyContent: "center"

    }
}));

/**
 * Function that capitalizes the first letter of a given string.
 *
 * @param str String used to capitalize the first word.
 *
 * @return Returns the given input string with capitalized first letter.
 */
export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Panel that shows the image, -name and -explanations from a {@link Rank}.
 *
 * @author Maximilian Flügel
 * @author Jannes Bikker
 * @author Alina Simon
 * @author Niklas Lugowski
 */
const FigureExplanationPanel = (props: FigureExplanationPanelProps) => {

    const {classes} = useStyles(props);

    return (
        <Box className={classes.panelOuterBox}>
            {props.rank &&
                <Box>
                    <Box className={classes.panelInnerBox}>
                        <Card
                            className={classes.card}>
                            <Card.Section>
                                <Image
                                    className={classes.figurePicture}
                                    src={"/strategoFigures/" + props?.rank?.toString()?.toLowerCase() + ".png"}
                                    alt={Rank[props.rank]}
                                />
                            </Card.Section>
                        </Card>
                    </Box>
                    <Box className={classes.textBox}>
                        <Title ta="center">{capitalizeFirstLetter(props.rank.toString().toLowerCase())} </Title>
                    </Box>
                    <Box className={classes.textBox}>
                        <Text ta="center" size="xl">{props.explanation}</Text>
                    </Box>
                </Box>
            }
        </Box>
    )
};

export default FigureExplanationPanel;